import { prop } from './index';
import { getCategoryUrl } from './category';

function getHostname(lang): string {
  if (process.env.REACT_APP_BASE_URL) {
    return lang === 'sk'
      ? process.env.REACT_APP_BASE_URL
      : `${process.env.REACT_APP_BASE_URL}/${lang}`;
  }

  return '';
}

interface Breadcrumbs {
  url: string;
  name: string;
}

export function getArticleBreadcrumbMicrodata(article, lang): Breadcrumbs[] {
  const hostname: string = getHostname(lang);
  return [{ name: 'Domov', url: hostname }];
}

export function getCategoryBreadcrumbMicrodata(category, lang): Breadcrumbs[] {
  const breadcrumbList: Breadcrumbs[] = [];
  breadcrumbList.push({
    url: getCategoryUrl(prop(category, 'url', ''), lang),
    name: prop(category, 'category_name', ''),
  });
  if (prop(category, 'parent_categories', []).length > 0) {
    const sortedArray = prop(category, 'parent_categories', [])
      .slice(0)
      .reverse();
    sortedArray.forEach(cat => {
      breadcrumbList.push({
        url: getCategoryUrl(prop(cat, 'url', ''), lang),
        name: prop(cat, 'category_name', ''),
      });
    });
  }
  return breadcrumbList;
}
