import styled from 'styled-components';
import * as React from 'react';
import { FlexColCenter, FlexRowCenter } from 'eshop-defaults';
import { theme } from '../../../theme/theme';
import { rem } from 'polished';
import { prop } from '../../../utilities';

export const ModalMobile = (props: any) => {
  const { src, closeModal, currentIndex, setCurrentImage } = props;
  const currentOriginal = prop(src[currentIndex], 'original', '');
  const total = src.length;
  const isCurrentFirst = currentIndex === 0;
  const isCurrentLast = currentIndex + 1 === total;

  return (
    <StyledModalMobile id="modalMobile" className="modal">
      <CancelButton onClick={closeModal} src={'/images/cancel.svg'} />
      <img className="modal-content" src={currentOriginal} alt="Obrázok"></img>
      <ButtonsWrapper>
        <PreviousButton
          isCurrentFirst={isCurrentFirst}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => setCurrentImage(currentIndex - 1)}
        >
          <img src="/images/blog_assets/back-arrow.svg" alt="Next" />
        </PreviousButton>
        <NextButton
          isCurrentLast={isCurrentLast}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => setCurrentImage(currentIndex + 1)}
        >
          <img src="/images/blog_assets/right-arrow.svg" alt="Previous" />
        </NextButton>
      </ButtonsWrapper>
    </StyledModalMobile>
  );
};

const CancelButton = styled.img`
  position: absolute;
  height: auto;
  width: 20px;
  cursor: pointer;
  top: 20px;
  right: 20px;
`;

const ButtonsWrapper = styled.div`
  max-width: 33%;
  margin: auto;
  padding: 2px;
  display: flex;
  flex-flow: row;
`;

const ArrowButton = styled.button`
  height: ${rem(30)};
  width: ${rem(30)};
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const PreviousButton = styled(ArrowButton)<{ isCurrentFirst?: boolean }>`
  margin-right: ${rem(12)};
  margin-left: ${rem(12)};
  visibility: ${({ isCurrentFirst }) =>
    isCurrentFirst ? 'hidden' : 'initial'};
`;

const NextButton = styled(ArrowButton)<{ isCurrentLast?: boolean }>`
  margin-left: ${rem(12)};
  margin-right: ${rem(12)};
  visibility: ${({ isCurrentLast }) => (isCurrentLast ? 'hidden' : 'initial')};
`;

const StyledModalMobile = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 190px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(242, 242, 242);
  background-color: rgba(242, 242, 242, 0.9);
  align-items: center;

  .modal-content {
    margin: auto;
    display: block;
    max-width: 95%;
    content-fit: cover;
  }

  /* Add Animation - Zoom in the Modal */
  .modal-content {
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
`;
