import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical, IconWithText } from 'eshop-defaults';
import { useInterval } from '../../utilities/hooks';
import { prop } from '../../utilities';

const secondInfoTexts = [
  {
    icon: '/images/zemplin/package.svg',
    textMain: 'Široký sortiment tovaru',
  },
  {
    icon: '/images/zemplin/help.svg',
    textMain: 'Variabilita spolupráce',
  },
  {
    icon: '/images/zemplin/service.svg',
    textMain: 'Servis a poradenstvo',
  },
  {
    icon: '/images/zemplin/settings.svg',
    textMain: 'Výroba na zakázku',
  },
];

interface Props {
  benefits: any[];
}

function SecondIconsBanner({ benefits }: Props) {
  const [secondInfo, setSecondInfo] = useState(0);

  const changeInfoBanners = () => {
    if (benefits) {
      const newSecondInfo =
        secondInfo >= benefits.length - 1 ? 0 : secondInfo + 1;
      setSecondInfo(newSecondInfo);
    }
  };

  useInterval(() => {
    changeInfoBanners();
  }, 3000);

  if (!benefits || benefits.length === 0) {
    return <br />;
  }

  const currentInfo = benefits[secondInfo];
  const secondCurrentInfo =
    benefits[secondInfo >= benefits.length - 1 ? 0 : secondInfo + 1];

  if (!currentInfo || !secondCurrentInfo) {
    return null;
  }

  return (
    <>
      <DesktopSecondInfoBanner>
        {benefits.map(info => (
          <IconWithText
            url={'/benefity'}
            key={prop(info, 'content.name')}
            textMain={prop(info, 'content.name')}
            iconSrc={info.icon}
            alt={`ikonka ku ${prop(info, 'content.name')}`}
            smaller={true}
          />
        ))}
      </DesktopSecondInfoBanner>
      <TabletSecondInfoBanner>
        <IconWithText
          url={'/benefity'}
          key={prop(currentInfo, 'content.name')}
          textMain={prop(currentInfo, 'content.name')}
          iconSrc={currentInfo.icon}
          alt={`ikonka ku ${prop(currentInfo, 'content.name')}`}
          smaller={true}
        />
        <IconWithText
          url={'/benefity'}
          key={prop(secondCurrentInfo, 'content.name')}
          textMain={prop(secondCurrentInfo, 'content.name')}
          iconSrc={secondCurrentInfo.icon}
          alt={`ikonka ku ${prop(secondCurrentInfo, 'content.name')}`}
          smaller={true}
        />
      </TabletSecondInfoBanner>
      <MobileSecondInfoBanner>
        <IconWithText
          url={'/benefity'}
          key={prop(currentInfo, 'content.name')}
          textMain={prop(currentInfo, 'content.name')}
          iconSrc={currentInfo.icon}
          alt={`ikonka ku ${prop(currentInfo, 'content.name')}`}
          smaller={true}
        />
      </MobileSecondInfoBanner>
    </>
  );
}

const DesktopFirstInfoBanner = styled(FlexRowCenterVertical)`
  margin: ${rem(56)} 0;
  width: 100%;
  padding: ${rem(12)} ${rem(41)};
  background-color: ${({ theme }) => theme.colors.secondary};
  justify-content: space-between;
  border-radius: ${rem(4)};
  max-height: ${rem(40)};
  ${({ theme }) => theme.mediab.l925`
     display: none;
    
  `};
`;

const DesktopSecondInfoBanner = styled(DesktopFirstInfoBanner)`
  margin: 0;
  margin-top: ${rem(0)};
  padding: ${rem(12)} ${rem(64)};
  max-height: ${rem(64)};
`;

const TabletSecondInfoBanner = styled(DesktopSecondInfoBanner)`
  display: none;
  justify-content: center;
  margin: ${rem(33)} 0 0;

  ${({ theme }) => theme.mediab.l925`
     display: flex;
     justify-content: space-around;
  `}

  ${({ theme }) => theme.mediab.m580`
     display: none;
  `}
`;

const MobileFirstInfoBanner = styled(DesktopFirstInfoBanner)`
  display: none;
  justify-content: center;
  margin: ${rem(33)} 0 ${rem(40)};
  padding: ${rem(12)} ${rem(16)};

  ${({ theme }) => theme.mediab.m580`
     display: flex;
  `}
`;

const MobileSecondInfoBanner = styled(MobileFirstInfoBanner)`
  padding: ${rem(12)} ${rem(16)};
  margin: ${rem(0)} -${rem(24)} 0;
  width: 100vw;
  min-height: 64px;
`;

export default SecondIconsBanner;
