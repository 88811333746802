import React, { useState } from 'react';
import styled from 'styled-components';
import { prop } from '../../utilities';
import {
  Button,
  FlexCol,
  FlexRowCenter,
  Icon,
  TableWrapper,
  TextP,
  ZemplinLoaderWrapper,
  ZemplinTitle as Title,
  InfoText,
} from 'eshop-defaults';
import { rem } from 'polished';
import { __ } from 'react-i18n';
import { CrmDashboardActivityDetail } from './CrmDashboardActivityDetail';
import { Loader } from '../_helpers/Loader/Loader';
import { PrimaryInput } from '../_helpers/Default/Inputs';
import { PrimaryButton } from '../_helpers/Default/Buttons';
import moment from 'moment';
import FormInput from '../_helpers/Default/FormInput';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';

interface Props {
  isLoading: boolean;
  saveNewActivity: any;
  setShowCreateActivity;
  user: any;
  errorMessage?: string;
}

export function CrmDashboardCreateNewActivity(props: Props) {
  const {
    isLoading,
    saveNewActivity,
    setShowCreateActivity,
    user,
    errorMessage,
  } = props;
  const [predmetValue, setPredmetValue] = useState('');
  const [popisValue, setPopisValue] = useState('');
  const [newContactPersonName, setNewContactPersonName] = useState('');
  const [newContactPersonSurname, setNewContactPersonSurname] = useState('');
  const [newContactPersonEmail, setNewContactPersonEmail] = useState('');
  const [newContactPersonPhone, setNewContactPersonPhone] = useState('');
  const [addNewContactPerson, setAddNewContactPerson] = useState(false);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const validateData = (): boolean => {
    if (addNewContactPerson) {
      return !(
        !newContactPersonEmail ||
        !newContactPersonPhone ||
        !newContactPersonSurname ||
        !newContactPersonName ||
        !predmetValue ||
        !popisValue
      );
    }

    return !(!predmetValue || !popisValue);
  };

  const handleSaveNewActivity = () => {
    const isValid = validateData();

    if (!isValid) {
      return alert('Vyplňte prosím všetky polia označené "*"');
    }

    const contactPerson = {
      ID: addNewContactPerson ? null : prop(user, 'helios_person_id'),
      Meno: addNewContactPerson ? newContactPersonName : prop(user, 'meno'),
      Priezvisko: addNewContactPerson
        ? newContactPersonSurname
        : prop(user, 'priezvisko'),
      Mobil: addNewContactPerson
        ? newContactPersonPhone
        : prop(user, 'telefon'),
      Email: addNewContactPerson ? newContactPersonEmail : prop(user, 'email'),
    };

    saveNewActivity({
      predmetValue,
      popisValue,
      date,
      KontaktnaOsoba: { ...contactPerson },
    });
  };

  const handleReturnToActivitiesListClick = () => {
    setShowCreateActivity(false);
  };

  const handleAddNewContactPersonClick = e => {
    e.preventDefault();
    setAddNewContactPerson(!addNewContactPerson);
  };

  return (
    <Wrapper>
      {isLoading ? (
        <ZemplinLoaderWrapper height={500} />
      ) : (
        <>
          <Row>
            <ReturnToActivitiesListWrapper
              onClick={handleReturnToActivitiesListClick}
            >
              <Icon
                src="/images/blog_assets/arrow_back_gray.svg"
                style={{ height: '30px', width: '30px' }}
              />
              <p style={{ marginLeft: '6px', marginRight: '32px' }}>
                {__('Späť na zoznam')}
              </p>
            </ReturnToActivitiesListWrapper>
            <Title marginTop={24} marginBottom={32}>
              {__('Nová služobná cesta')}
            </Title>
          </Row>

          <FormWrapper>
            <StyledTextArea
              name="predmet"
              value={predmetValue}
              onChange={e => setPredmetValue(e.target.value)}
              placeholder={`${__('Predmet')}*`}
              required={true}
            />
            <StyledTextArea
              name="popis"
              value={popisValue}
              onChange={e => setPopisValue(e.target.value)}
              placeholder={`${__('Popis')}*`}
              required={true}
            />
            <Row>
              <p
                style={{
                  minWidth: 'min-content',
                  color: 'black',
                  marginRight: '24px',
                }}
              >{`${__('Dátum stretnutia:')}`}</p>
              <FormInput
                type="date"
                name={'from'}
                value={date}
                onChange={e => setDate(e.target.value)}
                maxWidth={250}
                required={true}
              />
            </Row>
            <Row>
              {user.helios_person_id ? (
                <p>
                  <span style={{ color: 'black', marginRight: '24px' }}>
                    {'Kontaktná osoba: '}
                  </span>
                  {user.helios_person_id
                    ? `${user.meno || ''} ${user.priezvisko ||
                        ''} ${user.phone || ''} ${user.email || ''}`
                    : ' Kontaktná osoba nie je k dispozícií '}
                </p>
              ) : null}
              <AddContactPersonButton onClick={handleAddNewContactPersonClick}>
                {!user.helios_person_id
                  ? __('Pridať kontaktnú osobu')
                  : __('Použiť inú kontaktnú osobu')}
              </AddContactPersonButton>
            </Row>
            {addNewContactPerson ? (
              <Col>
                <FormInput
                  placeholder={'Meno*'}
                  name={'new_contact_person_name'}
                  value={newContactPersonName}
                  onChange={e => setNewContactPersonName(e.target.value)}
                  maxWidth={250}
                  required={true}
                  marginBottom={24}
                />
                <FormInput
                  placeholder={'Priezvisko*'}
                  name={'new_contact_person_surname'}
                  value={newContactPersonSurname}
                  onChange={e => setNewContactPersonSurname(e.target.value)}
                  maxWidth={250}
                  required={true}
                  marginBottom={24}
                />
                <FormInput
                  placeholder={'Email*'}
                  name={'new_contact_person_email'}
                  value={newContactPersonEmail}
                  onChange={e => setNewContactPersonEmail(e.target.value)}
                  maxWidth={250}
                  required={true}
                  marginBottom={24}
                />
                <FormInput
                  placeholder={'Mobil*'}
                  name={'new_contact_person_phone'}
                  value={newContactPersonPhone}
                  onChange={e => setNewContactPersonPhone(e.target.value)}
                  maxWidth={250}
                  required={true}
                  marginBottom={24}
                />
              </Col>
            ) : null}

            {!!errorMessage && (
              <InfoText type={InfoType.ERROR} info={errorMessage} />
            )}
            <SubscribeButton type="submit" onClick={handleSaveNewActivity}>
              {isLoading ? <Loader small={true} /> : __('Odoslať')}
            </SubscribeButton>
          </FormWrapper>
        </>
      )}
    </Wrapper>
  );
}

const Row = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  max-height: content;
  align-items: center;
  margin-bottom: 24px;
`;

const Col = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-height: content;
  align-items: center;
  justify-items: center;
`;

const ReturnToActivitiesListWrapper = styled.div`
  display: flex;
  flex-flow: row;
  min-width: 160px;
  &:hover {
    cursor: pointer;
  }
  align-items: center;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: ${rem(120)};
  background: ${({ theme }) => theme.color.white};
  border-radius: ${rem(4)};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.alto};
  margin-bottom: ${rem(20)};
  padding: ${rem(10)} ${rem(15)};
  font-family: ${({ theme }) => theme.font.primary};
  resize: none;

  &::placeholder {
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.color.textSecondary};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 12px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  align-items: center;
  justify-content: center;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: ${rem(24)};
  width: 100%;

  ${({ theme }) => theme.mediab.m580`
   flex-flow: column;
  `};
`;

const SubscribeButton = styled(PrimaryButton)`
  margin-top: 36px;
  height: 50px;

  ${({ theme }) => theme.mediab.m580`
   width: 100%;
  `};
`;

const AddContactPersonButton = styled.button`
  height: 40px;
  margin-left: 36px;
  background: #26bf38;
  border: 0;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  ${({ theme }) => theme.mediab.m580`
   width: 100%;
`};
`;
