import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  MetaTags,
  ZemplinSelect as Select,
  ZemplinCheckbox as CheckBox,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import {
  fetchChildUsers,
  fetchRequests,
  requestsDataSelector,
  requestsByIdSelector,
  requestsIsFetchingSelector,
  childUsersSelector,
} from './myAccountSlice';
import API, { ThenArg } from '../../services/API';
import MyRequests from '../../components/MyAccount/MyRequests';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';

const LIMIT = 5;

interface Props {
  user: any;
  dispatch: any;
  isFetching: boolean;
  requestsById: object;
  requestsData: any;
  token: string;
  childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
}

interface State {
  currentId: string;
  isDownloading: boolean;
}

class MyRequestsContainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_REQUESTS));
      await Promise.all([
        await dispatch(fetchChildUsers()),
        await dispatch(fetchRequests(null, LIMIT, 0)),
      ]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
      isDownloading: false,
    };
  }

  public downloadFile = (order: any, type?: string) => {
    const { token, user } = this.props;
    this.setState({ isDownloading: true });

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/customers/${
        user.id
      }/helios-request/${order.helios_id}${
        type ? `?document_type=${type}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response: any) => response.blob())
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        if (type === 'csv') {
          link.setAttribute('download', `CenovaPonuka_${order.helios_id}.csv`);
        } else {
          link.setAttribute('download', `CenovaPonuka_${order.helios_id}.pdf`);
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        this.setState({ isDownloading: false });

        // Clean up and remove the link
        (link as any).parentNode.removeChild(link);
      });
  };

  public render() {
    const {
      user,
      requestsById,
      requestsData,
      childUsers,
      isFetching,
    } = this.props;
    const { currentId, isDownloading } = this.state;

    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: (user.id || '').toString(),
          }))
          .map(a => a)
      : [];
    const options = [
      { name: 'Ja', value: user.id.toString() },
      ...childUserOptions,
    ];

    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Moje dopyty' }} />
        <MyRequests
          requests={requestsById[currentId]}
          requestsData={requestsData}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetching={isFetching}
          downloadFile={this.downloadFile}
          isDownloading={isDownloading}
          user={user}
        />
      </>
    );
  }

  public renderFilters() {
    return (
      <FilterWrapper>
        <Select
          value={''}
          options={[{ name: '', value: '' }]}
          onChange={() => {}}
        />
        <CheckBox label="Spracovaný" selected={true} />
        <CheckBox label="Spracováva sa" selected={false} />
        <CheckBox label="Čaká na spracovanie" selected={true} />
      </FilterWrapper>
    );
  }

  public handleSelectChange = e => {
    const id = e.target.value;

    this.setState({ currentId: id });
    this.props.dispatch(fetchRequests(id, LIMIT, 0));
  };

  public handleOffsetChange = e => {
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;
    const offset = LIMIT * num;
    this.props.dispatch(fetchRequests(currentId, LIMIT, offset));
  };
}

const FilterWrapper = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    align-items: flex-start;
  `}
`;

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    requestsData: requestsDataSelector(state),
    requestsById: requestsByIdSelector(state),
    isFetching: requestsIsFetchingSelector(state),
    childUsers: childUsersSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyRequests' })(MyRequestsContainer),
);
