import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { B2BRegisterFormFields, MetaTags, RegisterForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { registerUser } from '../App/actions';
import {
  registrationIsFetchingSelector,
  registrationSuccessMessageSelector,
  registrationErrorMessageSelector,
} from '../App/selectors';
import { onlyPublic } from '../../utilities/auth';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { B2CRegisterFromFields } from 'eshop-defaults/lib/components/Zemplin/Auth/types';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
  isFetching: boolean;
  successMessage: string;
  errorMessage: string;
}

interface State {
  error: string;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(702)};
  margin: ${rem(32)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: 0 ${rem(16)};
     margin: ${rem(32)} auto;
  `}
`;

class RegisterPage extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  public componentDidMount() {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.REGISTRATION, null));
  }

  public render() {
    const { cartId, isFetching, successMessage, errorMessage } = this.props;

    return (
      <>
        <Wrapper>
          <MetaTags tags={{ title: 'Registrácia' }} />
          <RegisterForm
            cartId={cartId}
            handleFormSubmit={this.handleFormSubmit}
            isFetching={isFetching}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
        </Wrapper>
        <InfoBanner />
      </>
    );
  }

  public handleFormSubmit = (fields: B2CRegisterFromFields) => {
    this.props.dispatch(registerUser(fields, true));
  };

  public handleFacebookLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/facebook?state="${this.props.cartId}"`;
  };

  public handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/google?state="${this.props.cartId}"`;
  };
}

const mapStateToProps = state => {
  return {
    cartId: state.general.lang,
    isFetching: registrationIsFetchingSelector(state),
    successMessage: registrationSuccessMessageSelector(state),
    errorMessage: registrationErrorMessageSelector(state),
  };
};

const withRouterLoginPage = onlyPublic(RegisterPage);
export default connect(mapStateToProps)(withRouter(withRouterLoginPage));
