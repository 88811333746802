import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
} from 'eshop-defaults';
import { Modal } from '../_helpers/Modal/Modal';
import { __, __r } from 'react-i18n/lib';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import OutsideClick from '../_helpers/Default/OutsideClick';
import {
  addToCartModalProductSelector,
  addToCartModalVisibleSelector,
  fetchCart,
  resetCart,
  setAddToCartModalVisibility,
} from '../../containers/Cart/cartSlice';
import { prop } from '../../utilities';
import { Loader } from '../_helpers/Loader/Loader';
import AccountSelectItem from './AccountSelectItem';
import API from '../../services/API';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';
import { removeCartCookie } from '../../containers/Cart/helpers';
import { CrmDashboardHeader } from './CrmDashboardHeader';
import { CrmDashboardActivitiesList } from './CrmDashboardActivitiesList';
import { CrmDashboardCreateNewActivity } from './CrmDashboardCreateNewActivity';

interface Props {
  lang: string;
  dispatch: any;
  user: any;
  token: string;
}

function AccountSelectWindow({ dispatch, user, lang, token }: Props) {
  const closeModal = () => {
    dispatch(setAddToCartModalVisibility(false, null));
  };
  let loadResultsTimeout: any = React.useRef<any>(null);
  const [query, setQuery] = React.useState<any>(null);
  const [activityError, setActivityError] = React.useState<string>('');
  const [checkBoxChecked, setcheckBoxChecked] = React.useState<boolean>(false);
  const [usersCrmDashboardShowed, setUsersCrmDashboardShowed] = React.useState<
    boolean
  >(false);
  const [isFetchingActivities, setIsFetchingActivities] = React.useState<
    boolean
  >(false);
  const [showedUserData, setShowedUserData] = React.useState<any>(null);
  const [showedUserActivities, setShowedUserActivities] = React.useState<any>(
    null,
  );
  const [crmUserActivities, setCrmUserActivities] = React.useState<any>([]);
  const [data, setData] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showCreateActivity, setShowCreateActivity] = React.useState(false);
  const baseTimeIntervalMs = 750;

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await API.loadCustomerRelations(user.id, {
        relation: 'CAN_IMPERSONATE',
        query: query ? query : undefined,
        myCustomersOnly: checkBoxChecked,
        limit: 400,
      });
      setData(prop(result, 'users'));
      setIsLoading(false);
    };
    loadResultsTimeout.current = setTimeout(async () => {
      await Promise.all([fetchData()]);
    }, baseTimeIntervalMs);
  }, [query, checkBoxChecked, user.id]);

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const handleQueryChange = event => {
    if (loadResultsTimeout) {
      clearTimeout(loadResultsTimeout);
    }
    setQuery(event.target.value);
  };

  const impersonate = async id => {
    let data;
    try {
      // Odstranime cart cookie pre OZ
      removeCartCookie();

      data = await API.impersonateUser({
        user: id,
        redirectUri: `${process.env.REACT_APP_BASE_URL}${'/prihlasenie'}`,
      });
    } catch (e) {
      console.log(e);
      alert(
        `${e.details.description}, ${__(
          'Zákazník nespadá do vašej kompetencie',
        )}`,
      );
    }

    if (data && data.redirectUriFinal) {
      await dispatch(
        logoutUser(user.cart_id, () => {
          dispatch(fetchCart(true));
        }),
      );

      if (window) {
        window.location.href = data.redirectUriFinal;
        window.location.reload();
      }
    }
  };

  const showUsersCrmDashboard = async userData => {
    setUsersCrmDashboardShowed(true);
    setShowedUserData(userData);
    setIsFetchingActivities(true);
    let data;

    try {
      data = await API.loadHeliosActivities(userData.id);
    } catch (e) {
      console.log(e);
      setIsFetchingActivities(false);
      setCrmUserActivities([]);
      return;
    }

    if (data) {
      setCrmUserActivities(data.activities);
      setIsFetchingActivities(false);
    }
  };

  const handleReturnToDashboard = () => {
    setUsersCrmDashboardShowed(false);
    setShowedUserData(null);
    setCrmUserActivities(null);
    setQuery('');
  };

  const handleCheckBoxChange = () => {
    setcheckBoxChecked(!checkBoxChecked);
  };

  const handleLogoutUser = async () => {
    await dispatch(
      logoutUser(user.cart_id, () => {
        dispatch(fetchCart(true));
      }),
    );
    removeCartCookie();
    dispatch(resetCart(false));
    if (window) {
      window.location.reload();
    }
  };

  const createActivity = () => {
    setShowCreateActivity(true);
  };

  const saveNewActivity = async data => {
    setIsFetchingActivities(true);
    const dataToSend = {
      AktivitaPredmet: data.predmetValue,
      AktivitaPopis: data.popisValue,
      KontaktnaOsoba: [data.KontaktnaOsoba],
      Datum: data.date,
    };
    try {
      await API.createHeliosActivity(showedUserData.id, {}, dataToSend);
      setShowCreateActivity(false);
      await showUsersCrmDashboard(showedUserData);
    } catch (e) {
      console.log('err: ', e);
      setActivityError(prop(e, 'details.description'));
    }

    setIsFetchingActivities(false);
  };

  return (
    <>
      <Dimmer topZero={true} height={100} zIndex={9999999990} />
      <OutsideClick handleFunction={closeModal}>
        <WrapperModal>
          <Wrapper>
            {/*-------  CRM DASHBOARD HEADING  --------*/}
            <Heading>
              {usersCrmDashboardShowed ? (
                <ReturnToDashboardWrapper onClick={handleReturnToDashboard}>
                  <Icon src="/images/blog_assets/arrow_back_gray.svg" />
                  <p style={{ marginLeft: '6px', marginRight: '32px' }}>
                    {__('Dashboard')}
                  </p>
                </ReturnToDashboardWrapper>
              ) : null}

              <Title style={{ lineHeight: '34px' }}>
                {!usersCrmDashboardShowed
                  ? __('Vyberte zákazníka')
                  : __('Tvorba zápisov zo služobných ciest')}
              </Title>
              {!!user && (
                <Logout onClick={handleLogoutUser}>{__('Odhlásiť sa')}</Logout>
              )}
            </Heading>
            {/*-------  CRM USER DETAIL DASHBOARD  --------*/}
            {usersCrmDashboardShowed ? (
              <>
                <DashboardWrapper>
                  <CrmDashboardHeader
                    userData={showedUserData}
                    createActivity={createActivity}
                    impersonateUser={impersonate}
                  />
                  {!showCreateActivity ? (
                    <CrmDashboardActivitiesList
                      activities={crmUserActivities}
                      isFetchingActivities={isFetchingActivities}
                      token={token}
                    />
                  ) : (
                    <CrmDashboardCreateNewActivity
                      isLoading={isFetchingActivities}
                      saveNewActivity={saveNewActivity}
                      setShowCreateActivity={setShowCreateActivity}
                      user={showedUserData}
                      errorMessage={activityError}
                    />
                  )}
                </DashboardWrapper>
              </>
            ) : (
              <>
                {/*  -----  CRM USER SELECT DASHBOARD   ------  */}
                <InputsWrapper>
                  <StyledInput
                    onChange={handleQueryChange}
                    placeholder={__('Hľadaný výraz...')}
                  />
                  <StyledCeckBox
                    type={'checkbox'}
                    onChange={handleCheckBoxChange}
                    label={__('Hľadaný výraz...')}
                  />
                  <InputLabel>{__('Zobraziť mojich zákazníkov')}</InputLabel>
                </InputsWrapper>

                {isLoading ? (
                  <Loader />
                ) : (
                  <ItemWrapper>
                    {data &&
                      data.map(d => {
                        return (
                          <AccountSelectItem
                            onClick={() => showUsersCrmDashboard(d)}
                            key={d.id}
                            name={d.meno}
                            email={d.email}
                            surname={d.priezvisko}
                            crm_obchodnik_email={d.crm_obchodnik_email}
                            contact_email_1={d.contact_email_1}
                            contact_email_2={d.contact_email_2}
                            contact_email_3={d.contact_email_3}
                            firma={d.firma}
                            ico={d.ico}
                            eshop_user={d.eshop_user}
                          />
                        );
                      })}
                  </ItemWrapper>
                )}
              </>
            )}
          </Wrapper>
        </WrapperModal>
      </OutsideClick>
    </>
  );
}

const DashboardWrapper = styled.div`
  max-width: 960px;
  margin: auto auto;
`;

const InputLabel = styled.p`
  width: 200px;
  margin: 0px;
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.08);
  border-radius: ${rem(4)};
`;

export const WrapperModal = styled(StyledModal)`
  /* max-width: ${rem(500)}; */
  width: 98%;
  height: 95%;
  overflow-y: scroll;
  padding: 24px 0px;
`;

const StyledInput = styled.input`
  margin-top: 0;
  width: ${rem(400)};
  padding: 12px 24px;
  border: solid 1px #ededed;s
`;

const StyledCeckBox = styled.input`
  width: ${rem(40)};
  border: solid 1px #ededed;
  margin-left: 40px;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(32)} ${rem(24)} 0;
`;

const Title = styled(TextP)`
  font-size: ${rem(30)};
  font-weight: 400;
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 0px 24px;
`;

const Heading = styled(FlexRowCenterVertical)`
  width: 100%;
  margin-bottom: ${rem(24)};
  justify-content: space-between;
`;

const IconWrapper = styled(FlexRowCenterVertical)`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const Logout = styled(TextP)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.primary};
  min-width: ${rem(100)};
  text-align: right;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const ItemWrapper = styled.div`
  margin-bottom: ${rem(40)};
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
  max-height: calc(100vh - 210px);
  height: 100%;
`;

const ProductImg = styled.img`
  margin-right: ${rem(8)};
`;

const Buttons = styled(FlexRowCenterVertical)`
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: 4px;
`;

const CloseButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Name = styled(TextP)`
  font-weight: 500;
`;

const Icon: any = styled.img`
  width: 30px;
  height: 30px;
`;

const ReturnToDashboardWrapper = styled.div`
  display: flex;
  flex-flow: row;
  min-width: 160px;
  &:hover {
    cursor: pointer;
  }
  align-items: center;
`;

const mapStateToProps = state => {
  return {
    lang: state.general.lang,
    modalVisible: addToCartModalVisibleSelector(state),
    modalProduct: addToCartModalProductSelector(state),
  };
};

export default connect(mapStateToProps)(AccountSelectWindow);
