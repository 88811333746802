import React, { useEffect } from 'react';
import styled from 'styled-components';
import { prop } from '../../utilities';
import {
  FlexCol,
  TableTh,
  FlexRowCenter,
  TextP,
  ZemplinTitle as Title,
} from 'eshop-defaults';
import { rem } from 'polished';
import { __ } from 'react-i18n';
import { CrmDashboardActivityRow } from './CrmDashboardActivityRow';
import { TableWrapper, ZemplinLoaderWrapper } from 'eshop-defaults';
import { CrmDashboardActivityDetail } from './CrmDashboardActivityDetail';

interface Props {
  activities;
  isFetchingActivities;
  token: string;
}

export function CrmDashboardActivitiesList(props: Props) {
  const { activities, isFetchingActivities, token } = props;
  const [showActivityDetail, setShowActivityDetail] = React.useState(null);
  const [showLoader, setShowLoader] = React.useState(false);

  const columns = [
    { name: 'Id aktivity' },
    { name: 'Dátum' },
    { name: 'Predmet stretnutia', width: '100%' },
  ];

  const headers =
    columns &&
    columns.map((item: any) => (
      <SecondTableTh
        width={item.width}
        minWidth={item.minWidth}
        align={item.align || 'left'}
        key={item.name}
      >
        {item.name}
      </SecondTableTh>
    ));

  const handleShowActivityDetail = activity => {
    setShowActivityDetail(activity);
  };

  const activityRows =
    activities &&
    activities.map(actvity => (
      <CrmDashboardActivityRow
        data={actvity}
        handleShowActivityDetail={handleShowActivityDetail}
      />
    ));

  useEffect(() => {
    setShowLoader(isFetchingActivities);
  }, [isFetchingActivities]);

  return (
    <Wrapper>
      {showLoader ? (
        <ZemplinLoaderWrapper height={500} />
      ) : (
        <>
          {showActivityDetail ? (
            <CrmDashboardActivityDetail
              activityData={showActivityDetail}
              handleShowActivitiesClick={handleShowActivityDetail}
              setShowLoader={setShowLoader}
              token={token}
            />
          ) : (
            <>
              <Title marginTop={24} marginBottom={32}>
                {__('Služobné cesty')}
              </Title>
              <TableWrapper
                tableHasBorder={true}
                tableHasBoxShadow={true}
                noItemsText={__('Nenašli sa žiadne aktivity')}
                headers={headers}
                rows={activityRows}
              />
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const SecondTableTh: any = styled(TableTh)<{
  width?: string;
  minWidth?: string;
}>`
  padding: ${rem(16)} ${rem(16)};
  border-bottom: 0;
  background-color: ${({ theme }) => theme.colors.navSecondary};
  color: ${({ theme }) => theme.colors.primary};
  width: ${({ width }) => (width ? width : '')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 'initial')};
  white-space: nowrap;
  font-weight: normal;
  font-family: Montserrat;

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(13)};
    line-height: ${rem(21)};
  `}
`;
