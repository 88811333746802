import styled from 'styled-components';

export const MobileWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediab.l925`
    display: block;
  `}
`;

export const DesktopWrapper = styled.div`
  display: block;

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;
