import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  MetaTags,
  ZemplinLoaderWrapper,
  InfoText,
  OrderDetail,
} from 'eshop-defaults';
import {
  fetchRequestDetail,
  requestDetailDataSelector,
  requestDetailIsFetchingSelector,
} from './myAccountSlice';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
}

class MyOrdersDetail extends React.Component<Props> {
  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { id },
    } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_ORDERS));
      await dispatch(fetchRequestDetail(id));
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const { data, isFetching, user } = this.props;

    if (isFetching) {
      return <ZemplinLoaderWrapper />;
    }

    if (!data) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Dopyt sa nepodarilo načítať')}
        />
      );
    }

    const { order_id } = data;

    return (
      <>
        <MetaTags
          tags={{ title: `${__('Moj účet - Detail dopytu č.')} ${order_id}` }}
        />
        <OrderDetail data={data} isB2B={user && user.b2b} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    data: requestDetailDataSelector(state),
    isFetching: requestDetailIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyOrdersDetail' })(MyOrdersDetail),
);
