import API from './services/API';
import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import rootReducer from './rootReducer';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';
import { prop } from 'eshop-defaults';
import * as cookies from 'react-cookies';
import { USER_COOKIE } from 'react-auth';

let composeEnhancers;

if (typeof window !== 'undefined') {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  if (prop(window.__REDUX_STATE__, 'auth.token', undefined)) {
    API.setToken(prop(window.__REDUX_STATE__, 'auth.token', undefined));
  } else if (cookies.load(USER_COOKIE)) {
    API.setToken(cookies.load(USER_COOKIE));
  } else {
    API.setToken('');
  }
} else {
  composeEnhancers = compose;
}

// export const history = createBrowserHistory();
const middleware = routerMiddleware(browserHistory);

const thunk: ThunkMiddleware<{}, AnyAction> = thunkMiddleware.withExtraArgument(
  API,
);

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  applyMiddleware(middleware),
);

export default function configureStore(initialState?: object) {
  return createStore(rootReducer, initialState!, enhancer);
}
