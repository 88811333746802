import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinSelect as Select,
  Invoices,
  PaginationSimple,
  Loader as DefaultLoader,
  TextP,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import { Title } from 'eshop-defaults/lib/components/Zemplin/General/Title';

interface Props {
  invoices: any[];
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  downloadFile: any;
  currency: string;
  isDownloading: boolean;
  refetchData: any;
}

function MyInvoices({
  currentId,
  invoices,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  downloadFile,
  currency,
  isDownloading,
  refetchData,
}: Props) {
  const { limit, offset, total } = ordersData;

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {__('Faktúry a dobropisy')}
      </StyledTitle>
      <Invoices
        isFetching={isFetchingOrders}
        orders={prop(invoices, 'data')}
        downloadFile={downloadFile}
        currency={currency}
        refetchData={refetchData}
        sumPrice={prop(invoices, 'price.sumWithoutVat') || 0}
      />
      <PaginationSimple
        totalItems={prop(invoices, 'pagination.total')}
        limit={prop(invoices, 'pagination.limit')}
        offset={prop(invoices, 'pagination.offset')}
        handleOffsetChange={handleOffsetChange}
      />
      {isDownloading && (
        <LoaderWrapper>
          <DefaultLoader
            style={{
              position: isDownloading ? 'absolute' : 'initial',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 6,
            }}
            loading={isDownloading}
            dim={true}
          >
            <TextP
              style={{
                position: 'fixed',
                top: '60%',
                width: '100%',
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: 700,
              }}
            >
              {__('Pracujeme na príprave Vašej faktúry')}
            </TextP>
          </DefaultLoader>
        </LoaderWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const StyledTitle = styled(Title)`
  margin-bottom: ${rem(32)};
  margin-top: 0;
`;

const LoaderWrapper = styled(FlexCol)``;

export default MyInvoices;
