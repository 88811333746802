import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinTitle as Title,
  InfoText,
  Invoices,
  ZemplinSelect as Select,
  Orders,
  PaginationSimple,
  ZemplinCheckbox,
  prop,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';

interface Props {
  invoices: any;
  orders: ThenArg<typeof API.getOrders>;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  userId: number;
}

function MyOrders({
  currentId,
  orders,
  invoices,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  customerInfo,
  userId,
}: Props) {
  const { limit, offset, total } = ordersData;
  const defaultNewsletterAccept = prop(customerInfo, 'newsletter_accept');
  const [isNewsletterAccept, setIsNewsletterAccept] = React.useState(
    prop(customerInfo, 'newsletter_accept'),
  );

  React.useEffect(() => {
    setIsNewsletterAccept(defaultNewsletterAccept);
  }, [defaultNewsletterAccept]);

  const [newsletterDataUpdated, setNewsletterDataUpdated] = React.useState(
    false,
  );
  const [newsletterError, setNewsletterError] = React.useState(null);

  const toggleNewsletter = async () => {
    try {
      setIsNewsletterAccept(a => !a);
      await API.updateCustomerDetails(
        userId,
        {},
        {
          ...customerInfo,
          newsletter_accept: !isNewsletterAccept,
        },
      );
    } catch (e) {
      setNewsletterError(e as any);
      return;
    }
    setNewsletterDataUpdated(true);
  };

  return (
    <Wrapper>
      {/* faza 2 - uprava API + napojenie na frontende */}
      {/* {false && (
        <>
          <Title marginTop={0} marginBottom={32}>
            {__('Neuhradené faktúry')}
          </Title>
          <InfoText
            type={'ERROR'}
            info={'Máte 1 neuhradenú faktúru po splatnosti.'}
          />
          <Invoices isFetching={false} invoices={invoices} />
        </>
      )} */}

      <Title marginTop={0} marginBottom={32}>
        {__('Moje objednávky')}
      </Title>
      <ZemplinCheckbox
        name={'newsletterAgree'}
        type={'checkbox'}
        selected={isNewsletterAccept}
        label={
          <>
            {__('Súhlasím so zasielaním newslettra')}{' '}
            <a
              target="__blank"
              rel="noopener noreferrer"
              href={
                'https://www.appgdpr.sk/doc/01HQR-HZY7T-JEEH-94V1-71NP-8HXP/DIP%20Vyhl%c3%a1senie%20o%20ochrane%20s%c3%bakromia%20-%20In%c3%a9%20[odberatelia%20newslettra].html'
              }
            >
              ({__('podmienky súhlasu')})
            </a>
          </>
        }
        wrapperStyle={{ marginBottom: rem(32) }}
        onClick={toggleNewsletter}
      />
      {!newsletterError && newsletterDataUpdated && (
        <InfoText
          type={InfoType.SUCCESS}
          info={__('Súhlas bol úspešne zmenený')}
        />
      )}
      {options && options.length > 1 && (
        <Select
          value={currentId}
          options={options}
          onChange={handleSelectChange}
        />
      )}
      <Orders isFetching={isFetchingOrders} orders={orders} />
      <PaginationSimple
        totalItems={total}
        limit={limit}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MyOrders;
