import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical, IconWithText } from 'eshop-defaults';
import { useInterval } from '../../utilities/hooks';
import { prop } from '../../utilities';

const firstInfoTexts = [
  {
    icon: '/images/zemplin/heart.svg',
    textMain: 'Starostlivosť o zákazníka',
    textSecondary: 'Staráme sa o našich zákazníkov',
  },
  {
    icon: '/images/zemplin/fast.svg',
    textMain: 'Rýchla odozva',
    textSecondary: 'Váš dopyt vybavíme do niekoľkých hodín',
  },
  {
    icon: '/images/zemplin/wallet.svg',
    textMain: 'Náhradné diely',
    textSecondary: 'Garantujeme dostupnosť náhradných dielov',
  },
  {
    icon: '/images/zemplin/thumbup.svg',
    textMain: '23 rokov na trhu',
    textSecondary: 'Firma s tradíciou',
  },
];

interface Props {
  benefits: any[];
}

function IconsBanner({ benefits }: Props) {
  const [firstInfo, setFirstInfo] = useState(0);

  const changeInfoBanners = () => {
    if (benefits) {
      const newFirstInfo = firstInfo >= benefits.length - 1 ? 0 : firstInfo + 1;
      setFirstInfo(newFirstInfo);
    }
  };

  useInterval(() => {
    changeInfoBanners();
  }, 3000);

  if (!benefits || benefits.length === 0) {
    return null;
  }

  const currentInfo = benefits[firstInfo];
  const secondCurrentInfo =
    benefits[firstInfo + 1 >= benefits.length - 1 ? 0 : firstInfo + 1];

  if (!currentInfo || !secondCurrentInfo) {
    return null;
  }

  return (
    <>
      <DesktopFirstInfoBanner>
        {benefits &&
          benefits.map(info => (
            <IconWithText
              url={'/benefity'}
              key={prop(info, 'content.name')}
              textMain={prop(info, 'content.name')}
              textSecondary={
                prop(info, 'content.json_content.body', '') &&
                typeof prop(info, 'content.json_content.body', '') === 'string'
                  ? prop(info, 'content.json_content.body', '').replace(
                      /<[^>]*>?/gm,
                      '',
                    )
                  : ''
              }
              iconSrc={info.icon}
              alt={`ikonka ku ${prop(info, 'content.name')}`}
            />
          ))}
      </DesktopFirstInfoBanner>
      <TabletSecondInfoBanner>
        <IconWithText
          url={'/benefity'}
          key={prop(currentInfo, 'content.name')}
          textMain={prop(currentInfo, 'content.name')}
          textSecondary={
            prop(currentInfo, 'content.json_content.body', '') &&
            typeof prop(currentInfo, 'content.json_content.body', '') ===
              'string'
              ? prop(currentInfo, 'content.json_content.body', '').replace(
                  /<[^>]*>?/gm,
                  '',
                )
              : ''
          }
          iconSrc={currentInfo.icon}
          alt={`ikonka ku ${prop(currentInfo, 'content.name')}`}
          smaller={true}
        />
        <IconWithText
          url={'/benefity'}
          key={prop(secondCurrentInfo, 'content.name')}
          textMain={prop(secondCurrentInfo, 'content.name')}
          textSecondary={
            prop(secondCurrentInfo, 'content.json_content.body', '') &&
            typeof prop(secondCurrentInfo, 'content.json_content.body', '') ===
              'string'
              ? prop(
                  secondCurrentInfo,
                  'content.json_content.body',
                  '',
                ).replace(/<[^>]*>?/gm, '')
              : ''
          }
          iconSrc={secondCurrentInfo.icon}
          alt={`ikonka ku ${prop(secondCurrentInfo, 'content.name')}`}
          smaller={true}
        />
      </TabletSecondInfoBanner>
      <MobileFirstInfoBanner>
        {' '}
        <IconWithText
          url={'/benefity'}
          key={prop(currentInfo, 'content.name')}
          textMain={prop(currentInfo, 'content.name')}
          textSecondary={
            prop(currentInfo, 'content.json_content.body', '') &&
            prop(currentInfo, 'content.json_content.body', '') === 'string'
              ? prop(currentInfo, 'content.json_content.body', '').replace(
                  /<[^>]*>?/gm,
                  '',
                )
              : ''
          }
          iconSrc={currentInfo.icon}
          alt={`ikonka ku ${prop(currentInfo, 'content.name')}`}
        />
      </MobileFirstInfoBanner>
    </>
  );
}

const DesktopFirstInfoBanner = styled(FlexRowCenterVertical)`
  margin: ${rem(56)} ${rem(0)} ${rem(12)} ${rem(0)};
  width: 100%;
  padding: ${rem(12)} ${rem(41)};
  background-color: ${({ theme }) => theme.colors.secondary};
  justify-content: space-between;
  border-radius: ${rem(4)};

  ${({ theme }) => theme.mediab.l1300`
  margin: ${rem(0)} ${rem(0)};
    
  `}

  ${({ theme }) => theme.mediab.l925`
     display: none;
    
  `}
`;

const DesktopSecondInfoBanner = styled(DesktopFirstInfoBanner)`
  margin: 0;
  margin-top: ${rem(80)};
  padding: ${rem(12)} ${rem(64)};
  max-height: ${rem(64)};
`;

const TabletSecondInfoBanner = styled(DesktopSecondInfoBanner)`
  display: none;
  justify-content: center;
  margin: ${rem(33)} 0 0;

  ${({ theme }) => theme.mediab.l925`
     display: flex;
     justify-content: space-around;
  `}

  ${({ theme }) => theme.mediab.m580`
     display: none;
  `}
`;

const MobileFirstInfoBanner = styled(DesktopFirstInfoBanner)`
  display: none;
  justify-content: center;
  width: 100vw;
  margin-left: ${rem(-24)};
  margin-right: ${rem(-10)};
  margin-top: ${rem(12)};
  padding: ${rem(12)} ${rem(16)};

  ${({ theme }) => theme.mediab.m580`
     display: flex;
  `}
`;

export default IconsBanner;
