import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { MetaTags, PreRegisterForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { onlyPublic } from '../../utilities/auth';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { push } from 'react-router-redux';

interface Props {
  dispatch: (action: any) => void;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(384)};
  margin: ${rem(80)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: 0 ${rem(16)};
     margin: ${rem(32)} auto;
  `}
`;

function PreRegisterPage({ dispatch }: Props & WithRouterProps) {
  const [isEndUser, setIsEndUser] = React.useState(true);

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.REGISTRATION, null));
  });

  const redirectToForm = () => {
    if (isEndUser) {
      dispatch(push('/registracia-b2c'));
    } else {
      dispatch(push('/registracia-b2b'));
    }
  };

  return (
    <>
      <Wrapper>
        <MetaTags tags={{ title: 'Registrácia' }} />
        <PreRegisterForm
          isEndUser={isEndUser}
          setIsEndUser={setIsEndUser}
          redirectToForm={redirectToForm}
        />
      </Wrapper>
      <InfoBanner />
    </>
  );
}

const withRouterLoginPage = onlyPublic(PreRegisterPage);
export default withRouter(withRouterLoginPage);
