import React from 'react';
import { FlexCol, TextP, SvgIcon } from 'eshop-defaults';
import { rem } from 'polished';
import styled from 'styled-components';
import { Link } from 'react-router';
import { IconType } from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import { formatPriceToString, round } from '../../utilities';

interface Props {
  numberOfProducts: number;
  totalPrice: number;
  currency: string;
  cartRoute: string;
}

function HeaderCart({
  numberOfProducts,
  totalPrice,
  currency,
  cartRoute,
}: Props) {
  const printNumberOfProducts = () => {
    if (numberOfProducts > 0) {
      if (numberOfProducts > 1) {
        if (numberOfProducts > 4) {
          return `${numberOfProducts} produktov`;
        }
        return `${numberOfProducts} produkty`;
      }
      return `1 produkt`;
    }

    return `0 produktov`;
  };

  return (
    <CartLink to={cartRoute}>
      <SvgIcon icon={IconType.cart} alt="Prejsť na košík" cursor={'pointer'} />
      <NameLinkWrapper>
        <NumOfProducts>{printNumberOfProducts()}</NumOfProducts>
        <TotalPrice>
          {totalPrice !== undefined &&
            `${formatPriceToString(totalPrice, currency)}`}
        </TotalPrice>
      </NameLinkWrapper>
    </CartLink>
  );
}

const CartLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${rem(48)};
  transition: color 0.2s ease;

  &:hover p {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const NumOfProducts = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  margin-bottom: ${rem(4)};
`;

const NameLinkWrapper = styled(FlexCol)`
  margin-left: ${rem(16)};

  @media only screen and (max-width: 1740px) {
    min-width: max-content;
  }

  ${({ theme }) => theme.mediab.l1050`
    display: none;
  `}
`;

const TotalPrice = styled(TextP)`
  font-size: ${rem(14)};
`;

export default HeaderCart;
