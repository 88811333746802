import { langSelector } from '../App/selectors';
import { arrayToObject } from '../../utilities';
import { AppThunk } from '../../rootReducer';

export const namespace = 'CMS';

const requestCMSCategoriesTree = () => ({
  type: 'CMS/REQUEST_CMS_CATEGORIES',
});

const receiveCMSCategoriesTreeSuccess = (sitemap, sitemapObj) => ({
  type: 'CMS/RECEIVE_CMS_CATEGORIES_SUCCESS',
  payload: {
    sitemap,
    sitemapObj,
  },
});

const receiveCMSCategoriesTreeError = error => ({
  type: 'CMS/RECEIVE_CMS_CATEGORIES_ERROR',
  payload: {
    error,
  },
});

export const loadCMSCategoriesTree = (siteMapUid: string) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCMSCategoriesTree());
      const { sitemap_tree } = await API.loadSitemapSubtree(
        siteMapUid,
        {},
        { xAcceptLanguage: getState().general.lang },
      );
      const sitemapObj = arrayToObject(sitemap_tree, 'id');
      dispatch(receiveCMSCategoriesTreeSuccess(sitemap_tree, sitemapObj));
    } catch (e) {
      dispatch(receiveCMSCategoriesTreeError(e));
    }
  };
};

const requestCMSArticles = () => ({
  type: 'CMS/REQUEST_CMS_ARTICLES',
});

const receiveCMSArticlesSuccess = articles => ({
  type: 'CMS/RECEIVE_CMS_ARTICLES_SUCCESS',
  payload: {
    articles,
  },
});

const receiveCMSArticlesError = (error: any) => ({
  type: 'CMS/RECEIVE_CMS_ARTICLES_ERROR',
  payload: {
    error,
  },
});

export const loadCMSArticles = (
  sitemapId: number | null = null,
  limit: number = 6,
  offset: number = 0,
): AppThunk => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      dispatch(requestCMSArticles());

      const paramsObject: any = { limit, offset };
      if (!sitemapId) {
        paramsObject.sitemapUniqueId = 'AKTUALITY';
      } else {
        paramsObject.sitemapId = sitemapId;
      }

      const articles = await API.loadArticles(
        {
          ...paramsObject,
          sort: 'created_date',
          sortDir: 'desc',
          isPublished: 1,
        },
        {
          xAcceptLanguage: lang,
        },
      );
      dispatch(receiveCMSArticlesSuccess(articles));
    } catch (e) {
      dispatch(receiveCMSArticlesError(e));
    }
  };
};
