import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import {
  resolveCategoryUrl,
  resolveProductUrl,
} from 'eshop-defaults/lib/utilities/selectors';
import { __, __r } from 'react-i18n';
import { cartRoutes } from '../Cart/utilities';

export enum BreadCrumbType {
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
  LOGIN = 'LOGIN',
  MY_ACCOUNT_INVOICES = 'MY_ACCOUNT_INVOICES',
  REGISTRATION = 'REGISTRATION',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  MY_ACCOUNT_ORDERS = 'MY_ACCOUNT_ORDERS',
  MY_ACCOUNT_REQUESTS = 'MY_ACCOUNT_REQUESTS',
  MY_ACCOUNT_REQUESTS_DETAIL = 'MY_ACCOUNT_REQUESTS_DETAIL',
  MY_ACCOUNT_INFO = 'MY_ACCOUNT_INFO',
  MY_ACCOUNT_DELIVERY_ADDRESSES = 'MY_ACCOUNT_DELIVERY_ADDRESSES',
  MY_ACCOUNT_ACCOUNTS = 'MY_ACCOUNT_ACCOUNTS',
  MY_ACCOUNT_CHANGE_PASSWORD = 'MY_ACCOUNT_CHANGE_PASSWORD',
  MY_BACKORDER = 'MY_BACKORDER',
  CONTACT = 'CONTACT',
  CMS_ARTICLES = 'CMS_ARTICLES',
  CMS = 'CMS',
  NONE = 'NONE',
  CART = 'CART',
  CUSTOM = 'CUSTOM',
}

export type SingleBreadCrumb = { name: string; url: string };
export type BreadCrumbPath = SingleBreadCrumb[];

interface BreadCrumbState {
  path: BreadCrumbPath;
}

const initialState: BreadCrumbState = {
  path: [],
};

const breadCrumbSlice = createSlice({
  name: 'breadCrumb',
  initialState,
  reducers: {
    setBreadCrumb(
      state,
      action: PayloadAction<{
        path: BreadCrumbPath;
      }>,
    ) {
      state.path = action.payload.path;
    },
  },
});

export const { setBreadCrumb } = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;

const breadCrumbDomainSelector = state => state.breadCrumb;

export const breadCrumbSelector = createSelector(
  breadCrumbDomainSelector,
  substate => prop(substate, 'path', null),
);

export const setBreadCrumbPath = (
  type: BreadCrumbType,
  data?: any,
): any => async (dispatch, getState) => {
  const path: BreadCrumbPath = [];
  switch (type) {
    case BreadCrumbType.CATEGORY: {
      prop(data, 'parent_categories', []).map(parentCat => {
        path.push(createBreadCrumbFromCategory(parentCat));
      });
      path.push(createBreadCrumbFromCategory(data));
      break;
    }

    case BreadCrumbType.PRODUCT: {
      prop(data, 'parent_categories', []).map(parentCat => {
        path.push(createBreadCrumbFromCategory(parentCat));
      });
      path.push(createBreadCrumbFromProduct(data));
      break;
    }

    case BreadCrumbType.LOGIN: {
      path.push({
        name: __('Prihlásenie'),
        url: __r('routes:prihlasenie', '/prihlasenie'),
      });
      break;
    }
    case BreadCrumbType.REGISTRATION: {
      path.push({
        name: __('Registrácia'),
        url: __r('routes:registracia', '/registracia'),
      });
      break;
    }

    case BreadCrumbType.FORGOT_PASSWORD: {
      path.push({
        name: __('Zabudnuté heslo'),
        url: __r('routes:zabudnute-heslo', '/zabudnute-heslo'),
      });
      break;
    }

    case BreadCrumbType.MY_ACCOUNT_ORDERS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje objednávky a faktúry'),
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_REQUESTS:
    case BreadCrumbType.MY_ACCOUNT_REQUESTS_DETAIL: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje dopyty'),
        url: __r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty'),
      });
      break;
    }
    case BreadCrumbType.MY_BACKORDER: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Nedodaný tovar'),
        url: __r('routes:moj-ucet/nedodany-tovar', '/moj-ucet/nedodany-tovar'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_INFO: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje údaje'),
        url: __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_DELIVERY_ADDRESSES: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje adresy'),
        url: __r(
          'routes:moj-ucet/moje-dodacie-adresy',
          '/moj-ucet/moje-dodacie-adresy',
        ),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_ACCOUNTS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Správa účtov'),
        url: __r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_CHANGE_PASSWORD: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Zmena hesla'),
        url: __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
      });
      break;
    }

    case BreadCrumbType.CONTACT: {
      path.push({
        name: __('Kontakt'),
        url: __r('routes:kontakt', '/kontakt'),
      });
      break;
    }

    case BreadCrumbType.CMS_ARTICLES: {
      const { isTipsAndTricks } = data;
      path.push({
        name: __(isTipsAndTricks ? 'Tipy a rady' : 'Aktuality'),
        url: __r(
          isTipsAndTricks ? 'routes:tipy-a-rady' : 'routes:aktuality',
          isTipsAndTricks ? '/tipy-a-rady' : '/aktuality',
        ),
      });
      break;
    }

    case BreadCrumbType.CMS: {
      const { articleDetail } = data;
      const isTipsAndTricks = articleDetail?.url?.includes('tipy-a-rady/');
      const isParent = articleDetail?.url?.includes('montaz-krbov-fotogaleria');

      if (isParent) {
        path.push({
          name: __(isTipsAndTricks ? 'Tipy a rady' : 'Aktuality'),
          url: __r(
            isTipsAndTricks ? 'routes:tipy-a-rady' : 'routes:aktuality',
            isTipsAndTricks ? '/tipy-a-rady' : '/aktuality',
          ),
        });
      }
      path.push(createBreadCrumbFromCMSArticle(articleDetail));
      break;
    }

    case BreadCrumbType.CART: {
      path.push({
        name: __('Nákupný košík'),
        url: prop(data, 'url', __r(cartRoutes.INDEX, '/kosik')),
      });
      break;
    }

    case BreadCrumbType.CUSTOM: {
      path.push({
        name: data.name,
        url: data.url,
      });
      break;
    }

    case BreadCrumbType.NONE: {
      break;
    }

    default:
      break;
  }
  dispatch(setBreadCrumb({ path }));
};

const createBreadCrumbFromCategory = (category: any): SingleBreadCrumb => {
  const name = prop(category, 'category_name', '');
  const id = prop(category, 'category_id', '');
  const categoryUrl = prop(category, 'url', 'url');
  const url = resolveCategoryUrl(id, categoryUrl);

  return { name, url };
};

const createBreadCrumbFromProduct = (product: any): SingleBreadCrumb => {
  const name = prop(product, 'name', '');
  const id = prop(product, 'product_id', '');
  const productUrl = prop(product, 'url', 'url');
  const url = resolveProductUrl(id, productUrl);

  return { name, url };
};

const createBreadCrumbFromCMSArticle = (article: any): SingleBreadCrumb => {
  const name = prop(article, 'name', '');
  const url = `/${prop(article, 'url', 'url')}`;

  return { name, url };
};
