import { ga4EventsItem } from '@bart.sk-ecommerce/react-online-marketing';
import { AFFILIATION_VALUE } from '../containers/App/constants';
import {
  resolveDatalayerProducts,
  setNewDatalayerProducts,
} from './localStorage';
import { prop } from '.';

export const trackSelectProduct = (
  product: any,
  actionField?: string,
  idx?: number,
) => {
  const dataLayerProducts: any = resolveDatalayerProducts();

  // ga4EventsItem(
  //   AFFILIATION_VALUE,
  //   'select_item',
  //   [product],
  //   dataLayerProducts,
  //   {
  //     item_list_name: actionField,
  //     idx,
  //   },
  // );
  const key = `${prop(product, 'item.productId') ||
    prop(product, 'product_id')}-${prop(product, 'item.goodId') ||
    prop(product, 'main_good.good_id') ||
    prop(product, 'main_good_id')}`;
  const alreadyPushedItem = dataLayerProducts[key];
  if (
    (!alreadyPushedItem || !alreadyPushedItem.isLocked) &&
    dataLayerProducts
  ) {
    dataLayerProducts[key] = {
      item_list_name: actionField,
      category:
        prop(product, 'parent_categories.0.category_name') ||
        prop(product, 'categories_names.0') ||
        '',
      category2:
        prop(product, 'parent_categories.1.category_name') ||
        prop(product, 'categories_names.1') ||
        '',
      category3:
        prop(product, 'parent_categories.2.category_name') ||
        prop(product, 'categories_names.2') ||
        '',
      category4:
        prop(product, 'parent_categories.3.category_name') ||
        prop(product, 'categories_names.3') ||
        '',
      category5:
        prop(product, 'parent_categories.4.category_name') ||
        prop(product, 'categories_names.4') ||
        '',
      isLocked: false,
    };
    setNewDatalayerProducts(dataLayerProducts);
  }
};
