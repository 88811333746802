import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import APIClass, { ThenArg } from '../../services/API';
import { startFetching, stopFetching } from '../../utilities/redux';
import { AppThunk } from '../../rootReducer';

interface ContactState {
  persons: {
    data: ThenArg<typeof APIClass.loadArticles> | null;
    isFetching: boolean;
  };
  info: {
    basicData: ThenArg<typeof APIClass.loadSitemap> | null;
    secondData: ThenArg<typeof APIClass.loadSitemap> | null;
    thirdData: ThenArg<typeof APIClass.loadSitemap> | null;
    isFetching: boolean;
  };
}

const initialState: ContactState = {
  persons: {
    data: null,
    isFetching: false,
  },
  info: {
    basicData: null,
    secondData: null,
    thirdData: null,
    isFetching: false,
  },
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    startFetchingPersons(state) {
      startFetching(state, 'persons');
    },
    setPersons(
      state,
      action: PayloadAction<{
        persons: ThenArg<typeof APIClass.loadArticles>;
      }>,
    ) {
      stopFetching(state, 'persons');
      state.persons.data = action.payload.persons;
    },
    stopFetchingPersons(state) {
      stopFetching(state, 'persons');
    },

    startFetchingInfoData(state) {
      startFetching(state, 'info');
    },
    setInfoData(
      state,
      action: PayloadAction<{
        basicData: ThenArg<typeof APIClass.loadSitemap>;
        secondData: ThenArg<typeof APIClass.loadSitemap>;
        thirdData: ThenArg<typeof APIClass.loadSitemap>;
      }>,
    ) {
      stopFetching(state, 'info');
      state.info.basicData = action.payload.basicData;
      state.info.secondData = action.payload.secondData;
      state.info.thirdData = action.payload.thirdData;
    },
    stopFetchingInfoData(state) {
      stopFetching(state, 'info');
    },
  },
});

export const {
  startFetchingPersons,
  setPersons,
  stopFetchingPersons,
  startFetchingInfoData,
  setInfoData,
  stopFetchingInfoData,
} = contactSlice.actions;

export default contactSlice.reducer;

const contactDomainSelector = state => state.contact;

export const contactPersonsSelector = createSelector(
  contactDomainSelector,
  substate => prop(substate, 'persons.data', null),
);

export const contactPersonsIsFetchingSelector = createSelector(
  contactDomainSelector,
  substate => prop(substate, 'persons.isFetching', null),
);

export const basicContactInfoSelector = createSelector(
  contactDomainSelector,
  substate => prop(substate, 'info.basicData', null),
);

export const secondContactInfoSelector = createSelector(
  contactDomainSelector,
  substate => prop(substate, 'info.secondData', null),
);

export const thirdContactInfoSelector = createSelector(
  contactDomainSelector,
  substate => prop(substate, 'info.thirdData', null),
);

export const contactInfoIsFetchingSelector = createSelector(
  contactDomainSelector,
  substate => prop(substate, 'info.isFetching', null),
);

export const fetchContactPersons = (): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  if (!contactPersonsSelector(state)) {
    try {
      dispatch(startFetchingPersons());
      const persons = await API.loadArticles(
        { sitemapId: 114, limit: 20, sort: 'sorting', sortDir: 'asc' },
        { xAcceptLanguage: 'sk' },
      );
      dispatch(setPersons({ persons }));
      dispatch(stopFetchingPersons());
    } catch (err) {
      dispatch(stopFetchingPersons());
    }
  }
};

export const fetchContactInfo = (): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  if (!contactPersonsSelector(state)) {
    try {
      dispatch(startFetchingInfoData());
      const basicData = await API.loadSitemap('112', {
        xAcceptLanguage: 'sk',
      });
      const secondData = await API.loadSitemap('120', {
        xAcceptLanguage: 'sk',
      });
      const thirdData = await API.loadSitemap('122', {
        xAcceptLanguage: 'sk',
      });
      dispatch(setInfoData({ basicData, secondData, thirdData }));
      dispatch(stopFetchingInfoData());
    } catch (err) {
      dispatch(stopFetchingInfoData());
    }
  }
};
