import * as React from 'react';
import { __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags, CartItemsPart, EmptyCart, prop } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  addItemToCart,
  removeItemFromCart,
  changeItemNote,
  changeCartNote,
  fetchCart,
  updateCart,
  setCartZone,
} from './cartSlice';
import { ga4CartEvent } from '@bart.sk-ecommerce/react-online-marketing';
import { AFFILIATION_VALUE } from '../App/constants';
import TrackCart from '../../components/Analytics/TrackCart';
import {
  resolveDatalayerProducts,
  setNewDatalayerProducts,
} from '../../utilities/localStorage';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  zoneId: number;
  hasPartlyOnStockItem: number;
  cartB2BDiscount: number;
  freeDelivery: boolean;
  dispatchCartContactInfo: any;
  cartContactInfo: any;
  hasExternalStock: boolean[];
  handleEmptyCartClick: any;
}

class CartFirstStep extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, data } = props;
    try {
      if (data && data.id) {
        const freeDelivery = await API.getFreeDeliveryInfo(data.id);
        return freeDelivery;
      }
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  // public componentDidMount() {
  //   const data = this.props.data;

  //   ga4CartEvent(AFFILIATION_VALUE, 'begin_checkout', data, null, [], {});
  // }

  public render() {
    const {
      data,
      isFetching,
      user,
      zoneId,
      hasPartlyOnStockItem,
      cartB2BDiscount,
      freeDelivery,
      dispatchCartContactInfo,
      cartContactInfo,
      hasExternalStock,
      handleEmptyCartClick,
    } = this.props;

    if (!data) {
      return null;
    }

    const { items } = data;
    return (
      <>
        <MetaTags
          tags={{
            title: __r('Košík - Nákupný košík', 'Košík - Nákupný košík'),
          }}
        />
        <TrackCart cart={data} eventName="view_cart" />
        {items.length === 0 ? (
          <EmptyCart />
        ) : (
          <CartItemsPart
            data={data}
            isFetching={isFetching}
            addToRequest={this.addToRequest}
            addToCart={this.addToCart}
            removeFromCart={this.removeFromCart}
            changeCartItemNote={this.changeCartItemNote}
            changeCartNote={this.changeCartNote}
            refreshCart={this.refreshCart}
            user={user}
            handleZoneInputChange={this.handleZoneInputChange}
            zoneId={zoneId}
            hasPartlyOnStockItem={hasPartlyOnStockItem}
            updateCart={this.updateCart}
            cartB2BDiscount={cartB2BDiscount}
            freeDelivery={freeDelivery}
            dispatchCartContactInfo={dispatchCartContactInfo}
            cartContactInfo={cartContactInfo}
            hasExternalStock={hasExternalStock}
            handleEmptyCartClick={handleEmptyCartClick}
          />
        )}
      </>
    );
  }

  private updateCart = data => {
    this.props.dispatch(updateCart(data));
  };

  private refreshCart = () => {
    this.props.dispatch(fetchCart(true));
  };

  private addToRequest = (product: any, count: number) => {
    const dataLayerProducts = resolveDatalayerProducts();
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    this.props.dispatch(
      addItemToCart(cartProduct, count, true, false, dataLayerProducts),
    );
  };

  private addToCart = (product: any, count: number) => {
    const dataLayerProducts = resolveDatalayerProducts();
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    this.props.dispatch(
      addItemToCart(cartProduct, count, false, false, dataLayerProducts),
    );
  };

  private removeFromCart = (
    pId: number,
    gId: number,
    isRequest?: boolean,
    product?: any,
  ) => {
    const dataLayerProducts: any = resolveDatalayerProducts();
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    this.props.dispatch(
      removeItemFromCart(gId, pId, isRequest, cartProduct, dataLayerProducts),
    );

    const key = `${pId}-${gId}`;
    const dataLayerProduct = dataLayerProducts && dataLayerProducts[key];
    if (dataLayerProduct) {
      delete dataLayerProducts[key];
    }
    setNewDatalayerProducts(dataLayerProducts);
  };

  private changeCartItemNote = (goodId: number, note: string) => {
    this.props.dispatch(changeItemNote(goodId, note));
  };

  private changeCartNote = (note: string) => {
    this.props.dispatch(changeCartNote(note));
  };

  private handleZoneInputChange = async (e: any) => {
    const value = e.target.value;
    if (value && value.length === 5) {
      const zone = await API.findZoneByZipCode({ zip: value });
      if (zone && zone.zoneId) {
        this.props.dispatch(setCartZone(zone.zoneId));
      } else {
        this.props.dispatch(setCartZone(null));
      }
    } else {
      this.props.dispatch(setCartZone(null));
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartFirstStep' })(CartFirstStep),
);
