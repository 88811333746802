import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical, IconWithText } from 'eshop-defaults';

const Wrapper = styled(FlexRowCenterVertical)`
  margin: 0 auto;
  width: 100%;
  max-width: ${rem(1408)};
  padding: ${rem(12)} ${rem(41)};
  background-color: ${({ theme }) => theme.colors.secondary};
  justify-content: space-between;
  border-radius: ${rem(4)} ${rem(4)} 0 0;
  margin-top: auto;

  ${({ theme }) => theme.mediab.l1050`
   display: none;
  `}
`;

function InfoBanner() {
  const secondInfoTexts = [
    {
      icon: '/images/zemplin/package.svg',
      textMain: 'Široký sortiment tovaru',
    },
    {
      icon: '/images/zemplin/help.svg',
      textMain: 'Variabilita spolupráce',
    },
    {
      icon: '/images/zemplin/service.svg',
      textMain: 'Servis a poradenstvo',
    },
    {
      icon: '/images/zemplin/settings.svg',
      textMain: 'Výroba na zakázku',
    },
  ];

  return (
    <Wrapper>
      {' '}
      {secondInfoTexts.map(info => (
        <IconWithText
          url={'/benefity'}
          key={info.textMain}
          textMain={info.textMain}
          iconSrc={info.icon}
          alt={`ikonka ku ${info.textMain}`}
          smaller={true}
        />
      ))}
    </Wrapper>
  );
}

export default InfoBanner;
