import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinTitle as Title,
  ZemplinSelect as Select,
  PaginationSimple,
  Requests,
  ZemplinCheckbox as CheckBox,
  FlexRowCenterVertical,
  TextP,
  Loader as DefaultLoader,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';

interface Props {
  requests: ThenArg<typeof API.getOrders>;
  requestsData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetching: boolean;
  downloadFile: any;
  isDownloading: boolean;
  user: any;
}

function MyRequests({
  currentId,
  requests,
  requestsData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetching,
  downloadFile,
  isDownloading,
  user,
}: Props) {
  const { limit, offset, total } = requestsData;

  const handleCheckboxChange = e => {
    console.log({ v: e.target.value });
  };

  return (
    <Wrapper>
      <Title marginTop={0} marginBottom={32}>
        {__('Moje dopyty')}
      </Title>
      {options && options.length > 1 && (
        <FilterWrapper>
          <Select
            value={currentId}
            options={options}
            onChange={handleSelectChange}
          />
          {/* TODO uncomment and connect to API */}
          <CheckBox
            label="Spracovaný"
            // selected={true}
            onChange={handleCheckboxChange}
          />
          <CheckBox
            label="Spracováva sa"
            // selected={false}
            onChange={handleCheckboxChange}
          />
          <CheckBox
            label="Čaká na spracovanie"
            // selected={true}
            onChange={handleCheckboxChange}
          />
        </FilterWrapper>
      )}
      <Requests
        isFetching={isFetching}
        requests={requests}
        downloadFile={downloadFile}
        showCSV={true}
      />
      <PaginationSimple
        totalItems={total}
        limit={limit}
        offset={offset}
        handleOffsetChange={handleOffsetChange}
      />
      {isDownloading && (
        <LoaderWrapper>
          <DefaultLoader
            style={{
              position: isDownloading ? 'absolute' : 'initial',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 6,
            }}
            loading={isDownloading}
            dim={true}
          >
            <TextP
              style={{
                position: 'fixed',
                top: '60%',
                width: '100%',
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: 700,
              }}
            >
              {__('Pracujeme na príprave Vašej ponuky')}
            </TextP>
          </DefaultLoader>
        </LoaderWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const FilterWrapper = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    align-items: flex-start;
  `}
`;

const LoaderWrapper = styled(FlexCol)``;

export default MyRequests;
