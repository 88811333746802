import {
  RECEIVE_HOME_BANNER_SUCCESS,
  REQUEST_HOME_NEW_PRODUCTS,
  RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  REQUEST_HOME_SALE_PRODUCTS,
  RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  RECEIVE_HOME_BENEFITS_SUCCESS,
  RECEIVE_HOME_BANNERS_ERROR,
  RECEIVE_HOME_BANNERS_SUCCESS,
} from './constants';

const INITIAL_STATE: any = {
  newProducts: {
    isFetching: false,
    productsData: null,
  },
  saleProducts: {
    isFetching: false,
    productsData: null,
  },
  adBanner: null,
  firstBenefits: null,
  secondBenefits: null,
};

export const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_HOME_NEW_PRODUCTS: {
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_NEW_PRODUCTS_SUCCESS: {
      const productsData = {
        ...state.newProducts.productsData,
        [action.payload.categoryId]: { ...action.payload.products },
      };
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: false,
          productsData,
        },
      };
    }

    case RECEIVE_HOME_NEW_PRODUCTS_ERROR: {
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case RECEIVE_HOME_BANNERS_SUCCESS: {
      return {
        ...state,
        banners: {
          isFetching: false,
          banners: action.payload.banners,
        },
      };
    }

    case RECEIVE_HOME_BANNERS_ERROR: {
      return {
        ...state,
        newProducts: {
          ...state.newProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_SALE_PRODUCTS: {
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_SALE_PRODUCTS_SUCCESS: {
      const productsData = {
        ...state.saleProducts.productsData,
        [action.payload.categoryId]: { ...action.payload.products },
      };
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: false,
          productsData,
        },
      };
    }

    case RECEIVE_HOME_SALE_PRODUCTS_ERROR: {
      return {
        ...state,
        saleProducts: {
          ...state.saleProducts,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case RECEIVE_HOME_BANNER_SUCCESS: {
      return {
        ...state,
        adBanner: action.payload.banner,
      };
    }

    case RECEIVE_HOME_BENEFITS_SUCCESS: {
      return {
        ...state,
        firstBenefits:
          action.payload.benefits && action.payload.benefits.slice(0, 4)
            ? action.payload.benefits.slice(0, 4)
            : [],
        secondBenefits:
          action.payload.benefits && action.payload.benefits.slice(4, 8)
            ? action.payload.benefits.slice(4, 8)
            : [],
      };
    }

    default: {
      return state;
    }
  }
};
