import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import fbVerticalLogo from '../../public/images/zemplin/Facebook_Logo_(2019).svg';
import { useState } from 'react';

interface Props {
  isVt: boolean;
}

function FacebookPagePlugin({ isVt }: Props) {
  return (
    <>
      <Wrapper isVt={isVt}>
        <div
          className="fb-page"
          data-href="https://www.facebook.com/krbove.studio.zemplin"
          data-tabs="timeline"
          data-width=""
          data-height=""
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/krbove.studio.zemplin"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/krbove.studio.zemplin">
              ZEMPLÍN - Krbové štúdio - Pletivá, oplotenia
            </a>
          </blockquote>
        </div>
        <LogoWrap isVt={isVt}>
          <Logo />
        </LogoWrap>
      </Wrapper>
      <WrapperSM isVt={isVt}>
        <div
          className="fb-page"
          data-href="https://www.facebook.com/ZEMPL%C3%8DN-Ve%C4%BEkoobchod-so-spojovac%C3%ADm-materi%C3%A1lom-110469614751538"
          data-tabs="timeline"
          data-width=""
          data-height=""
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/ZEMPL%C3%8DN-Ve%C4%BEkoobchod-so-spojovac%C3%ADm-materi%C3%A1lom-110469614751538"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/ZEMPL%C3%8DN-Ve%C4%BEkoobchod-so-spojovac%C3%ADm-materi%C3%A1lom-110469614751538">
              ZEMPLÍN - Veľkoobchod so spojovacím materiálom
            </a>
          </blockquote>
        </div>
        <LogoWrap isVt={isVt}>
          <Logo />
        </LogoWrap>
      </WrapperSM>
    </>
  );
}

const Wrapper = styled.div<{ isVt: boolean }>`
  display: flex;
  flex-flow: row;
  position: absolute !important;
  top: 270px;
  left: 0px;
  width: 386px;
  /* min-height: 130px; */
  height: ${rem(64)};
  z-index: 100;
  margin-left: -340px;
  transition: 0.3s;

  ${({ isVt }) => (isVt ? '' : 'display: none')};
  &:hover {
    margin-left: 0px;
  }

  @media only screen and (max-width: 1050px) {
    top: 264px;
  }

  @media only screen and (max-width: 925px) {
    top: 120px;
  }

  @media only screen and (max-width: 720px) {
    top: 120px;
  }

  @media only screen and (max-width: 580px) {
    display: none;
  }
`;

const WrapperSM = styled.div<{ isVt: boolean }>`
  display: flex;
  flex-flow: row;
  position: absolute !important;
  top: 270px;
  left: 0px;
  width: 386px;
  /* min-height: 130px; */
  height: ${rem(64)};
  z-index: 100;
  margin-left: -340px;
  transition: 0.3s;

  ${({ isVt }) => (isVt ? 'display: none' : '')};
  &:hover {
    margin-left: 0px;
    height: auto;
  }

  @media only screen and (max-width: 1050px) {
    top: 264px;
  }

  @media only screen and (max-width: 925px) {
    top: 120px;
  }

  @media only screen and (max-width: 720px) {
    top: 120px;
  }

  @media only screen and (max-width: 580px) {
    display: none;
  }
`;

const LogoWrap = styled.div<{ isVt: boolean }>`
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 42px;
  width: 42px;
  ${({ isVt }) => (isVt ? 'background: #FF711C' : 'background: #312783')};

  background-position: center;
  background-size: cover;
`;

const Logo = styled.div`
  margin: auto;
  margin-top: 8px;
  width: 26px;
  height: 26px;
  background: url('/images/zemplin/facebook-original-logo.svg') no-repeat;
  filter: brightness(0) invert(1);
  background-position: center;
  background-size: cover;
  vertical-align: middle;
`;

export default FacebookPagePlugin;
