import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexBetween,
  FlexCol,
  MobileNavigationItem as MobileNavItem,
  ItemType,
  TextP,
  MobileNavSubItem,
  SvgIcon,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getImagePath } from '../../utilities/product';
import { Link } from 'react-router';
import { __ } from 'react-i18n';
import { IconType } from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import { theme } from '../../theme/theme';

export interface Props {
  items: ThenArg<typeof API.loadTree>;
  closeMenu: () => void;
  user: any;
  cartIsImporting: boolean;
}

function MobileNavigation({ items, closeMenu, user, cartIsImporting }: Props) {
  const [level, setLevel] = useState<number>(1);
  const [title, setTitle] = useState<string>('Menu');
  const [currentItems, setCurrentItems] = useState<any>(items);
  const [previous, setPrevious] = useState<
    Array<{ items: any; title: string }>
  >([]);

  const upLevel = (id: number = -1) => {
    const currentLvl = level;

    if (currentLvl === 1) {
      setPrevious([
        {
          items,
          title: 'Menu',
        },
      ]);
      setLevel(level + 1);
      setTitle('Produkty');
    }

    if (currentLvl > 1 && id >= 0) {
      const item = currentItems[id];
      if (item) {
        const newItems = currentItems[id].children;
        const newTitle = currentItems[id].category_name;
        const newPrevious = previous;
        newPrevious.push({
          items: currentItems,
          title,
        });
        setPrevious(newPrevious);
        setLevel(level + 1);
        setTitle(newTitle);
        setCurrentItems(newItems);
      }
    }
  };

  const downLevel = () => {
    const currentLvl = level;

    if (currentLvl > 1) {
      setLevel(level - 1);
      setTitle(previous[previous.length - 1].title);
      setCurrentItems(previous[previous.length - 1].items);

      const newPrevious = previous;
      newPrevious.splice(-1, 1);
      setPrevious(newPrevious);
    }
  };

  return (
    <Wrapper>
      <IconTitleWrapper>
        <SvgIcon
          icon={level === 1 ? IconType.close : IconType.back}
          alt="Zavrieť menu"
          viewBox={level === 1 ? '0 0 24 24' : '0 0 16 16'}
          width={level === 1 ? 24 : 16}
          height={level === 1 ? 24 : 16}
          cursor={'pointer'}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={level === 1 ? closeMenu : downLevel}
        />
        <Title>{title}</Title>
      </IconTitleWrapper>
      {renderContentItems(level, upLevel, closeMenu, currentItems)}
      {level === 1 && (
        <>
          {user ? (
            <ImportOrderButton onClick={closeMenu} to={'/import'}>
              {cartIsImporting ? (
                <>
                  <ZemplinLoaderWrapper
                    height={100}
                    loaderHeight={24}
                    strokeWidth={3}
                    center={true}
                    secondaryColor={theme.colors.primary}
                    primaryColor={'transparent'}
                  />
                  <ImportingOrderTitle>
                    {__('Práve prebieha import')}
                  </ImportingOrderTitle>
                </>
              ) : (
                <>
                  <SvgIcon
                    icon={IconType.upload}
                    alt={__('Import objednávky')}
                    width={24}
                    height={24}
                    marginRight={16}
                    cursor="pointer"
                  />

                  <ImportOrderTitle>
                    {__('Import objednávky / dopytu')}
                  </ImportOrderTitle>
                </>
              )}
            </ImportOrderButton>
          ) : null}
        </>
      )}
    </Wrapper>
  );
}

const renderContentItems = (level, upLevel, closeMenu, currentItems) => {
  const rightSrc = '/images/zemplin/list-arrow_black.svg';
  const placeholderImgSrc = '/images/zemplin/placeholder.svg';
  if (level === 1) {
    return (
      <MenuItemsWrapper>
        <MobileNavItem
          onClick={upLevel}
          type={ItemType.PRIMARY}
          title={
            <FlexBetween>
              <span>Produkty</span>
              <SvgIcon
                icon={IconType.rightArrow}
                width={16}
                height={16}
                viewBox={'0 0 12 12'}
                alt={'Zobraziť podkategórie'}
                cursor="pointer"
              />
            </FlexBetween>
          }
        />
        <MobileNavItem
          onClick={closeMenu}
          url="/novinky"
          type={ItemType.SECONDARY}
          title={<span>Novinky</span>}
        />
        <MobileNavItem
          onClick={closeMenu}
          url="/akcie"
          type={ItemType.SECONDARY}
          title={<span>Akcie</span>}
        />
        <MobileNavItem
          onClick={closeMenu}
          url="/vypredaj"
          type={ItemType.SECONDARY}
          title={<span>Výpredaj</span>}
        />
        <MobileNavItem
          onClick={closeMenu}
          url="/aktuality"
          type={ItemType.TERTIARY}
          title={<span>Aktuality</span>}
        />
        <MobileNavItem
          onClick={closeMenu}
          url="/kontakt"
          type={ItemType.TERTIARY}
          title={<span>Kontakt</span>}
        />
        <MobileNavItem
          onClick={closeMenu}
          url="/tipy-a-rady"
          type={ItemType.TERTIARY}
          title={<span>Tipy a rady</span>}
        />
        <MobileNavItem
          onClick={closeMenu}
          url="/montaz-krbov-fotogaleria"
          type={ItemType.TERTIARY}
          title={<span>Montáž krbov - fotogaléria</span>}
        />
      </MenuItemsWrapper>
    );
  }
  if (level > 1) {
    return (
      currentItems &&
      currentItems.map((category, i) => {
        return category?.counts > 0 ? (
          <MobileNavSubItem
            key={category.category_id}
            imgSrc={
              category.image
                ? getImagePath(
                    category.image,
                    {
                      width: 24,
                      height: 24,
                    },
                    false,
                    false,
                    true,
                  )
                : placeholderImgSrc
            }
            showArrow={category.children.length > 0}
            arrowSrc={rightSrc}
            name={category.category_name}
            url={category.url}
            id={category.category_id}
            onClickLink={closeMenu}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => upLevel(i)}
          />
        ) : null;
      })
    );
  }

  return null;
};

const Wrapper = styled.div`
  width: 100%;
`;

const IconTitleWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${rem(24)};
  padding: 0 ${rem(16)};
`;

const MenuItemsWrapper = styled(FlexCol)``;

const Title = styled(TextP)`
  font-weight: 400;
  font-size: 14px;
`;

const ImportOrderTitle = styled.span`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
`;

const ImportingOrderTitle = styled.span`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  margin-right: ${rem(24)};
`;

const ImportOrderButton = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.hover};
  font-size: ${rem(14)};
  padding: ${rem(16)};
  line-height: ${rem(22)};

  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(56)};
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

export { MobileNavigation };
