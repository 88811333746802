import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import {
  MetaTags,
  B2BRegisterForm,
  B2BRegisterFormFields,
} from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { registerB2B } from '../App/actions';
import {
  registrationB2bIsFetchingSelector,
  registrationB2bSuccessMessageSelector,
  registrationB2bErrorMessageSelector,
} from '../App/selectors';
import { onlyPublic } from '../../utilities/auth';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
  isFetching: boolean;
  successMessage: string;
  errorMessage: string;
}

interface State {
  error: string;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(752)};
  margin: ${rem(32)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: 0 ${rem(16)};
     margin: ${rem(32)} auto;
  `}
`;

class B2BRegisterPage extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  public componentDidMount() {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.REGISTRATION, null));
  }

  public render() {
    const { isFetching, successMessage, errorMessage } = this.props;

    return (
      <>
        <Wrapper>
          <MetaTags tags={{ title: 'Registrácia B2B' }} />
          <B2BRegisterForm
            handleFormSubmit={this.handleFormSubmit}
            isFetching={isFetching}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
        </Wrapper>
        <InfoBanner />
      </>
    );
  }

  public handleFormSubmit = (
    fields: B2BRegisterFormFields,
    isCompanyAgreed: boolean,
    isGdprAccepted: boolean,
    isNewsletterAccepted: boolean,
  ) => {
    this.props.dispatch(
      registerB2B(
        fields,
        isCompanyAgreed,
        isGdprAccepted,
        isNewsletterAccepted,
      ),
    );
  };

  public handleFacebookLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/facebook?state="${this.props.cartId}"`;
  };

  public handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/google?state="${this.props.cartId}"`;
  };
}

const mapStateToProps = state => {
  return {
    cartId: state.general.lang,
    isFetching: registrationB2bIsFetchingSelector(state),
    successMessage: registrationB2bSuccessMessageSelector(state),
    errorMessage: registrationB2bErrorMessageSelector(state),
  };
};

const withRouterLoginPage = onlyPublic(B2BRegisterPage);
export default connect(mapStateToProps)(withRouter(withRouterLoginPage));
