import React, { ReactNode, useRef } from 'react';
import { ga4EventsItem } from '@bart.sk-ecommerce/react-online-marketing/lib/index';
import { useLocalStorage } from '../../utilities/useLocalStorage';
import {
  AFFILIATION_VALUE,
  DATALAYER_PRODUCTS_COOKIE,
} from '../../containers/App/constants';
import { prop } from 'eshop-defaults';
import { InView, useInView } from 'react-intersection-observer';

type Props = {
  products: any[];
  itemListId?: string;
  itemListName?: string;
  page?: number;
  listId?: number;
  children?: ReactNode;
};

const TrackViewItemList: React.FC<Props> = ({
  products,
  itemListId,
  itemListName,
  page = 1,
  listId,
  children,
}: Props) => {
  const [analyticsSent, setAnalyticsSent] = React.useState(false);
  const [dataLayerProducts, setDataLayerProducts] = useLocalStorage(
    DATALAYER_PRODUCTS_COOKIE,
    {},
  );

  const firstProductId = prop(products, '0.product_id');
  React.useEffect(() => {
    setAnalyticsSent(false);
  }, [firstProductId, listId]);

  const { ref } = useInView({
    threshold: 0,
    // rootMargin: '-200px 0px -200px',
    onChange: inView => {
      if (products && products.length && !analyticsSent && inView) {
        setAnalyticsSent(true);
        ga4EventsItem(
          AFFILIATION_VALUE,
          'view_item_list',
          products,
          dataLayerProducts,
          {
            item_list_id:
              page > 1 && itemListId ? `${itemListId} ${page}` : itemListId,
            item_list_name: page > 1 ? `${itemListName} ${page}` : itemListName,
          },
        );
      }
    },
  });

  // return (
  //   <InView
  //     as="div"
  //     onChange={inView => {
  //       console.log({ inView, itemListName, products });
  //       if (products && products.length && !analyticsSent && inView) {
  //         setAnalyticsSent(true);
  //         ga4EventsItem(
  //           AFFILIATION_VALUE,
  //           'view_item_list',
  //           products,
  //           dataLayerProducts,
  //           {
  //             item_list_id:
  //               page > 1 && itemListId ? `${itemListId} ${page}` : itemListId,
  //             item_list_name:
  //               page > 1 ? `${itemListName} ${page}` : itemListName,
  //           },
  //         );
  //       }
  //     }}
  //   >
  //     {children}
  //   </InView>
  // );

  return <div ref={ref} />;
};

export default TrackViewItemList;
