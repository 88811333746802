import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  MetaTags,
  ZemplinContactMapWrapper as ContactMapWrapper,
  ZemplinContactUpper as ContactUpper,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';
import APIClass, { ThenArg } from '../../services/API';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import {
  StyledZemplinTitle,
  SecondaryZemplinTitle,
} from './ContactPersonsPage';
import { rem } from 'polished';
import styled from 'styled-components';
import {
  fetchContactInfo,
  thirdContactInfoSelector,
  secondContactInfoSelector,
  basicContactInfoSelector,
  contactInfoIsFetchingSelector,
} from './contactSlice';
import {
  getContactInfoBasicData,
  getContactInfoOtherData,
} from '../../utilities';

interface Props {
  user: any;
  basicData: ThenArg<typeof APIClass.loadSitemap>;
  secondData: ThenArg<typeof APIClass.loadSitemap>;
  thirdData: ThenArg<typeof APIClass.loadSitemap>;
  isFetching: boolean;
}

class ContactInfoPageContainer extends React.Component<Props> {
  public static async getInitialProps({ dispatch }) {
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.CONTACT));
      await dispatch(fetchContactInfo());
      return true;
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { basicData, secondData, thirdData, isFetching } = this.props;

    if (isFetching) {
      return <ZemplinLoaderWrapper height={300} />;
    }

    const {
      address,
      phone,
      fax,
      email,
      ico,
      icdph,
      orsr,
    } = getContactInfoBasicData(basicData);
    const {
      address: secondAddress,
      phone: secondPhone,
      fax: secondFax,
      email: secondEmail,
    } = getContactInfoOtherData(secondData);
    const {
      address: thirdAddress,
      phone: thirdPhone,
      fax: thirdFax,
      email: thirdEmail,
    } = getContactInfoOtherData(thirdData);

    return (
      <>
        <MetaTags tags={{ title: 'Kontakt' }} />
        <StyledZemplinTitle>{__('ZEMPLÍN, s.r.o. Humenné')}</StyledZemplinTitle>
        <ContactUpper
          address={address}
          phone={phone}
          fax={fax}
          email={email}
          ico={ico}
          icdph={icdph}
          orsr={orsr}
        />

        <StyledSecondaryZemplinTitle>
          {__('Oddelenie spojovacieho materiálu')}
        </StyledSecondaryZemplinTitle>
        <ContactMapWrapper
          address={secondAddress}
          mapUrl={
            'https://www.google.com/maps?q=ZEMPL%C3%8DN+s.r.o.+Humenn%C3%A9'
          }
          phone={secondPhone}
          fax={secondFax}
          email={secondEmail}
        >
          <IframeWrapper>
            <iframe
              allowFullScreen={false}
              frameBorder="0"
              src="https://www.google.com/maps/embed?pb=!4v1583508927159!6m8!1m7!1sCAoSLEFGMVFpcE02VlptVUstWXpqRGdaUFdxWlV6NWMwMXVhZlpYWHBraklPNlhH!2m2!1d48.92361753360946!2d21.90843195712569!3f343.11925246634894!4f-4.981347872118221!5f0.520999924263284"
              style={{
                border: 0,
                width: '100%',
                height: '100%',
              }}
              title="Virtuálna prehliadka 2"
            ></iframe>
          </IframeWrapper>
        </ContactMapWrapper>

        <StyledSecondaryZemplinTitle>
          {__('Oddelenie vykurovacej techniky')}
        </StyledSecondaryZemplinTitle>
        <ContactMapWrapper
          address={thirdAddress}
          mapUrl={
            'https://www.google.com/maps?q=ZEMPLÍN+s.r.o.+–+centrum+vykurovania'
          }
          phone={thirdPhone}
          fax={thirdFax}
          email={thirdEmail}
        >
          <IframeWrapper>
            <iframe
              allowFullScreen={false}
              frameBorder="0"
              src="https://www.google.com/maps/embed?pb=!4v1583510407027!6m8!1m7!1sCAoSLEFGMVFpcE9ETi1sTlVoZnFZUkcwM0RtOV81MFBkbk56ZTBHOWhkYlRsTFBT!2m2!1d48.92300396337818!2d21.9079177373477!3f309.8334655761719!4f0!5f0.7820865974627469"
              style={{
                border: 0,
                width: '100%',
                height: '100%',
              }}
              title="Virtuálna prehliadka 1"
            ></iframe>
          </IframeWrapper>
        </ContactMapWrapper>
      </>
    );
  }
}

const StyledSecondaryZemplinTitle = styled(SecondaryZemplinTitle)`
  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(56)} 0 ${rem(32)};
    font-size: ${rem(20)};
    line-height:${rem(28)};
  `}
`;

const IframeWrapper = styled.div`
  height: ${rem(296)};
  width: 100%;
  max-width: ${rem(688)};
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
    height: ${rem(124)};
  `}
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    basicData: basicContactInfoSelector(state),
    secondData: secondContactInfoSelector(state),
    thirdData: thirdContactInfoSelector(state),
    isFetching: contactInfoIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ContactInfoPageContainer' })(
    ContactInfoPageContainer,
  ),
);
