import {
  REQUEST_HOME_RECOMMENDED,
  RECEIVE_HOME_RECOMMENDED_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_ERROR,
  REQUEST_HOME_FAST_NEWS,
  RECEIVE_HOME_FAST_NEWS_SUCCESS,
  RECEIVE_HOME_FAST_NEWS_ERROR,
  REQUEST_HOME_TOP_ARTICLE,
  RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  RECEIVE_HOME_TOP_ARTICLE_ERROR,
  REQUEST_HOME_EBOOKS,
  RECEIVE_HOME_EBOOKS_SUCCESS,
  RECEIVE_HOME_EBOOKS_ERROR,
  SET_HOME_RECOMMENDED_FILTER,
  RECEIVE_HOME_BANNER_SUCCESS,
  SET_HOME_ARTICLES_FILTERS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  REQUEST_HOME_NEW_PRODUCTS,
  RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  REQUEST_HOME_SALE_PRODUCTS,
  RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  REQUEST_HOME_BENEFITS,
  RECEIVE_HOME_BENEFITS_SUCCESS,
  RECEIVE_HOME_BENEFITS_ERROR,
  REQUEST_HOME_BANNERS,
  RECEIVE_HOME_BANNERS_ERROR,
  RECEIVE_HOME_BANNERS_SUCCESS,
} from './constants';
import { prop } from '../../utilities';
import { langSelector } from '../App/selectors';
import {
  newProductsSelector,
  saleProductsSelector,
  firstHomeBenefitsSelector,
  homeBannersSelector,
} from './selectors';
import { AppThunk } from '../../rootReducer';

const requestHomeNewProducts = () => ({
  type: REQUEST_HOME_NEW_PRODUCTS,
});

const receiveHomeNewProductsSuccess = (products, categoryId: string) => ({
  type: RECEIVE_HOME_NEW_PRODUCTS_SUCCESS,
  payload: {
    products,
    categoryId,
  },
});

const receiveHomeNewProductsError = error => ({
  type: RECEIVE_HOME_NEW_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeNewProducts = (categoryId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const news = newProductsSelector(state);

      if (!news || !news.productsData || !news.productsData[categoryId]) {
        const lang = langSelector(state);

        dispatch(requestHomeNewProducts());
        const products = await API.searchProducts(
          {
            limit: 3,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            isNew: '1',
            categoryId,
            withProductPackages: '1',
            goodsWithStores: '1',
            withFreeDelivery: '1',
            withParentCategories: '1',
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeNewProductsSuccess(products, categoryId));
      }
    } catch (e) {
      console.log({ error: e });
      dispatch(receiveHomeNewProductsError(e));
    }
  };
};

const requestHomeBanners = () => ({
  type: REQUEST_HOME_BANNERS,
});

const receiveHomeBannersSuccess = banners => ({
  type: RECEIVE_HOME_BANNERS_SUCCESS,
  payload: {
    banners,
  },
});

const receiveHomeBannersError = error => ({
  type: RECEIVE_HOME_BANNERS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeBanners = () => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const news = homeBannersSelector(state);

      if (!news || !news.banners || !news.banners.length) {
        dispatch(requestHomeBanners());
        const { banners } = await API.loadBanners();
        dispatch(receiveHomeBannersSuccess(banners));
      }
    } catch (e) {
      console.log({ error: e });
      dispatch(receiveHomeBannersError(e));
    }
  };
};

const requestHomeSaleProducts = () => ({
  type: REQUEST_HOME_SALE_PRODUCTS,
});

const receiveHomeSaleProductsSuccess = (products, categoryId) => ({
  type: RECEIVE_HOME_SALE_PRODUCTS_SUCCESS,
  payload: {
    products,
    categoryId,
  },
});

const receiveHomeSaleProductsError = error => ({
  type: RECEIVE_HOME_SALE_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeSaleProducts = (categoryId: string): AppThunk => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const saleProducts = saleProductsSelector(state);

      if (
        !saleProducts ||
        !saleProducts.productsData ||
        !saleProducts.productsData[categoryId]
      ) {
        const lang = langSelector(state);

        dispatch(requestHomeSaleProducts());
        const products = await API.searchProducts(
          {
            limit: 3,
            withAttribs: '0',
            withGifts: '0',
            withBrand: '0',
            isSaleout: 1,
            categoryId,
            withProductPackages: '1',
            goodsWithStores: '1',
            withFreeDelivery: '1',
            withParentCategories: '1',
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeSaleProductsSuccess(products, categoryId));
      }
    } catch (e) {
      dispatch(receiveHomeSaleProductsError(e));
    }
  };
};

export const setHomeArticlesFilters = filters => ({
  type: SET_HOME_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});

const requestHomeRecommended = () => ({
  type: REQUEST_HOME_RECOMMENDED,
});

const receiveHomeRecommendedSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_ERROR,
  payload: {
    error,
  },
});

const setHomeRecommendedFilter = filter => ({
  type: SET_HOME_RECOMMENDED_FILTER,
  payload: {
    filter,
  },
});

export const loadHomeRecommended = filter => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeRecommended());
      if (filter && filter !== getState().homepage.recommended.filter) {
        dispatch(setHomeRecommendedFilter(filter));
      }
      const lang = langSelector(getState());

      let tree =
        getState().recommended &&
        getState().recommended.recommendedArticlesOptions;
      if (!tree) {
        const subtree = await API.loadSitemapSubtree(
          'ODPORUCANE',
          {},
          {
            xAcceptLanguage: lang,
          },
        );
        tree = prop(subtree, 'sitemap_tree', []);
        dispatch(loadHomeRecommendedOptions(tree));
      }
      const firstOptionId = tree && tree[0] && tree[0].id;

      const id = parseInt(filter, 10) || firstOptionId || 0;
      if (id) {
        const articles = await API.loadArticles(
          {
            sitemapId: id,
            limit: 5,
            sfForm: 'Article',
            onlyActual: '1',
            isPublished: '1',
            tagExcludeId: 1518,
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeRecommendedSuccess(articles));
      } else {
        dispatch(receiveHomeRecommendedSuccess([]));
      }
    } catch (e) {
      dispatch(receiveHomeRecommendedError(e));
    }
  };
};

const receiveHomeRecommendedOptionsSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedOptionsError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeRecommendedOptions = options => {
  return async (dispatch, getState, API) => {
    try {
      await dispatch(receiveHomeRecommendedOptionsSuccess(options));
    } catch (e) {
      dispatch(receiveHomeRecommendedOptionsError(e));
    }
  };
};

const requestHomeFastNews = () => ({
  type: REQUEST_HOME_FAST_NEWS,
});

const receiveHomeFastNewsSuccess = fastNews => ({
  type: RECEIVE_HOME_FAST_NEWS_SUCCESS,
  payload: {
    fastNews,
  },
});

const receiveHomeFastNewsError = error => ({
  type: RECEIVE_HOME_FAST_NEWS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeFastNews = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeFastNews());
      const lang = langSelector(getState());
      const articles = await API.loadArticles(
        {
          sitemapUniqueId: 'BLESKOVKY',
          limit: 3,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeFastNewsSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeFastNewsError(e));
    }
  };
};

const requestHomeTopArticle = () => ({
  type: REQUEST_HOME_TOP_ARTICLE,
});

const receiveHomeTopArticlesSuccess = topArticle => ({
  type: RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  payload: {
    topArticle,
  },
});

const receiveHomeTopArticleError = error => ({
  type: RECEIVE_HOME_TOP_ARTICLE_ERROR,
  payload: {
    error,
  },
});

export const loadHomeTopArticle = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeTopArticle());
      const lang = langSelector(getState());
      const topArticle = await API.loadArticles(
        {
          isTop: '1',
          sfForm: 'Article',
          onlyActual: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeTopArticlesSuccess(topArticle));
    } catch (e) {
      dispatch(receiveHomeTopArticleError(e));
    }
  };
};

const receiveHomeBannerSuccess = banner => ({
  type: RECEIVE_HOME_BANNER_SUCCESS,
  payload: {
    banner,
  },
});

export const loadHomeBanner = () => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      const banners = await API.loadBanners(
        { position: 'HOMEPAGE' },
        { xAcceptLanguage: lang },
      );
      const banner = prop(banners, 'banners');
      if (banner && banner.length > 0) {
        dispatch(receiveHomeBannerSuccess(banner[0]));
      } else {
        dispatch(receiveHomeBannerSuccess(null));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const requestHomeEbooks = () => ({
  type: REQUEST_HOME_EBOOKS,
});

const receiveHomeEbooksSuccess = ebooks => ({
  type: RECEIVE_HOME_EBOOKS_SUCCESS,
  payload: {
    ebooks,
  },
});

const receiveHomeEbooksError = error => ({
  type: RECEIVE_HOME_EBOOKS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeEbooks = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeEbooks());
      const lang = langSelector(getState());
      const ebooks = await API.loadArticles(
        {
          limit: 3,
          sitemapUniqueId: 'EBOOKY',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeEbooksSuccess(ebooks));
    } catch (e) {
      dispatch(receiveHomeEbooksError(e));
    }
  };
};

const requestHomeBenefits = () => ({
  type: REQUEST_HOME_BENEFITS,
});

const receiveHomeBenefitsSuccess = benefits => ({
  type: RECEIVE_HOME_BENEFITS_SUCCESS,
  payload: {
    benefits,
  },
});

const receiveHomeBenefitsError = error => ({
  type: RECEIVE_HOME_BENEFITS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeBenefits = () => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const homeBenefits = firstHomeBenefitsSelector(state);

      if (!homeBenefits) {
        dispatch(requestHomeBenefits());
        const benefitsArray: any[] = [];
        benefitsArray.push({
          ...(await API.loadOtherTexts('BENEFITS_1')),
          icon: '/images/zemplin/heart.svg',
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('BENEFITS_2')),
          icon: '/images/zemplin/fast.svg',
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('BENEFITS_3')),
          icon: '/images/zemplin/wallet.svg',
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('BENEFITS_4')),
          icon: '/images/zemplin/thumbup.svg',
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('BENEFITS_5')),
          icon: '/images/zemplin/package.svg',
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('BENEFITS_6')),
          icon: '/images/zemplin/help.svg',
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('BENEFITS_7')),
          icon: '/images/zemplin/service.svg',
        });
        benefitsArray.push({
          ...(await API.loadOtherTexts('BENEFITS_8')),
          icon: '/images/zemplin/settings.svg',
        });
        dispatch(receiveHomeBenefitsSuccess(benefitsArray));
      }
    } catch (e) {
      dispatch(receiveHomeBenefitsError(e));
    }
  };
};
