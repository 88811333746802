import * as React from 'react';
import { Link } from 'react-router';
import styled, { withTheme } from 'styled-components';
import { rem, darken } from 'polished';
import { TextP } from 'eshop-defaults';
import { FlexCol, FlexBetween } from 'eshop-defaults';
import { __ } from 'react-i18n';
import { TableTd, TableRow } from 'eshop-defaults';

interface Props {
  data;
  handleShowActivityDetail;
}

export function CrmDashboardActivityRow(props: Props) {
  const { AktivitaID, Datum, AktivitaPredmet } = props.data;
  return (
    <StyledTableRow onClick={() => props.handleShowActivityDetail(props.data)}>
      <StyledTableTd>
        <StatusText>{AktivitaID}</StatusText>
      </StyledTableTd>
      <StyledTableTd>
        <StatusText>{Datum}</StatusText>
      </StyledTableTd>
      <StyledTableTd>
        <StatusText>{AktivitaPredmet}</StatusText>
      </StyledTableTd>
    </StyledTableRow>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: ;
`;

const StyledTableTd = styled(TableTd)`
  padding: ${rem(16)} ${rem(24)};
  border-bottom: ${({ theme }) => theme.borders.primary};
`;

const StatusTd = styled(StyledTableTd)``;

const StyledTableRow = styled(TableRow)`
  padding: ${rem(24)} 0;
  border-top: ${({ theme }) => theme.borders.primary};

  &:last-child {
    border-bottom: ${({ theme }) => theme.borders.primary};
  }

  &:hover {
    background: #edebeb;
    cursor: pointer;
  }
`;

const StatusText = styled(TextP)<{ color?: string }>`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  color: ${({ theme, color }) => (color ? color : theme.colors.textPrimary)};
  text-align: left;
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
