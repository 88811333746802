import {
  WasAddedToCartModal,
  FlexCol,
  ZemplinLoaderWrapper,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import {
  wasAddedToCartModalSelector,
  wasAddedToCartModalProductSelector,
  setWasAddedToCartModalVisibility,
  wasAddedToCartModalCountSelector,
  loadProductInCartRecommended,
  wasAddedToCartModalIsFetchingSelector,
  wasAddedToCartModalBoughtTooSelector,
  setAddToCartModalVisibility,
  addToCartModalIsRequestSelector,
  addToCartModalIsFromCategorySelector,
} from '../Cart/cartSlice';
import SecondSlider from '../../components/_helpers/Slider/SecondSlider';
import { __ } from 'react-i18n';
import { cartIndexRoute } from '../../utilities/cart';
import { WithRouterProps, withRouter } from 'react-router';
import API, { ThenArg } from '../../services/API';

interface Props {
  wasAddedToCartModal: boolean;
  wasAddedToCartModalProduct: any;
  wasAddedToCartModalCount: number;
  wasAddedToCartModalIsFetching: boolean;
  wasAddedToCartModalBoughtToo: ThenArg<typeof API.loadProductConnections>;
  dispatch: any;
  isRequest: boolean;
  user: any;
  isFromCategory: boolean;
}

function AddedToCartModal({
  wasAddedToCartModal,
  wasAddedToCartModalProduct,
  wasAddedToCartModalCount,
  wasAddedToCartModalIsFetching,
  wasAddedToCartModalBoughtToo,
  isRequest,
  dispatch,
  router,
  user,
  isFromCategory,
}: Props & WithRouterProps) {
  React.useEffect(() => {
    if (wasAddedToCartModalProduct) {
      dispatch(loadProductInCartRecommended(wasAddedToCartModalProduct));
    }
  }, [wasAddedToCartModalProduct, dispatch]);

  const handleToCartButtonClick = () => {
    dispatch(setWasAddedToCartModalVisibility());
    router.push(cartIndexRoute);
  };

  const addToCart = (product: any) => {
    dispatch(setWasAddedToCartModalVisibility());
    dispatch(setAddToCartModalVisibility(false, product));
  };

  const addToRequest = (product: any) => {
    dispatch(setWasAddedToCartModalVisibility());
    dispatch(setAddToCartModalVisibility(true, product));
  };

  return (
    <>
      {wasAddedToCartModal && wasAddedToCartModalProduct && (
        <WasAddedToCartModal
          product={wasAddedToCartModalProduct}
          handleClose={() => dispatch(setWasAddedToCartModalVisibility())}
          handleToCartButtonClick={handleToCartButtonClick}
          count={wasAddedToCartModalCount}
          isRequest={isRequest}
          wasAddedToCartModalIsFetching={wasAddedToCartModalIsFetching}
          wasAddedToCartModalBoughtToo={wasAddedToCartModalBoughtToo}
          isLoggedIn={user && user.b2b ? true : false}
          isFromCategory={isFromCategory}
        >
          {wasAddedToCartModalIsFetching ? (
            <ZemplinLoaderWrapper />
          ) : (
            wasAddedToCartModalBoughtToo &&
            wasAddedToCartModalBoughtToo.products.length > 0 && (
              <BoughtTooWrapper>
                <Title>{__('Zákazníci tiež kúpili')}</Title>
                <SecondSlider
                  type="addToCartProduct"
                  slides={wasAddedToCartModalBoughtToo.products}
                  addToCart={addToCart}
                  addToRequest={addToRequest}
                  maxSlidesVisible={2}
                  user={user}
                  onClick={() => dispatch(setWasAddedToCartModalVisibility())}
                />
              </BoughtTooWrapper>
            )
          )}
        </WasAddedToCartModal>
      )}
    </>
  );
}

const Title = styled.h4`
  font-size: ${rem(20)};
  font-weight: 400;
  line-height: ${rem(38)};
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0;
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `}
`;

const BoughtTooWrapper = styled(FlexCol)`
  ${({ theme }) => theme.mediab.m600`
    display:none;
  `}
`;

const mapStateToProps = state => {
  return {
    wasAddedToCartModal: wasAddedToCartModalSelector(state),
    wasAddedToCartModalProduct: wasAddedToCartModalProductSelector(state),
    wasAddedToCartModalCount: wasAddedToCartModalCountSelector(state),
    wasAddedToCartModalIsFetching: wasAddedToCartModalIsFetchingSelector(state),
    wasAddedToCartModalBoughtToo: wasAddedToCartModalBoughtTooSelector(state),
    isFromCategory: addToCartModalIsFromCategorySelector(state),
    isRequest: addToCartModalIsRequestSelector(state),
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(AddedToCartModal));
