import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { __r } from 'react-i18n';
import { hideDimmer, hideLoginModal } from '../../containers/App/actions';
import API from '../../services/API';
import { ForgotPasswordForm, MetaTags } from 'eshop-defaults';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';

interface Props {
  push: (path: string) => any;
  dispatch: (action: any) => void;
}

interface State {
  isFetching: boolean;
  error: string | null;
  emailSended: boolean;
}

class ForgotPassword extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isFetching: false,
      emailSended: false,
    };
  }

  public componentDidMount = () => {
    this.props.dispatch(hideDimmer());
    this.props.dispatch(hideLoginModal());
    this.props.dispatch(
      setBreadCrumbPath(BreadCrumbType.FORGOT_PASSWORD, null),
    );
  };

  public onOk = () => {
    this.props.push('/');
  };

  public onSendEmailClick = async email => {
    try {
      this.setState({ isFetching: true });
      await API.lostPassword({}, { email });
      this.setState({ emailSended: true, isFetching: false });
    } catch (exp) {
      this.setState({ emailSended: false, isFetching: false });
      this.setState({ error: exp.details.description });
    }
  };

  public onSignUpLinkClick = () => {
    this.props.push(__r('routes:registracia', '/registracia'));
  };

  public render() {
    const { error, isFetching, emailSended } = this.state;

    return (
      <>
        <Wrapper>
          <MetaTags tags={{ title: 'Zabudnuté heslo' }} />
          <ForgotPasswordForm
            error={error}
            isFetching={isFetching}
            emailSended={emailSended}
            onSendEmailClick={this.onSendEmailClick}
          />
        </Wrapper>
        <InfoBanner />
      </>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  margin: ${rem(80)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: 0 ${rem(16)};
     margin: ${rem(32)} auto;
  `}
`;

const mapStateToProps = (state, actions) => {
  return {
    lang: state.general.lang,
    push: actions.router.push,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ForgotPassword' })(ForgotPassword),
);
