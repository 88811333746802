import React, { ReactNode, useEffect } from 'react';
import {
  ga4CartEvent,
  ga4EventsItemDetail,
} from '@bart.sk-ecommerce/react-online-marketing/lib/index';
import {
  AFFILIATION_VALUE,
  DATALAYER_PRODUCTS_COOKIE,
} from '../../containers/App/constants';
import { useLocalStorage } from '../../utilities/useLocalStorage';

type Props = {
  product: any;
  activeGood?: any;
};

const TrackProductDetail: React.FC<Props> = ({
  product,
  activeGood,
}: Props) => {
  const [analyticsSent, setAnalyticsSent] = React.useState(false);
  const [dataLayerProducts, setDataLayerProducts] = useLocalStorage(
    DATALAYER_PRODUCTS_COOKIE,
    {},
  );
  useEffect(() => {
    if (product && !analyticsSent) {
      setAnalyticsSent(true);

      ga4EventsItemDetail(
        AFFILIATION_VALUE,
        'view_item',
        product,
        dataLayerProducts,
        {
          good: activeGood,
          item_list_name: 'Product detail',
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, analyticsSent]);

  return null;
};

export default TrackProductDetail;
