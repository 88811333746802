import { prop } from './index';

export function getImagePath(
  filename,
  { width, height } = { width: 64, height: 80 },
  noSize = false,
  webp = false,
  typeCover = false,
) {
  if (height === 0) {
    return `${
      process.env.REACT_APP_API_BASE_URL
    }/image?query=${filename}&width=${width}&webp=${webp ? 1 : 0}${
      typeCover ? '&type=cover' : ''
    }`;
  }

  return noSize
    ? `${process.env.REACT_APP_API_BASE_URL}/image?query=${filename}&webp=${
        webp ? 1 : 0
      }${typeCover ? '&type=cover' : ''}`
    : `${
        process.env.REACT_APP_API_BASE_URL
      }/image?query=${filename}&width=${width}&height=${height}&webp=${
        webp ? 1 : 0
      }${typeCover ? '&type=cover' : ''}`;
}

export function getFilePath(filename) {
  return `${process.env.REACT_APP_API_BASE_URL}/file?query=${filename}`;
}

export function getColorFromName(name) {
  switch (name) {
    case 'Červená':
      return 'red';
    case 'Modrá':
      return 'blue';
    case 'Žltá':
      return 'yellow';
    case 'Čierna':
      return 'black';

    case 'Light Cyan':
      return 'lightcyan';
    case 'Red':
      return 'red';
    case 'Blue':
      return 'blue';
    case 'Yellow':
      return 'yellow';
    case 'Black':
      return 'black';

    case 'Cyan':
      return 'cyan';
    case 'Green':
      return 'green';
    case 'Gloss Optimizer':
      return 'grey';
    case 'Grey':
      return 'grey';
    case 'Light Black':
      return '#000000ab';
    case 'Light Light Black':
      return '#00000059';
    case 'Light Magenta':
      return '#E78BE7';

    case 'Orange':
      return 'orange';
    case 'Matte Black':
      return '#141414';
    case 'Magenta':
      return 'magenta';

    case 'Photo Black':
      return '#191616';
    case 'Photo Cyan':
      return '#00ffffb5';
    case 'Photo Grey':
      return '#808080db';
    case 'Photo Magenta':
      return '#fac';

    case 'Vivi Light Magenta':
      return '#F245C9';
    case 'Vivid Magenta':
      return '#F41AC1';

    default:
      return '';
  }
}

/**
 * Decode HTML characters
 * @param html string which contains html characters
 */
export function decodeHtml(html) {
  if (typeof document !== 'undefined') {
    let txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
  return html;
}

export function resolveProductUrl(product) {
  let slug;
  let publish = prop(product, 'publish');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
  }
  if (publish) {
    slug = publish.product_url;
  }

  const url = prop(product, 'url', '');
  if (url.substr(0, 1) === '/') {
    slug = url;
  }

  slug = `/${prop(product, 'url', '')}`;

  return '/product/' + slug;
}

export function resolveProductName(product) {
  let publish = prop(product, 'publish');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
    // publish = publish.find(el => {
    //   return el.lang_id === lang;
    // });
  }
  if (publish) {
    return prop(publish, 'product_name', null) || product.name;
  }
  return product.name;
}

export function resolveShortDescription(product) {
  let publish = prop(product, 'publish');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
  }

  if (publish && publish.content) {
    const descr = publish.content.json_content.short_description;
    if (typeof descr !== 'object') {
      return descr;
    }
  }
  return product.description;
}

export function resolveAvailabilityName(availability) {
  let publish = prop(availability, 'translations');
  if (publish && Array.isArray(publish)) {
    publish = publish[0];
  }
  if (publish) {
    return prop(publish, 'avail_name');
  }

  return prop(availability, 'avail_uniqid', '');
}

export function resolveAttribValueName(value) {
  if (
    value.translations &&
    Array.isArray(value.translations) &&
    value.translations.length
  ) {
    const valueTranslation = value.translations[0];
    if (valueTranslation) {
      return prop(valueTranslation, 'attrib_value');
    }
  }
  return prop(value, 'attrib_value');
}

export function resolveAttribUrl(value) {
  if (
    value.translations &&
    Array.isArray(value.translations) &&
    value.translations.length
  ) {
    const valueTranslation = value.translations[0];
    if (valueTranslation) {
      return prop(valueTranslation, 'attrib_url');
    }
  }
  return prop(value, 'attrib_url');
}

export function resolveCategoryName(category) {
  // let name;
  // if (
  //   category.content
  // ) {
  //   const content = category.content[0];
  //   if (content) {
  //     name = prop(content, 'json_content.name');
  //   }
  // }
  // if(name){
  //   return name;
  // }
  return prop(category, 'category_name');
}

export function resolveCategoryDescr(category) {
  let name;
  if (category.content) {
    const content = category.content[0];
    if (content) {
      name = prop(content, 'json_content.body');
    }
  }
  if (name) {
    return name;
  }
  prop(category, 'category_descr');
}

export function resolveProductPlu(product) {
  if (product.product_plu) {
    return product.product_plu;
  } else if (product.plu) {
    return product.plu;
  }
  return '';
}
