import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  MetaTags,
  ZemplinLoaderWrapper,
  InfoText,
  RequestDetail,
} from 'eshop-defaults';
import {
  fetchRequestDetail,
  requestDetailDataSelector,
  requestDetailIsFetchingSelector,
  startLoadingRequestDetail,
  fetchRequestDetailSuccess,
} from './myAccountSlice';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { withRouter, WithRouterProps } from 'react-router';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
}

class MyRequestsDetail extends React.Component<Props & WithRouterProps> {
  state = {
    isCreatingOrder: false,
  };

  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { id },
    } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_REQUESTS_DETAIL));
      await dispatch(fetchRequestDetail(id));
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const { data, isFetching, user } = this.props;
    const { isCreatingOrder } = this.state;

    if (isFetching) {
      return <ZemplinLoaderWrapper />;
    }

    if (!data) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Dopyt sa nepodarilo načítať')}
        />
      );
    }

    const { order_id } = data;
    console.log(data);
    return (
      <>
        <MetaTags
          tags={{ title: `${__('Moj účet - Detail dopytu č.')} ${order_id}` }}
        />
        <RequestDetail
          handleCreateOrderClick={this.handleCreateOrderClick}
          removeItemFromOrder={this.removeItemFromOrder}
          enableCreateOrder={true}
          data={data}
          isB2B={user && user.b2b}
          isFetching={isCreatingOrder}
        />
      </>
    );
  }

  private removeItemFromOrder = async itemToDelete => {
    const { data } = this.props;
    let newData = data;
    if (!itemToDelete.is_meta_item) {
      let newItems = newData.items?.filter(item => item !== itemToDelete);
      this.props.dispatch(startLoadingRequestDetail());
      try {
        let order = await API.updateDemandInternal(
          {},
          { ...data, items: newItems },
        );
        this.props.dispatch(fetchRequestDetailSuccess({ order }));
      } catch (error) {
        console.log(error);
      }
    } else {
      window.alert('Túto položku nie je možné odstrániť');
    }
  };

  private handleCreateOrderClick = async (body: {
    return;
    note: string;
    demand_number: string;
  }) => {
    const { data, router } = this.props;
    try {
      let body = {
        note: null,
        order: data,
        demand_number: null,
      };
      this.setState({ isCreatingOrder: true });
      await API.createOrderFromDemand(data.order_id.toString(), {}, body);
    } catch (error) {
      window.alert(
        'Pri vytváraní objednávky došlo k chybe. Kontaktujte nás prosím emailom alebo telefonicky.',
      );
      return;
    } finally {
      this.setState({ isCreatingOrder: false });
    }
    router.push(
      `${__r('routes:dokoncena-objednavka', '/dokoncena-objednavka')}/${
        data.public_id
      }`,
    );
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    data: requestDetailDataSelector(state),
    isFetching: requestDetailIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyRequestsDetail' })(withRouter(MyRequestsDetail)),
);
