import * as React from 'react';
import styled from 'styled-components';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import { rem } from 'polished';
import { ProductTabSmall, FlexColCenter } from 'eshop-defaults';
import { trackSelectProduct } from '../../../utilities/gtm';

const BtnBack = styled(ButtonBack)`
  background-image: url('/images/zemplin/slider-back_black.svg');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: ${rem(24)};
  width: ${rem(24)};
  background-color: white;
  z-index: 500;
  right: ${rem(40)};
  top: -${rem(60)};
  border: none;
  visibility: ${({ type }) =>
    type === 'product-detail' ? 'hidden' : 'visible'};

  &:disabled {
    background-image: url('/images/zemplin/slider-back_gray.svg');
    cursor: unset;
  }

  @media only screen and (max-width: 580px) {
    top: -${rem(58)};
  }
`;

const BtnNext = styled(ButtonNext)`
  background-image: url('/images/zemplin/slider-back_black.svg');
  transform: rotate(180deg);
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: ${rem(24)};
  width: ${rem(24)};
  background-color: white;
  border: none;
  z-index: 500;
  right: ${rem(0)};
  top: -${rem(60)};
  visibility: ${({ type }) =>
    type === 'product-detail' ? 'hidden' : 'visible'};

  &:disabled {
    background-image: url('/images/zemplin/slider-back_gray.svg');
    cursor: unset;
  }

  @media only screen and (max-width: 580px) {
    top: -${rem(58)};
  }
`;

const Carousel = styled(CarouselProvider)`
  margin: auto;
  position: relative;
  width: 100%;
  overflow: visible;
`;

const SliderCustom = styled(Slider)`
  height: ${rem(240)};
`;

const SlideCustom = styled(Slide)`
  /* margin-right: ${rem(32)}; */
`;

const TabWrapper = styled(FlexColCenter)`
  /* margin-right: ${rem(32)}; */

  ${({ theme }) => theme.mediab.l925`
    &:nth-child(2n) {
      margin-right: 0;
    }
  `}

  ${({ theme }) => theme.mediab.m580`
    margin-right: 0;
  `}
`;

export interface Props {
  type: string;
  slides: any;
  addToCart: any;
  addToRequest: any;
  maxSlidesVisible?: number;
  user?: any;
  onClick?: any;
  listName?: string;
}

function SecondSlider({
  type,
  slides,
  addToCart,
  addToRequest,
  maxSlidesVisible = 4,
  user,
  onClick,
  listName,
}: Props) {
  const [state, setState]: any = React.useState({
    width: 330,
    height: 192,
    visibleSlides: 4,
  });

  const updateState = React.useCallback(
    win => {
      if (type === 'product') {
        if (win > 1450) {
          setState({
            ...state,
            width: 330,
            height: 255,
            visibleSlides: 4,
          });
        } else if (win <= 1450 && win >= 1260) {
          setState({
            ...state,
            width: 300,
            height: 255,
            visibleSlides: 4,
          });
        } else if (win <= 1260 && win >= 1100) {
          setState({
            ...state,
            width: 330,
            visibleSlides: 3,
          });
        } else if (win <= 1100 && win >= 975) {
          setState({
            ...state,
            width: 300,
            visibleSlides: 3,
          });
        } else if (win < 975 && win >= 830) {
          setState({
            ...state,
            width: 280,
            visibleSlides: 3,
          });
        } else if (win < 830 && win >= 750) {
          setState({
            ...state,
            width: 330,
            visibleSlides: 2,
          });
        } else if (win < 750 && win >= 620) {
          setState({
            ...state,
            width: 290,
            visibleSlides: 2,
          });
        } else if (win < 620 && win >= 550) {
          setState({
            ...state,
            width: 400,
            visibleSlides: 1,
          });
        } else if (win < 550 && win >= 480) {
          setState({
            ...state,
            width: 400,
            visibleSlides: 1,
          });
        } else if (win < 480 && win >= 440) {
          setState({
            ...state,
            width: 400,
            visibleSlides: 1,
          });
        } else if (win < 440 && win >= 420) {
          setState({
            ...state,
            width: 400,
            visibleSlides: 1,
          });
        } else if (win < 420 && win >= 380) {
          setState({
            ...state,
            width: 350,
            visibleSlides: 1,
          });
        } else if (win < 420 && win >= 380) {
          setState({
            ...state,
            width: 300,
            visibleSlides: 1,
          });
        } else {
          setState({
            ...state,
            width: 300,
            visibleSlides: 1,
          });
        }
      } else if (type === 'addToCartProduct') {
        setState({
          ...state,
          width: 288,
          visibleSlides: 2,
        });
      }
    },
    [type, state],
  );

  React.useEffect(() => {
    updateState(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const handleResize = () => updateState(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateState]);

  const { width, height, visibleSlides } = state;
  const renderItem = (key: number, img: string, item: any) => {
    if (type === 'product' || type === 'addToCartProduct') {
      return (
        <TabWrapper>
          <ProductTabSmall
            // tslint:disable-next-line:jsx-no-lambda
            onClick={e => {
              if (onClick) {
                onClick(e);
              }
              trackSelectProduct(item, listName, key);
            }}
            maxWidth={width}
            key={item.product_id}
            // tslint:disable-next-line:jsx-no-lambda
            addToCart={() => addToCart(item)}
            // tslint:disable-next-line:jsx-no-lambda
            addToRequest={() => addToRequest(item)}
            product={item}
            user={user}
            isInCart={false}
          />
        </TabWrapper>
      );
    }
    return null;
  };

  // window.addEventListener('resize', this.updateState);
  // window.removeEventListener('resize', this.updateState);

  return (
    <>
      {/* {slides && slides.length > 0 ? ( */}
      <Carousel
        naturalSlideHeight={height}
        naturalSlideWidth={width}
        visibleSlides={
          visibleSlides > maxSlidesVisible ? maxSlidesVisible : visibleSlides
        }
        step={visibleSlides}
        totalSlides={slides.length}
      >
        <BtnBack type={type} />
        <SliderCustom type={type}>
          {slides.map((item, index) => {
            return (
              <SlideCustom
                innerClassName="sliderCentered"
                index={index}
                key={index}
              >
                {renderItem(index, item.img ? item.img : '', item)}
              </SlideCustom>
            );
          })}
        </SliderCustom>
        <BtnNext type={type} />
      </Carousel>
      {/* ) : (
          <NoItemsWrapper text={'Nenašli sa žiadne produkty'} />
        )} */}
    </>
  );
}

export default SecondSlider;
