const namespace = 'Article';

export const REQUEST_HOME_ARTICLES = `${namespace}/REQUEST_HOME_ARTICLES`;
export const RECEIVE_HOME_ARTICLES_SUCCESS = `${namespace}/RECEIVE_HOME_ARTICLES_SUCCESS`;
export const RECEIVE_HOME_ARTICLES_ERROR = `${namespace}/RECEIVE_HOME_ARTICLES_ERROR`;
export const RECEIVE_MORE_HOME_ARTICLES_SUCCESS = `${namespace}/RECEIVE_MORE_HOME_ARTICLES_SUCCESS`;

export const REQUEST_HOME_MOST_READ = `${namespace}/REQUEST_HOME_MOST_READ`;
export const RECEIVE_HOME_MOST_READ_SUCCESS = `${namespace}/RECEIVE_HOME_MOST_READ_SUCCESS`;
export const RECEIVE_HOME_MOST_READ_ERROR = `${namespace}/RECEIVE_HOME_MOST_READ_ERROR`;

export const REQUEST_HOME_RECOMMENDED = `${namespace}/REQUEST_HOME_RECOMMENDED`;
export const RECEIVE_HOME_RECOMMENDED_SUCCESS = `${namespace}/RECEIVE_HOME_RECOMMENDED_SUCCESS`;
export const RECEIVE_HOME_RECOMMENDED_ERROR = `${namespace}/RECEIVE_HOME_RECOMMENDED_ERROR`;

export const REQUEST_HOME_RECOMMENDED_OPTIONS = `${namespace}/REQUEST_HOME_RECOMMENDED_OPTIONS`;
export const RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS = `${namespace}/RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS`;
export const RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR = `${namespace}/RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR`;

export const REQUEST_HOME_FAST_NEWS = `${namespace}/REQUEST_HOME_FAST_NEWS`;
export const RECEIVE_HOME_FAST_NEWS_SUCCESS = `${namespace}/RECEIVE_HOME_FAST_NEWS_SUCCESS`;
export const RECEIVE_HOME_FAST_NEWS_ERROR = `${namespace}/RECEIVE_HOME_FAST_NEWS_ERROR`;

export const REQUEST_HOME_TOP_ARTICLE = `${namespace}/REQUEST_HOME_TOP_ARTICLE`;
export const RECEIVE_HOME_TOP_ARTICLE_SUCCESS = `${namespace}/RECEIVE_HOME_TOP_ARTICLE_SUCCESS`;
export const RECEIVE_HOME_TOP_ARTICLE_ERROR = `${namespace}/RECEIVE_HOME_TOP_ARTICLE_ERROR`;

export const REQUEST_HOME_EBOOKS = `${namespace}/REQUEST_HOME_EBOOKS`;
export const RECEIVE_HOME_EBOOKS_SUCCESS = `${namespace}/RECEIVE_HOME_EBOOKS_SUCCESS`;
export const RECEIVE_HOME_EBOOKS_ERROR = `${namespace}/RECEIVE_HOME_EBOOKS_ERROR`;

export const SET_HOME_RECOMMENDED_FILTER = `${namespace}/SET_HOME_RECOMMENDED_FILTER`;
export const SET_HOME_MOST_READ_FILTER = `${namespace}/SET_HOME_MOST_READ_FILTER`;

export const RECEIVE_HOME_BANNER_SUCCESS = `${namespace}/RECEIVE_HOME_BANNER_SUCCESS`;

export const SET_HOME_ARTICLES_FILTERS = `${namespace}/SET_HOME_ARTICLES_FILTERS`;

export const REQUEST_HOME_NEW_PRODUCTS = `${namespace}/REQUEST_HOME_NEW_PRODUCTS`;
export const RECEIVE_HOME_NEW_PRODUCTS_SUCCESS = `${namespace}/RECEIVE_HOME_NEW_PRODUCTS_SUCCESS`;
export const RECEIVE_HOME_NEW_PRODUCTS_ERROR = `${namespace}/RECEIVE_HOME_NEW_PRODUCTS_ERROR`;

export const REQUEST_HOME_BANNERS = `${namespace}/REQUEST_HOME_BANNERS`;
export const RECEIVE_HOME_BANNERS_SUCCESS = `${namespace}/RECEIVE_HOME_BANNERS_SUCCESS`;
export const RECEIVE_HOME_BANNERS_ERROR = `${namespace}/RECEIVE_HOME_BANNERS_ERROR`;

export const REQUEST_HOME_SALE_PRODUCTS = `${namespace}/REQUEST_HOME_SALE_PRODUCTS`;
export const RECEIVE_HOME_SALE_PRODUCTS_SUCCESS = `${namespace}/RECEIVE_HOME_SALE_PRODUCTS_SUCCESS`;
export const RECEIVE_HOME_SALE_PRODUCTS_ERROR = `${namespace}/RECEIVE_HOME_SALE_PRODUCTS_ERROR`;

export const REQUEST_HOME_BENEFITS = `${namespace}/REQUEST_HOME_BENEFITS`;
export const RECEIVE_HOME_BENEFITS_SUCCESS = `${namespace}/RECEIVE_HOME_BENEFITS_SUCCESS`;
export const RECEIVE_HOME_BENEFITS_ERROR = `${namespace}/RECEIVE_HOME_BENEFITS_ERROR`;
