import {
  getSuccesfullB2BRegisterMsg,
  getSuccesfullLoginMsg,
} from 'eshop-defaults/lib/defaults';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  SET_LANGUAGE,
  SET_CURRENCY,
  SET_DEFAULT_TITLE,
  SET_TOP_MENU_ITEMS,
  SET_FOOTER_DATA,
  SET_CONTACT_INFO,
  SET_SOCIAL_URLS,
  SET_META_TAGS,
  REQUEST_REGISTER_USER,
  RECEIVE_REGISTER_USER_SUCCESS,
  RECEIVE_REGISTER_USER_FAILURE,
  REQUEST_REGISTER_B2B,
  RECEIVE_REGISTER_B2B_SUCCESS,
  RECEIVE_REGISTER_B2B_FAILURE,
  REQUEST_LOGIN_USER,
  RECEIVE_LOGIN_USER_SUCCESS,
  RECEIVE_LOGIN_USER_FAILURE,
  OPEN_DIMMER,
  CLOSE_DIMMER,
  REQUEST_CREATED_ORDER,
  RECEIVE_CREATED_ORDER_SUCCESS,
  RECEIVE_CREATED_ORDER_ERROR,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  SET_INVOICE_DATA,
  SET_CONTROL_INSTITUTE_DATA,
  SET_POINTS_AMOUNT_RATIO,
  SET_FREE_DELIVERY_INFO,
  SET_TOP_MENU_BRANDS,
  REQUEST_SEARCH_TERM,
  RECEIVE_SEARCH_TERM_SUCCESS,
  REQUEST_PARTIAL_SEARCH_TERM,
  RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS,
  SET_SEARCH_RESULTS_FILTERS,
  ERROR_TOP_MENU_ITEMS,
  REQUEST_TOP_MENU_ITEMS,
  RECEIVE_SITEMAP_SUCCESS,
  REQUEST_PARTIAL_CATEGORY_SEARCH_TERM,
  RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS,
  SET_CURRENT_THEME,
  SET_SETTINGS_LOADED,
  RECEIVE_ADDITIONAL_PRODUCT_RESULTS_SUCCESS,
  RECEIVE_SEARCH_TERM_CATEGORIES_SUCCESS,
  SET_TOP_TEXT,
  SET_NOT_FOUND,
  REQUEST_CART_IS_IMPORTING,
} from './constants';

import { REDIRECT } from '@bart.sk-ecommerce/react-eshop-redirects/lib/constants';
import { redirectReducer } from '@bart.sk-ecommerce/react-eshop-redirects/lib/reducer';

export const generalInitialState = {
  lang: 'sk',
  langSet: false,
  currency: 'EUR',
  title: 'Zemplin.sk',
  socialUrls: {
    fbUrl: 'http://facebook.com',
    igUrl: 'http://www.instagram.sk',
    ytUrl: 'http://www.youtube.sk',
  },
  topText: '',
  search: {
    isFetching: false,
    results: [],
    offset: 0,
    categories: [],
    partialResults: {
      isFetching: false,
      products: null,
      isFetchingCategories: false,
      categories: null,
    },
  },
  menuItems: {
    topMenuItems: [],
    isFetching: false,
  },
  register: {
    isFetching: false,
    success: '',
    error: '',
  },
  registerB2b: {
    isFetching: false,
    success: '',
    error: '',
  },
  currentTheme: '',
  areSettingsLoaded: false,
  notFound: false,
};

export const generalReducer = (
  state = generalInitialState,
  { type, payload },
) => {
  switch (type) {
    case SET_NOT_FOUND:
      return {
        ...state,
        notFound: payload.notFound,
      };
    case SET_SETTINGS_LOADED: {
      return {
        ...state,
        areSettingsLoaded: payload.isLoaded,
      };
    }

    case SET_TOP_TEXT: {
      return {
        ...state,
        topText: payload.text,
      };
    }

    case LOCATION_CHANGE:
      const { pathname } = payload;
      // themes are applied only to category and product detail
      const isDefault =
        pathname.includes('kategoria/') || pathname.includes('produkt/')
          ? false
          : true;
      return {
        ...state,
        currentTheme: isDefault ? '' : state.currentTheme,
      };
    case SET_CURRENT_THEME:
      return {
        ...state,
        currentTheme: payload.theme,
      };
    case RECEIVE_SITEMAP_SUCCESS:
      return {
        ...state,
        sitemap: payload.content,
      };
    case SET_FREE_DELIVERY_INFO:
      return {
        ...state,
        freeDeliveryInfo: payload.freeDeliveryInfo,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        lang: payload.lang,
        langSet: true,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: payload.currency,
      };
    case SET_DEFAULT_TITLE:
      return {
        ...state,
        title: payload.defaultTitle,
      };
    case REQUEST_TOP_MENU_ITEMS:
      return {
        ...state,
        menuItems: {
          ...state.menuItems,
          isFetching: true,
        },
      };
    case ERROR_TOP_MENU_ITEMS:
      return {
        ...state,
        menuItems: {
          ...state.menuItems,
          isFetching: false,
        },
      };
    case SET_TOP_MENU_ITEMS:
      return {
        ...state,
        menuItems: {
          ...state.menuItems,
          topMenuItems: payload.menuItems,
          isFetching: false,
        },
      };
    case SET_TOP_MENU_BRANDS:
      return {
        ...state,
        brands: payload.brands,
      };
    case SET_FOOTER_DATA:
      return {
        ...state,
        footerData: payload.footerData,
      };
    case SET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: payload.contactInfo,
      };
    case SET_SOCIAL_URLS:
      return {
        ...state,
        socialUrls: payload.socialUrls,
      };
    case SET_META_TAGS:
      return {
        ...state,
        metaTags: payload.metaTags,
      };
    case OPEN_DIMMER: {
      return {
        ...state,
        dimmerVisible: true,
        lockScrollBar: payload.lockScrollBar,
      };
    }
    case CLOSE_DIMMER: {
      return {
        ...state,
        dimmerVisible: false,
      };
    }
    case OPEN_LOGIN_MODAL: {
      return {
        ...state,
        loginModalVisible: true,
      };
    }

    case CLOSE_LOGIN_MODAL: {
      return {
        ...state,
        loginModalVisible: false,
      };
    }
    case SET_INVOICE_DATA: {
      return {
        ...state,
        invoiceData: payload.invoiceData,
      };
    }
    case SET_CONTROL_INSTITUTE_DATA: {
      return {
        ...state,
        controlInstituteData: payload.controlInstituteData,
      };
    }
    case SET_POINTS_AMOUNT_RATIO: {
      return {
        ...state,
        pointsAmountRatio: parseFloat(payload.pointsAmountRatio),
      };
    }
    case SET_SEARCH_RESULTS_FILTERS: {
      return {
        ...state,
        search: {
          ...state.search,
          offset: payload.filters.offset,
        },
      };
    }
    case REQUEST_SEARCH_TERM: {
      return {
        ...state,
        search: {
          ...state.search,
          searchTerm: payload.searchTerm,
          isFetching: true,
        },
      };
    }

    case RECEIVE_SEARCH_TERM_CATEGORIES_SUCCESS: {
      return {
        ...state,
        search: {
          ...state.search,
          categories: payload.categories,
        },
      };
    }

    case RECEIVE_SEARCH_TERM_SUCCESS: {
      // if (payload.searchTerm !== state.searchTerm) {
      //   return state;
      // }
      return {
        ...state,
        search: {
          ...state.search,
          isFetching: false,
          results: {
            products: payload.products,
          },
        },
      };
    }
    case REQUEST_PARTIAL_SEARCH_TERM: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            isFetching: true,
          },
        },
      };
    }

    case RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            isFetching: false,
            products: payload.products,
          },
        },
      };
    }

    case RECEIVE_ADDITIONAL_PRODUCT_RESULTS_SUCCESS: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            categoryProducts: payload.products,
          },
        },
      };
    }

    case REQUEST_PARTIAL_CATEGORY_SEARCH_TERM: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            isFetchingCategories: true,
          },
        },
      };
    }

    case REQUEST_CART_IS_IMPORTING: {
      return {
        ...state,
        isImporting: payload.isImporting,
      };
    }

    case RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            isFetchingCategories: false,
            categories: payload.products,
          },
        },
      };
    }

    case REQUEST_REGISTER_USER:
      return {
        ...state,
        register: {
          isFetching: true,
          error: '',
          success: '',
        },
      };
    case RECEIVE_REGISTER_USER_SUCCESS:
      return {
        ...state,
        register: {
          success: getSuccesfullLoginMsg(),
          error: '',
          isFetching: false,
        },
      };
    case RECEIVE_REGISTER_USER_FAILURE:
      return {
        ...state,
        register: {
          error: payload.error,
          success: '',
          isFetching: false,
        },
      };

    case REQUEST_REGISTER_B2B:
      return {
        ...state,
        registerB2b: {
          isFetching: true,
          error: '',
          success: '',
        },
      };
    case RECEIVE_REGISTER_B2B_SUCCESS:
      return {
        ...state,
        registerB2b: {
          success: getSuccesfullB2BRegisterMsg(),
          error: '',
          isFetching: false,
        },
      };
    case RECEIVE_REGISTER_B2B_FAILURE:
      return {
        ...state,
        registerB2b: {
          error: payload.error,
          success: '',
          isFetching: false,
        },
      };

    case REDIRECT: {
      return redirectReducer(state, payload);
    }
    default:
      return state;
  }
};

const loginReducerInitialState = {};

export const loginReducer = (state = loginReducerInitialState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN_USER:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_LOGIN_USER_SUCCESS:
      return {
        ...state,
        success: getSuccesfullLoginMsg(),
        isFetching: false,
      };
    case RECEIVE_LOGIN_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const ordersReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CREATED_ORDER: {
      return {
        ...state,
        createdOrder: {
          isFetching: true,
        },
      };
    }

    case RECEIVE_CREATED_ORDER_SUCCESS: {
      return {
        ...state,
        createdOrder: {
          isFetching: false,
          createdOrderData: { ...action.payload.createdOrder },
        },
      };
    }

    case RECEIVE_CREATED_ORDER_ERROR: {
      return {
        ...state,
        createdOrder: {
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    default:
      return state;
  }
};
