import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { InlineShareButtons } from 'sharethis-reactjs';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';

const Wrapper = styled(FlexCol)`
  max-width: ${rem(960)};
  width: 100%;
  height: ${rem(190)};
  background-color: white;
  border: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
     
  `}
`;

const Title = styled.h2`
  display: flex;
  align-items: center;
  height: ${rem(94)};
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(20)};
  margin: 0 ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 500;
  text-decoration: none;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.blogColors.border};

  ${({ theme }) => theme.mediab.m580`
     justify-content: center;
  `}
`;

const SocialsWrapper = styled(FlexRowCenterVertical)`
  padding: ${rem(16)};
  height: ${rem(94)};

  ${({ theme }) => theme.mediab.m580`
     justify-content: center;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${rem(48)};
`;

const NoTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  max-width: ${rem(688)};
  margin-bottom: ${rem(80)};

  ${({ theme }) => theme.mediab.l925`
      margin-bottom: ${rem(32)};
  `}
`;

// const SocialButton = styled(FlexRowCenterVertical)`
//   max-width: ${rem(125)};
//   width: 100%;
//   height: ${rem(32)};
//   border: 1px solid black;
//   margin-right: ${rem(8)};
// `;

const renderInlineButtons = (title, desc, image) => {
  return (
    <InlineShareButtons
      config={{
        alignment: 'center', // alignment of buttons (left, center, right)
        color: 'social', // set the color of buttons (social, white)
        enabled: true, // show/hide buttons (true, false)
        font_size: 16, // font size for the buttons
        labels: 'cta', // button labels (cta, counts, null)
        language: 'en', // which language to use (see LANGUAGES)
        networks: [
          // which networks to include (see SHARING NETWORKS)
          'facebook',
          // 'messenger',
          // 'email',
          // 'linkedin',
          // 'reddit',
          // 'tumblr',
          // 'whatsapp',
          // 'pinterest',
          // 'print',
        ],
        padding: 12, // padding within buttons (INTEGER)
        radius: 4, // the corner radius on each button (INTEGER)
        show_total: false,
        size: 32, // the size of each button (INTEGER)

        // OPTIONAL PARAMETERS
        url:
          window && window.location && window.location.href
            ? window.location.href
            : '', // (defaults to current url)
        // image: 'https://bit.ly/2CMhCMC', // (defaults to og:image or twitter:image)
        description: desc, // (defaults to og:description or twitter:description)
        title, // (defaults to og:title or twitter:title)
        message: desc, // (only for email sharing)
        subject: title, // (only for email sharing)
        username: '', // (only for twitter sharing)
        image,
      }}
    />
  );
};

const ShareWrapper = ({ title, desc, image, withTitle = true }) => {
  return (
    <>
      {' '}
      {withTitle ? (
        <ButtonWrapper>
          <Wrapper>
            <Title>{__('Pošlite článok známemu')}</Title>
            <SocialsWrapper>
              {renderInlineButtons(title, desc, image)}
            </SocialsWrapper>
          </Wrapper>
        </ButtonWrapper>
      ) : (
        <NoTitleWrapper>
          {renderInlineButtons(title, desc, image)}
        </NoTitleWrapper>
      )}
    </>
  );
};

export default ShareWrapper;
