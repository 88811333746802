import * as React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import { __, __r } from 'react-i18n';
import {
  MetaTags,
  CartStepper,
  CartSummary,
  CartFooter,
  FlexRow,
  FlexCol,
  ZemplinLoaderWrapper,
  Loader,
  InfoText,
} from 'eshop-defaults';
import {
  cartIndexRoute,
  cartSecondStepRoute,
  cartThirdStepRoute,
  CART_ROUTES,
} from './utilities';
import {
  updateCart,
  resetCart,
  showProblemsInCart,
  fetchCartSuccess,
} from './cartSlice';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import { round, prop } from '../../utilities';
import { removeCartCookie } from './helpers';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import { getZemplinCartProductInfo } from 'eshop-defaults/lib/components/Zemplin/selectors';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import * as cookie from 'react-cookies';
import { USER_COOKIE } from 'react-auth';
import { useForm } from '../../utilities/hooks';

const CART_STAGES = [
  { title: __('Nákupný košík'), stepNumber: 1, stepUrl: cartIndexRoute },
  {
    title: __('Osobné údaje'),
    stepNumber: 2,
    stepUrl: cartSecondStepRoute,
  },
  {
    title: __('Dodanie a platba'),
    stepNumber: 3,
    stepUrl: cartThirdStepRoute,
  },
];

interface Props {
  children: any;
  data: any | null;
  isFetching: boolean;
  error: { details: { description: string } };
  deliveryAddresses: any;
  deliveryAddressesIsFetching: boolean;
  dispatch: any;
  handleAcceptTermsChange: (e: any) => void;
  createOrder: () => Promise<string>;
  showProblems: boolean;
  user: any;
  zoneId: number;
  handleEmptyCartClick: any;
}

interface Address {
  city: string;
  company: string;
  country: string;
  country_code: string;
  country_id: string;
  person: {
    name: string;
    surname: string;
    email: string;
    phone: string;
  };
  street: string;
  street_number: string;
  zip: string;
}

interface DeliveryAddress extends Address {
  delivery_address_id: number;
}

function CartLogic({
  children,
  location: { pathname },
  router,
  data,
  error,
  isFetching,
  deliveryAddresses,
  deliveryAddressesIsFetching,
  dispatch,
  handleAcceptTermsChange,
  createOrder,
  showProblems,
  user,
  zoneId,
  handleEmptyCartClick,
}: Props & WithRouterProps) {
  const { items } = data || {};
  const [step, setStep] = React.useState(1);
  const [isMovingToNextStep, setIsMovingToNextStep] = React.useState(false);

  const contactInfoInitialState = {
    billing_address: prop(data, 'billing_address'),
    company: prop(data, 'company'),
    delivery_address: prop(data, 'delivery_address'),
    billing_company: prop(data, 'billing_company'),
    use_delivery_address: prop(data, 'use_delivery_address'),
    note: prop(data, 'note'),
  };

  const [cartContactInfo, dispatchCartContactInfo] = useForm<{
    billing_address: Address;
    company: { name: string; ic_dph: string; ico: string; dic: string };
    delivery_address: DeliveryAddress;
    billing_company: boolean;
    use_delivery_address: boolean;
    note: string;
  }>(contactInfoInitialState);

  const handleStepChange = async (e: any, newStep: number) => {
    e.preventDefault();
    setIsMovingToNextStep(true);
    if (newStep !== step) {
      if (newStep === 4) {
        const publicId = await createOrder();
        if (publicId) {
          // remove cart, create order, redirect
          removeCartCookie();
          await dispatch(loginUser(cookie.load(USER_COOKIE)));
          await dispatch(resetCart());
          setIsMovingToNextStep(false);
          router.push(
            `${__r(
              'routes:dokoncena-objednavka',
              '/dokoncena-objednavka',
            )}/${publicId}`,
          );
        }
      } else {
        let canMoveToNext = true;
        if (data) {
          const newData: any = {
            ...data,
            step: newStep,
            billing_address: cartContactInfo.billing_address,
            company: cartContactInfo.company,
            delivery_address: {
              ...cartContactInfo.delivery_address,
              person:
                cartContactInfo.delivery_address.person &&
                cartContactInfo.delivery_address.person,
            },
            note: cartContactInfo.note,
            use_delivery_address: cartContactInfo.use_delivery_address,
            billing_company: cartContactInfo.billing_company,
          };

          try {
            const res = await API.updateCart(
              data.id,
              { vatGroups: 1 },
              newData,
            );

            if (res && res.problems) {
              if (newStep === 3) {
                canMoveToNext = !res.problems.some(p => p.type === 'form');
                if (!canMoveToNext) {
                  dispatch(showProblemsInCart(true));
                }
              } else if (newStep === 2) {
                canMoveToNext = !res.problems.some(p => p.type === 'cart');
                if (!canMoveToNext) {
                  dispatch(showProblemsInCart(true));
                }
              }

              window.scrollTo({
                behavior: 'smooth',
                top: 200,
              });
            }

            if (res) {
              dispatchCartContactInfo({
                value: prop(res, 'billing_address.person.phone', ''),
                field: 'billing_address.person.phone',
              });
              dispatchCartContactInfo({
                value: prop(res, 'billing_address.person.email', ''),
                field: 'billing_address.person.email',
              });
              dispatchCartContactInfo({
                value: prop(res, 'billing_address.person.name', ''),
                field: 'billing_address.person.name',
              });
              dispatchCartContactInfo({
                value: prop(res, 'billing_address.person.surname', ''),
                field: 'billing_address.person.surname',
              });
              dispatchCartContactInfo({
                value: prop(res, 'billing_address.city', ''),
                field: 'billing_address.city',
              });
              dispatchCartContactInfo({
                value: prop(res, 'billing_address.street', ''),
                field: 'billing_address.street',
              });
              dispatchCartContactInfo({
                value: prop(res, 'billing_address.street_number', ''),
                field: 'billing_address.street_number',
              });
              dispatchCartContactInfo({
                value: prop(res, 'billing_address.zip', ''),
                field: 'billing_address.zip',
              });
              dispatchCartContactInfo({
                value: prop(res, 'company.name', ''),
                field: 'company.name',
              });
              dispatchCartContactInfo({
                value: prop(res, 'company.ic_dph', ''),
                field: 'company.ic_dph',
              });
              dispatchCartContactInfo({
                value: prop(res, 'company.ico', ''),
                field: 'company.ico',
              });
              dispatchCartContactInfo({
                value: prop(res, 'company.dic', ''),
                field: 'company.dic',
              });
              dispatchCartContactInfo({
                value: prop(res, 'billing_company', ''),
                field: 'billing_company',
              });
              dispatch(fetchCartSuccess({ cart: res }));
            }
          } catch (err) {
            alert(err);
            canMoveToNext = false;
            dispatch(showProblemsInCart(true));
          }
        }

        if (canMoveToNext) {
          dispatch(showProblemsInCart(false));
          setStep(newStep);
          setIsMovingToNextStep(false);
          router.push(CART_ROUTES[newStep] || cartIndexRoute);
        }
      }
      setIsMovingToNextStep(false);
    }
  };

  React.useEffect(() => {
    let step = 1;
    if (pathname === cartSecondStepRoute) {
      step = 2;
    } else if (pathname === cartThirdStepRoute) {
      step = 3;
    }
    setStep(step);
    const newRoute = CART_ROUTES[step];
    dispatch(setBreadCrumbPath(BreadCrumbType.CART, { url: newRoute }));
    router.push(newRoute || cartIndexRoute);
  }, [pathname, router, dispatch]);

  const handleSendToEmailClick = () => {
    // send to email
    console.log('sending to email');
  };

  if (isFetching && !data) {
    return <ZemplinLoaderWrapper height={500} />;
  }

  const normalItems =
    data && data.items ? data.items.filter((i: any) => !i.is_demand) : '';
  const isDemand = normalItems.length === 0;
  // 0 - no partly stock, 1 - yellow icon, 2 - red icon
  let hasPartlyOnStockItem = 0;
  let cartB2BDiscount = 0;
  let hasExternalStock: boolean[] = [false];
  if (normalItems && normalItems.length > 0) {
    normalItems.map(normalItem => {
      const {
        onStock,
        count,
        unit,
        b2BDiscount,
        onStockExternal,
      } = getZemplinCartProductInfo(normalItem);
      hasExternalStock.push(onStockExternal > 0);
      const correctOnstock = onStock + onStockExternal;
      const correctCount = unit === 'Mks' ? count * 1000 : count;
      if (correctCount > correctOnstock) {
        if (correctOnstock === 0) {
          hasPartlyOnStockItem = 2;
        } else {
          hasPartlyOnStockItem = 1;
        }
        cartB2BDiscount += b2BDiscount ? +(onStock * b2BDiscount) : 0;
      } else {
        cartB2BDiscount += b2BDiscount ? +(correctCount * b2BDiscount) : 0;
      }
    });
  }

  cartB2BDiscount = round(cartB2BDiscount, 2);

  const isEmpty = !items || items.length === 0;

  // tu sa beru tie props pre firstStep
  const childrenWithProps = React.cloneElement(children, {
    data,
    isFetching,
    deliveryAddressesIsFetching,
    deliveryAddresses,
    showProblems,
    user,
    zoneId,
    hasPartlyOnStockItem,
    cartB2BDiscount,
    dispatchCartContactInfo,
    cartContactInfo,
    hasExternalStock,
    handleEmptyCartClick,
  });

  return (
    <>
      {!isEmpty && (
        <CartStepper
          steps={CART_STAGES}
          setCartStep={handleStepChange}
          currentStepNumber={step}
          pathname={pathname}
          onStepClick={handleStepChange}
        />
      )}
      <Wrapper className="container container--wide">
        <MetaTags tags={{ title: __('Košík') }} />
        {error && (
          <InfoText
            type={InfoType.ERROR}
            info={`Nastala chyba pri získavaní košíka: ${
              error && error.details ? error.details.description : ''
            }`}
          />
        )}

        {isEmpty || step === 1 ? (
          <SummaryContentWrapper>
            {childrenWithProps}{' '}
            <Loader
              style={{
                position: isFetching ? 'absolute' : 'initial',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 6,
              }}
              loading={isFetching}
              dim={true}
            />
          </SummaryContentWrapper>
        ) : (
          <SummaryContentWrapper>
            <ContentWrapper>{childrenWithProps}</ContentWrapper>
            <CartSummary
              handleAcceptTermsChange={handleAcceptTermsChange}
              data={data}
              handleStepChange={handleStepChange}
              step={step}
              isFetching={isFetching || isMovingToNextStep}
              user={user}
              isDemand={isDemand}
              hasPartlyOnStockItem={hasPartlyOnStockItem}
              cartB2BDiscount={cartB2BDiscount}
            />

            {step === 3 && (
              <Loader
                style={{
                  position: isFetching ? 'absolute' : 'initial',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 6,
                  display: isFetching ? 'block' : 'none',
                }}
                loading={isFetching}
                dim={true}
              />
            )}
          </SummaryContentWrapper>
        )}
        {!isEmpty && step === 1 && (
          <CartFooter
            handleStepChange={handleStepChange}
            handleSendToEmailClick={handleSendToEmailClick}
            isDemand={isDemand}
          />
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  padding: ${rem(56)} ${rem(24)};
  position: relative;

  @media only screen and (max-width: 1740px) {
    flex-flow: column;
    padding: 0 ${rem(24)} ${rem(32)};
    max-width: 1080px;
  }
`;

const SummaryContentWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  max-width: ${rem(1148)};
  margin: 0 auto;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     flex-flow: column;
  `}
`;

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  justify-content: flex-start;
  max-width: ${rem(688)};

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
     margin: 0 auto;
  `}
`;

export default withRouter(CartLogic);
