import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexRowCenter,
  FlexRow,
  FlexCol,
  NavigationItem,
  ItemType,
  IconImg,
  NavigationDropdownContent,
  NavigationDropdownItem,
  OutsideClick,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getImagePath } from '../../utilities/product';

export interface Props {
  user?: any;
  items: ThenArg<typeof API.loadTree>;
  changeDimmerVisible: (newVal: boolean) => void;
}

function Navigation({ items, changeDimmerVisible, user }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeFirstItem, setActiveFirstItem] = useState<number>(-1);
  const [activeSecondItem, setActiveSecondItem] = useState<number>(-1);
  const [activeThirdItem, setActiveThirdItem] = useState<number>(-1);
  const [activeFourthItem, setActiveFourthItem] = useState<number>(-1);
  const [secondLvlItems, setSecondLvlItems] = useState<any[] | null>(null);
  const [thirdLvlItems, setThirdLvlItems] = useState<any[] | null>(null);
  const [fourthLvlItems, setFourthLvlItems] = useState<any[] | null>(null);
  const [fifthLvlItems, setFifthLvlItems] = useState<any[] | null>(null);

  const onMouseOverSecond = id => {
    if ((id || id === 0) && activeFirstItem !== id) {
      if (
        items &&
        items[id] &&
        items[id].children &&
        items[id].children.length > 0
      ) {
        setSecondLvlItems(items[id].children);
      } else {
        setSecondLvlItems(null);
      }
      setThirdLvlItems(null);
      setActiveFirstItem(id);
    }
  };

  const onMouseOverThird = id => {
    if (id || id === 0) {
      if (
        secondLvlItems &&
        secondLvlItems[id] &&
        secondLvlItems[id].children &&
        secondLvlItems[id].children.length > 0
      ) {
        setThirdLvlItems(secondLvlItems[id].children);
      } else {
        setThirdLvlItems(null);
      }
      setFourthLvlItems(null);
      setFifthLvlItems(null);
      setActiveSecondItem(id);
    }
  };

  const onMouseOverFourth = id => {
    if (id || id === 0) {
      if (
        thirdLvlItems &&
        thirdLvlItems[id] &&
        thirdLvlItems[id].children &&
        thirdLvlItems[id].children.length > 0
      ) {
        setFourthLvlItems(thirdLvlItems[id].children);
      } else {
        setFourthLvlItems(null);
      }
      setFifthLvlItems(null);
      setActiveThirdItem(id);
    }
  };

  const onMouseOverFifth = id => {
    if (id || id === 0) {
      if (
        fourthLvlItems &&
        fourthLvlItems[id] &&
        fourthLvlItems[id].children &&
        fourthLvlItems[id].children.length > 0
      ) {
        setFifthLvlItems(fourthLvlItems[id].children);
      } else {
        setFifthLvlItems(null);
      }
      setActiveFourthItem(id);
    }
  };

  const closeNav = () => {
    setIsOpen(false);
    changeDimmerVisible(false);
  };

  const toggleNav = () => {
    if (!isOpen) {
      resetState();
    }
    setIsOpen(!isOpen);
    changeDimmerVisible(!isOpen);
  };

  const resetState = () => {
    setActiveFirstItem(-1);
    setActiveSecondItem(-1);
    setSecondLvlItems(null);
    setThirdLvlItems(null);
  };

  const listIconSrc = '/images/zemplin/list.svg';
  const placeholderImgSrc = '/images/zemplin/placeholder.svg';

  return (
    <OutsideClick handleFunction={closeNav}>
      <Wrapper>
        <NavigationItem
          // tslint:disable-next-line:jsx-no-lambda
          onClick={toggleNav}
          type={ItemType.PRIMARY}
          title={
            <FlexRowCenter>
              <IconImg
                marginRight={true}
                width={24}
                height={24}
                src={listIconSrc}
                cursor="pointer"
              />
              <span>Produkty</span>
            </FlexRowCenter>
          }
        />
        <SecondaryItems>
          <NavigationItem
            onClick={closeNav}
            url="/novinky"
            type={ItemType.SECONDARY}
            title={'Novinky'}
          />
          <NavigationItem
            onClick={closeNav}
            url="/akcie"
            type={ItemType.SECONDARY}
            title={'Akcie'}
          />
          <NavigationItem
            onClick={closeNav}
            url="/vypredaj"
            type={ItemType.SECONDARY}
            title={'Výpredaj'}
          />
        </SecondaryItems>{' '}
        <FlexRowCenterVertical>
          <NavigationItem
            onClick={closeNav}
            url="/aktuality"
            type={ItemType.TERTIARY}
            title={'Aktuality'}
          />
          <NavigationItem
            onClick={closeNav}
            url="/kontakt"
            type={ItemType.TERTIARY}
            title={'Kontakt'}
          />
          <NavigationItem
            onClick={closeNav}
            url="/tipy-a-rady"
            type={ItemType.TERTIARY}
            title={'Tipy a rady'}
          />
          <NavigationItem
            onClick={closeNav}
            url="/montaz-krbov-fotogaleria"
            type={ItemType.TERTIARY}
            title={'Montáž krbov - fotogaléria'}
          />
        </FlexRowCenterVertical>
        {isOpen && (
          <NavigationDropdownContent fromTop={user ? 247 : 195}>
            <FirstHoverWrapper>
              <FirstItemsWrapper>
                {items &&
                  items.map((category, i) => {
                    if (!category.counts || category.counts === 0) {
                      return null;
                    }
                    return (
                      <NavigationDropdownItem
                        index={i}
                        onClick={closeNav}
                        key={category.category_id}
                        id={category.category_id}
                        url={category.url}
                        name={category.category_name}
                        bigImgSrc={
                          category.image
                            ? getImagePath(
                                category.image,
                                {
                                  width: 300,
                                  height: 300,
                                },
                                false,
                                false,
                                false,
                              )
                            : null
                        }
                        imgSrc={
                          category.image
                            ? getImagePath(
                                category.image,
                                {
                                  width: 40,
                                  height: 40,
                                },
                                false,
                                false,
                                false,
                              )
                            : placeholderImgSrc
                        }
                        onMouseOver={onMouseOverSecond}
                        firstLevel={true}
                        active={activeFirstItem === i}
                      />
                    );
                  })}
              </FirstItemsWrapper>
              {secondLvlItems && (
                <SecondHover className="showMe">
                  <OtherItemsWrapper>
                    {secondLvlItems &&
                      secondLvlItems.map((category, i) => {
                        if (!category.counts || category.counts === 0) {
                          return null;
                        }
                        return (
                          <NavigationDropdownItem
                            onClick={closeNav}
                            index={i}
                            key={category.category_id}
                            id={category.category_id}
                            url={category.url}
                            name={category.category_name}
                            bigImgSrc={
                              category.image
                                ? getImagePath(
                                    category.image,
                                    {
                                      width: 300,
                                      height: 300,
                                    },
                                    false,
                                    false,
                                    false,
                                  )
                                : null
                            }
                            imgSrc={
                              category.image
                                ? getImagePath(
                                    category.image,
                                    {
                                      width: 40,
                                      height: 40,
                                    },
                                    false,
                                    false,
                                    false,
                                  )
                                : placeholderImgSrc
                            }
                            onMouseOver={onMouseOverThird}
                            firstLevel={false}
                            numOfProducts={category.counts}
                            active={activeSecondItem === i}
                          />
                        );
                      })}
                  </OtherItemsWrapper>
                  {secondLvlItems && thirdLvlItems && (
                    <ThirdHover>
                      <OtherItemsWrapper>
                        {thirdLvlItems.map((category, i) => {
                          if (!category.counts || category.counts === 0) {
                            return null;
                          }

                          return (
                            <NavigationDropdownItem
                              index={i}
                              onClick={closeNav}
                              key={category.category_id}
                              id={category.category_id}
                              url={category.url}
                              name={category.category_name}
                              bigImgSrc={
                                category.image
                                  ? getImagePath(
                                      category.image,
                                      {
                                        width: 300,
                                        height: 300,
                                      },
                                      false,
                                      false,
                                      false,
                                    )
                                  : null
                              }
                              imgSrc={
                                category.image
                                  ? getImagePath(
                                      category.image,
                                      {
                                        width: 40,
                                        height: 40,
                                      },
                                      false,
                                      false,
                                      false,
                                    )
                                  : placeholderImgSrc
                              }
                              firstLevel={false}
                              active={activeThirdItem === i}
                              numOfProducts={category.counts}
                              onMouseOver={onMouseOverFourth}
                            />
                          );
                        })}
                      </OtherItemsWrapper>
                      {secondLvlItems && thirdLvlItems && fourthLvlItems && (
                        <FourthHover>
                          <OtherItemsWrapper>
                            {fourthLvlItems.map((category, i) => {
                              if (!category.counts || category.counts === 0) {
                                return null;
                              }
                              return (
                                <NavigationDropdownItem
                                  index={i}
                                  onClick={closeNav}
                                  key={category.category_id}
                                  id={category.category_id}
                                  url={category.url}
                                  name={category.category_name}
                                  bigImgSrc={
                                    category.image
                                      ? getImagePath(
                                          category.image,
                                          {
                                            width: 300,
                                            height: 300,
                                          },
                                          false,
                                          false,
                                          false,
                                        )
                                      : null
                                  }
                                  imgSrc={
                                    category.image
                                      ? getImagePath(
                                          category.image,
                                          {
                                            width: 40,
                                            height: 40,
                                          },
                                          false,
                                          false,
                                          false,
                                        )
                                      : placeholderImgSrc
                                  }
                                  firstLevel={false}
                                  numOfProducts={category.counts}
                                  onMouseOver={onMouseOverFifth}
                                  active={activeFourthItem === i}
                                />
                              );
                            })}
                          </OtherItemsWrapper>
                          {secondLvlItems &&
                            thirdLvlItems &&
                            fourthLvlItems &&
                            fifthLvlItems && (
                              <FifthHover>
                                <OtherItemsWrapper>
                                  {fifthLvlItems.map((category, i) => {
                                    if (
                                      !category.counts ||
                                      category.counts === 0
                                    ) {
                                      return null;
                                    }

                                    return (
                                      <NavigationDropdownItem
                                        index={i}
                                        onClick={closeNav}
                                        key={category.category_id}
                                        id={category.category_id}
                                        url={category.url}
                                        name={category.category_name}
                                        bigImgSrc={
                                          category.image
                                            ? getImagePath(
                                                category.image,
                                                {
                                                  width: 300,
                                                  height: 300,
                                                },
                                                false,
                                                false,
                                                false,
                                              )
                                            : null
                                        }
                                        imgSrc={
                                          category.image
                                            ? getImagePath(
                                                category.image,
                                                {
                                                  width: 40,
                                                  height: 40,
                                                },
                                                false,
                                                false,
                                                false,
                                              )
                                            : placeholderImgSrc
                                        }
                                        firstLevel={false}
                                        numOfProducts={category.counts}
                                      />
                                    );
                                  })}
                                </OtherItemsWrapper>
                              </FifthHover>
                            )}
                        </FourthHover>
                      )}
                    </ThirdHover>
                  )}
                </SecondHover>
              )}
            </FirstHoverWrapper>
          </NavigationDropdownContent>
        )}
      </Wrapper>
    </OutsideClick>
  );
}

const Wrapper = styled(FlexRowCenterVertical)``;

const SecondaryItems = styled(FlexRowCenterVertical)`
  padding: 0 ${rem(16)};
  background-color: ${({ theme }) => theme.colors.navSecondary};
`;

const FirstHoverWrapper = styled(FlexRow)`
  background-color: white;

  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(900)};
  `}
`;

const FirstItemsWrapper = styled(FlexCol)`
  box-shadow: ${({ theme }) => theme.boxShadow.secondary};
  width: ${rem(300)};
  height: ${rem(630)};
  padding: ${rem(32)} 0;

  border-radius: 0 0 0 ${rem(2)};
  color: black;
`;

const OtherItemsWrapper = styled(FirstItemsWrapper)`
  box-shadow: initial;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;
  -webkit-overflow-y: scroll;
  display: block;
`;

const SecondHover = styled(FirstHoverWrapper)`
  background-color: #fbfafd;
  box-shadow: ${({ theme }) => theme.boxShadow.secondary};
`;

const ThirdHover = styled(FlexRow)`
  background-color: #fbfafd;
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
`;

const FourthHover = styled(FlexRow)`
  background-color: #fbfafd;
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
  ${({ theme }) => theme.mediab.l1400`
    display:none;
  `}
`;

const FifthHover = styled(FlexRow)`
  background-color: #fbfafd;
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
  @media only screen and (max-width: 1740px) {
    display: none;
  }
`;

export { Navigation };
