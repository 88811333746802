import * as React from 'react';
import { Link } from 'react-router';
import styled, { withTheme } from 'styled-components';
import { rem, darken } from 'polished';
import { Icon, IconImg, TextP } from 'eshop-defaults';
import { FlexCol, FlexBetween } from 'eshop-defaults';
import { __ } from 'react-i18n';
import { TableTd, TableRow } from 'eshop-defaults';

interface Props {
  activityData;
  handleShowActivitiesClick;
  setShowLoader;
  token: string;
}

export function CrmDashboardActivityDetail(props: Props) {
  const {
    activityData,
    handleShowActivitiesClick,
    setShowLoader,
    token,
  } = props;

  const handleReturnToActivitiesListClick = e => {
    handleShowActivitiesClick(null);
  };

  const handleDownloadFile = async e => {
    setShowLoader(true);
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/customers/activities-documents/${activityData.AktivitaID}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/zip',
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response: any) => response.blob())
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${activityData.AktivitaID}-documents.zip`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Hide loader
        setShowLoader(false);

        // Clean up and remove the link
        (link as any).parentNode.removeChild(link);
      });
  };

  return (
    <Wrapper>
      <Row>
        <ReturnToActivitiesListWrapper
          onClick={handleReturnToActivitiesListClick}
        >
          <Icon
            src="/images/blog_assets/arrow_back_gray.svg"
            style={{ height: '30px', width: '30px' }}
          />
          <p style={{ marginLeft: '6px', marginRight: '32px' }}>
            {__('Späť na zoznam')}
          </p>
        </ReturnToActivitiesListWrapper>
      </Row>
      <Row>
        <ActivityDetailName>{__('ID záznamu: ')}</ActivityDetailName>
        <ActivityDetailValue>
          {`${activityData.AktivitaID}`}
        </ActivityDetailValue>
      </Row>
      <Row>
        <ActivityDetailName>{__('Kategória aktivity: ')}</ActivityDetailName>
        <ActivityDetailValue>
          {`${activityData.AktivitaKategoria}`}
        </ActivityDetailValue>
      </Row>
      <Row>
        <ActivityDetailName>
          {__('Poradové číslo aktivity: ')}
        </ActivityDetailName>
        <ActivityDetailValue>
          {`${activityData.AktivitaPoradoveCislo}`}
        </ActivityDetailValue>
      </Row>
      <Row>
        <ActivityDetailName>{__('Predmet aktivity: ')}</ActivityDetailName>
        <ActivityDetailValue>
          {`${activityData.AktivitaPredmet}`}
        </ActivityDetailValue>
      </Row>
      {activityData.AktivitaPopis ? (
        <Row>
          <ActivityDetailName>{__('Popis aktivity: ')}</ActivityDetailName>
          <ActivityDetailValue>
            {`${activityData.AktivitaPopis}`}
          </ActivityDetailValue>
        </Row>
      ) : null}

      {activityData.KontaktnaOsoba && activityData.KontaktnaOsoba.length ? (
        <>
          <p style={{ color: 'black', fontWeight: 'bold' }}>
            {__('Kontaktná osoba: ')}
          </p>
          {activityData.KontaktnaOsoba[0].Meno ? (
            <Row>
              <ActivityDetailName>{__('Meno: ')}</ActivityDetailName>
              <ActivityDetailValue>
                {`${activityData.KontaktnaOsoba[0].Meno}`}
              </ActivityDetailValue>
            </Row>
          ) : null}
          {activityData.KontaktnaOsoba[0].Priezvisko ? (
            <Row>
              <ActivityDetailName>{__('Priezvisko: ')}</ActivityDetailName>
              <ActivityDetailValue>
                {`${activityData.KontaktnaOsoba[0].Priezvisko}`}
              </ActivityDetailValue>
            </Row>
          ) : null}
          {activityData.KontaktnaOsoba[0].Email ? (
            <Row>
              <ActivityDetailName>{__('Email: ')}</ActivityDetailName>
              <ActivityDetailValue>
                {`${activityData.KontaktnaOsoba[0].Email}`}
              </ActivityDetailValue>
            </Row>
          ) : null}
          {activityData.KontaktnaOsoba[0].Mobil ? (
            <Row>
              <ActivityDetailName>{__('Telefón: ')}</ActivityDetailName>
              <ActivityDetailValue>
                {`${activityData.KontaktnaOsoba[0].Mobil}`}
              </ActivityDetailValue>
            </Row>
          ) : null}
        </>
      ) : null}
      {activityData.MaDokument ? (
        <Row>
          <ActivityDetailName>{__('Stiahnuť prílohy: ')}</ActivityDetailName>
          <ActivityDetailValue>
            <StyledButton onClick={handleDownloadFile}>
              <IconImg
                cursor={'pointer'}
                width={24}
                height={24}
                src={'/images/download.svg'}
              />
            </StyledButton>
          </ActivityDetailValue>
        </Row>
      ) : null}
    </Wrapper>
  );
}
const StyledButton = styled.button`
  border: 0;
  background: transparent;
`;

const ActivityDetailName = styled.p`
  color: #312783;
  width: 33%;
  margin-right: 24px;
`;

const ActivityDetailValue = styled.p`
  color: black;
  max-width: 60%;
`;

const ReturnToActivitiesListWrapper = styled.div`
  display: flex;
  flex-flow: row;
  min-width: 160px;
  &:hover {
    cursor: pointer;
  }
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: content;
  padding: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  max-height: content;
`;

const Col = styled.div`
  display: flex;
  width: 45%;
  flex-flow: column;
  padding: 12px;
  jsutify-content: flex-start;
`;
