import * as React from 'react';
import styled from 'styled-components';

// INTERFACES

interface DimmerProps {
  height: number | null;
  zIndex?: number;
  topZero?: boolean;
}

/*****************/

// Styled Components

const Wrapper: any = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
`;

/*****************/

const Dimmer: React.FunctionComponent<DimmerProps> = ({
  height,
  zIndex = 5,
  topZero,
}) => {
  return <Wrapper height={height} zIndex={zIndex} topZero={topZero} />;
};

export default Dimmer;
