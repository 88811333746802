import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  TextP,
  FlexBetween,
  FlexCol,
  FooterItem,
  FooterMenuCol,
  FlexRow,
  FlexColCenter,
  IconImg,
  FooterLastRow,
  FlexRowCenter,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';

const FooterWrapper = styled.footer`
z-index: 1;
  background: ${({ theme }) => theme.colors.footer};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  flex-flow: column;
  /* margin-top: ${rem(40)}; */
  @media print {
    display: none;
  }
  ${({ theme }) => theme.mediab.m580`
   margin-top: 0;
`};
`;

const MapWrapper = styled(FlexRowCenterVertical)`
  @media only screen and (max-width: 1440px) {
    display: none;
  }
`;

const MenuMapWrapper = styled(FlexBetween)`
  align-items: flex-start;
  width: 100%;
`;

const MenuWrapper = styled(FlexRow)`
  flex: 1;
  justify-content: space-between;

  ${({ theme }) => theme.mediab.m600`
    flex-flow: column;  
  `}
`;

const MobileMapWrapper = styled(FlexColCenter)`
  margin-bottom: ${rem(32)};
`;

const MapImage = styled.div`
  width: ${rem(262)};
  height: ${rem(180)};
  margin-right: ${rem(48)};
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${props =>
    `linear-gradient(270deg, #55555500 65%,  #555555 100%), url(${props.src})`};

  ${({ theme }) => theme.mediab.l1050`
   margin-right: ${rem(12)};
  `}
`;

const MapTextWrapper = styled(FlexCol)`
  max-width: ${rem(220)};
`;

const MapLink = styled.a`
  color: ${({ theme }) => theme.colors.footerLink};
  font-size: ${rem(14)};
  margin-top: ${rem(16)};
`;

const DesktopWrapper = styled.div`
  display: block;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`;
const NeedHelp = styled(FlexRowCenterVertical)`
  justify-content: flex-start;
  margin-right: ${rem(16)};
  min-width: fit-content;
  ${({ theme }) => theme.mediab.l925`
      margin-bottom: ${rem(8)};
      margin-right: 0;
  `};
`;

const SocialMedia = styled(FlexRowCenterVertical)`
  justify-content: center;
  margin-right: ${rem(16)};
  margin-left: ${rem(16)};
  width: 33%;
  @media only screen and (max-width: 500px) {
    margin-bottom: 14px;
  }
`;

const FooterImg = styled.img`
  width: ${rem(55)};
  height: ${rem(18)};
  margin-left: ${rem(32)};
`;

const FooterSocMedia = styled.img`
  width: ${rem(35)};
  height: ${rem(35)};
  margin-left: ${rem(8)};
`;

const SmallTextP = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  color: ${({ theme }) => theme.colors.white};
  line-height: ${rem(17)};
  min-width: fit-content;
  ${({ theme }) => theme.mediab.m580`
    text-align: center;
  `}
`;

const SmallTextPBold = styled(SmallTextP)`
  font-weight: 500;
`;

const SmallTextPhoneEmailLink = styled.a`
  text-decoration: none;
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  color: ${({ theme }) => theme.colors.white};
  line-height: ${rem(17)};

  &:hover {
    text-decoration: underline;
  }
`;

const MobileAddress = styled(SmallTextP)`
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${rem(16)};
`;

const DotDelimeter = styled.span`
  margin: 0 ${rem(12)};
  height: ${rem(2)};
  width: ${rem(2)};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: inline-block;

  ${({ theme }) => theme.mediab.m580`
    margin: 0 ${rem(8)};
  `}
`;

const PaymentsPicturesWrapper = styled(FlexRowCenterVertical)`
  width: 33%;
  justify-content: center;
`;

const FooterRowWrapper = styled.div`
  display: flex;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  padding: ${rem(32)} ${rem(24)};
  width: 100%;
  margin: 0 auto;
  border-bottom: ${({ theme }) => theme.borders.footer};
  justify-content: space-between;

  ${({ theme }) => theme.mediab.l925`
      flex-flow: column;
      align-items: center;

      &:last-child {
        padding-bottom: ${rem(32)};
      }
  `};
  ${({ theme }) => theme.mediab.m600`
      padding: ${rem(24)} 0;
  `};
`;

interface Props {
  items: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  phone: string;
  email: string;
  isVt: boolean;
}

interface State {
  breakInnerWidth: number;
}

class Footer extends React.Component<Props, State> {
  public render() {
    return (
      <FooterWrapper>
        {this.renderFirstRow()}
        {this.renderSecondRow()}
        {this.renderThirdRow()}
      </FooterWrapper>
    );
  }

  private renderFirstRow = () => {
    const { items }: any = this.props;

    const mapSrc = '/images/zemplin/map.png';
    const mapsAdressLink =
      'https://www.google.com/maps?q=ZEMPLÍN+s.r.o.+–+centrum+vykurovania';

    if (!items) {
      return null;
    }

    return (
      <FooterRowWrapper>
        {' '}
        <MenuMapWrapper>
          <MenuWrapper>
            {items &&
              items.map((item: any) => (
                <FooterMenuCol key={item.id} title={item.name}>
                  {item.children &&
                    item.children.map(child => (
                      <FooterItem
                        key={child.id}
                        url={`/${child.url}`}
                        title={child.name}
                        hasIcon={true}
                      />
                    ))}
                </FooterMenuCol>
              ))}
          </MenuWrapper>
          <MapWrapper>
            <MapImage src={mapSrc} alt="mapa" />
            <MapTextWrapper>
              <SmallTextP>
                {'Jasenovská 31, 066 01 Humenné Slovenská republika'}
              </SmallTextP>
              <MapLink rel="noreferrer" href={mapsAdressLink} target="_blank">
                {'Zobraziť na mape'}
              </MapLink>
            </MapTextWrapper>
          </MapWrapper>
        </MenuMapWrapper>
      </FooterRowWrapper>
    );
  };

  private renderSecondRow = () => {
    const { phone, email, isVt } = this.props;
    const visaSrc = '/images/zemplin/visa.svg';
    const masterCardSrc = '/images/zemplin/mastercard.svg';
    const locationSrc = '/images/zemplin/location.svg';
    const mapsAdressLink =
      'https://www.google.com/maps?q=ZEMPLÍN+s.r.o.+–+centrum+vykurovania';
    const facebookIcon = '/images/facebook-logo.svg';
    const instagramIcon = '/images/instagram_logo.svg';
    const fbLinkSM =
      'https://www.facebook.com/ZEMPL%C3%8DN-Ve%C4%BEkoobchod-so-spojovac%C3%ADm-materi%C3%A1lom-110469614751538';
    const isLinkSM = 'https://www.instagram.com/spojovaci_material_zemplin/';
    const fbLinkVT = 'https://www.facebook.com/krbove.studio.zemplin';
    const isLinkVT = 'https://www.instagram.com/zemplin_krbove_studio';

    return (
      <>
        <DesktopWrapper>
          <FooterRowWrapper>
            <NeedHelp>
              <SmallTextPBold>{'Potrebujete poradiť?'}</SmallTextPBold>
              <DotDelimeter />
              <SmallTextPhoneEmailLink href={`tel:${phone}`}>
                {phone}
              </SmallTextPhoneEmailLink>
              <DotDelimeter />
              <SmallTextPhoneEmailLink href={`mailto:${email}`}>
                {email}
              </SmallTextPhoneEmailLink>
            </NeedHelp>
            <SocialMedia>
              <SmallTextPBold>
                {'Sledujte nás na sociálnych sieťach'}
              </SmallTextPBold>
              <a href={isVt ? fbLinkVT : fbLinkSM}>
                <FooterSocMedia src={facebookIcon} alt="facebook" />
              </a>
              <a href={isVt ? isLinkVT : isLinkSM}>
                <FooterSocMedia src={instagramIcon} alt="instagram" />
              </a>
            </SocialMedia>
            <PaymentsPicturesWrapper>
              <FooterImg src={visaSrc} alt="visa" />
              <FooterImg src={masterCardSrc} alt="mastercard" />
            </PaymentsPicturesWrapper>
          </FooterRowWrapper>
        </DesktopWrapper>
        <MobileWrapper>
          <FooterRowWrapper>
            <MobileMapWrapper>
              <IconImg
                width={16}
                height={16}
                src={locationSrc}
                alt="location-icon"
              />
              <MobileAddress>
                {'Jasenovská 31, 066 01 Humenné Slovenská republika'}
              </MobileAddress>
              <MapLink rel="noreferrer" href={mapsAdressLink} target="_blank">
                {'Zobraziť na mape'}
              </MapLink>
            </MobileMapWrapper>
            <NeedHelp>
              <SmallTextP>{phone}</SmallTextP>
              <DotDelimeter />
              <SmallTextP>{email}</SmallTextP>
            </NeedHelp>
            <SocialMedia>
              <SmallTextPBold>
                {'Sledujte nás na sociálnych sieťach'}
              </SmallTextPBold>
            </SocialMedia>
            <SocialMedia>
              <a href={isVt ? fbLinkVT : fbLinkSM}>
                <FooterSocMedia src={facebookIcon} alt="facebook" />
              </a>
              <a href={isVt ? isLinkVT : isLinkSM}>
                <FooterSocMedia src={instagramIcon} alt="instagram" />
              </a>
            </SocialMedia>
          </FooterRowWrapper>
        </MobileWrapper>
      </>
    );
  };

  private renderThirdRow = () => {
    return (
      <FooterLastRow
        leftText={`Copyright © ${new Date().getFullYear()} ZEMPLIN.sk`}
      />
    );
  };
}

export default Footer;
