import React, { ReactNode, useEffect } from 'react';
import {
  ga4CartEvent,
  ga4EventsPurchase,
} from '@bart.sk-ecommerce/react-online-marketing/lib/index';
import {
  AFFILIATION_VALUE,
  DATALAYER_PRODUCTS_COOKIE,
} from '../../containers/App/constants';
import { useLocalStorage } from '../../utilities/useLocalStorage';
import { prop } from 'eshop-defaults';
import { stat } from 'fs';
import API from '../../services/API';

type Props = {
  order: any;
};

const TrackOrder: React.FC<Props> = ({ order }: Props) => {
  const [analyticsSent, setAnalyticsSent] = React.useState(false);
  const [dataLayerProducts, setDataLayerProducts] = useLocalStorage(
    DATALAYER_PRODUCTS_COOKIE,
    {},
  );

  useEffect(() => {
    async function sendToAnalytics(): Promise<void> {
      if (order && !analyticsSent) {
        setAnalyticsSent(true);

        // try {
        //   ga4CartEvent(
        //     AFFILIATION_VALUE,
        //     'add_shipping_info',
        //     order,
        //     null,
        //     [],
        //     {
        //       shipping_tier: prop(order, 'delivery.delivery_type'),
        //     },
        //   );
        //   ga4CartEvent(AFFILIATION_VALUE, 'add_payment_info', order, null, [], {
        //     payment_type: prop(order, 'payment.payment_type'),
        //   });
        // } catch (e) {
        //   console.error(e);
        // }
        try {
          ga4EventsPurchase(
            AFFILIATION_VALUE,
            { ...order, is_returning_customer: undefined },
            dataLayerProducts,
            false,
            {
              customer: {
                id: prop(order, 'club_user_id') || '',
                billing: {
                  first_name: prop(order, 'address.person.name') || '',
                  first_name_hash:
                    prop(order, 'hashedData.firstNameHash') || '',
                  last_name: prop(order, 'address.person.surname') || '',
                  last_name_hash: prop(order, 'hashedData.surnameHash') || '',
                  company: prop(order, 'company.name') || '',
                  address_1: `${prop(order, 'address.street') || ''} ${prop(
                    order,
                    'address.street_number',
                  ) || ''}`,
                  address_2: '',
                  city: prop(order, 'address.city') || '',
                  state: '',
                  postcode: prop(order, 'address.zip') || '',
                  country: prop(order, 'address.country') || 'SK',
                  email: prop(order, 'address.person.email') || '',
                  email_hash: prop(order, 'hashedData.emailHash') || '',
                  phone: prop(order, 'address.person.phone') || '',
                  phone_hash: prop(order, 'hashedData.phoneHash') || '',
                },
                shipping: {
                  first_name: prop(order, 'delivery.person.name') || '',
                  last_name: prop(order, 'delivery.person.surname') || '',
                  address_1: `${prop(order, 'delivery.delivery_addr_street') ||
                    ''} ${prop(order, 'delivery.delivery_addr_street_number') ||
                    ''}`,
                  address_2: '',
                  city: prop(order, 'delivery.delivery_addr_city') || '',
                  state: '',
                  postcode: prop(order, 'delivery.delivery_addr_zip') || '',
                  country:
                    prop(order, 'delivery.delivery_addr_country') || 'SK',
                },
              },
            },
          );
        } catch (e) {
          console.error(e);
        }

        try {
          if (process.env.NODE_ENV !== 'development') {
            await API.updateOrderAnalytics(order.public_id);
          }
        } catch (e) {
          console.error('Update order analytics failed!');
        }

        setDataLayerProducts({});
      }
    }

    setTimeout(() => {
      sendToAnalytics();
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, analyticsSent]);

  return null;
};

export default TrackOrder;
