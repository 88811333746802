import API from './services/API';
import * as cookies from 'react-cookies';
import { LANG_COOKIE } from './containers/App/constants';

export async function loadTranslations(location) {
  const fromCookie = cookies.load(LANG_COOKIE) || 'sk';
  return API.loadLangTranslation(fromCookie);
}

export function resolveLangFromCookies(hostname: string): string {
  return cookies.load(LANG_COOKIE) || 'sk';
}

export function resolveLangByHostname(hostname: string): string {
  switch (hostname) {
    case 'beta.vzdy.sk':
      return 'sk';
    case 'beta.vzdy.cz':
      return 'cz';
    default:
      return 'sk';
  }
}

export function resolveBaseUrlByLang(lang?: string): string {
  // default is base url
  if (!lang) {
    return process.env.REACT_APP_BASE_URL as string;
  }
  // we are returning other url - if lang is cz we return sk url
  if (lang === 'cz') {
    return process.env.REACT_APP_LANG_OTHER_VERSION as string;
  } else {
    return process.env.REACT_APP_BASE_URL as string;
  }
}
