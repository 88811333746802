import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  MetaTags,
  FinishedOrder,
  FlexCol,
  InfoText,
  prop,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
} from '../MyAccount/myAccountSlice';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { withRouter, WithRouterProps } from 'react-router';
import TrackOrder from '../../components/Analytics/TrackOrder';

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching: boolean;
}

class FinishedOrderContainer extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { orderPublicId },
    } = props;
    dispatch(
      setBreadCrumbPath(BreadCrumbType.CART, {
        url: `${__r(
          'routes:dokoncena-objednavka',
          '/dokoncena-objednavka',
        )}/${orderPublicId}`,
      }),
    );
    try {
      await dispatch(fetchFinishedOrder(orderPublicId));
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const { orderData, isFetching, location } = this.props;

    if (!isFetching && !orderData) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Takáto objednávka neexistuje')}
        />
      );
    }

    const status = prop(location, 'query.status');

    return (
      <>
        {+prop(orderData, 'analytics_sended') === 0 && (
          <TrackOrder order={orderData} />
        )}
        <Wrapper className="container container--wide">
          <MetaTags
            tags={{
              title:
                orderData && orderData['is_demand']
                  ? __('Ďakujeme za váš dopyt!')
                  : __('Ďakujeme za váš nákup!'),
            }}
          />
          <FinishedOrder
            order={orderData}
            isFetching={isFetching}
            redirectUrl={
              orderData && orderData.public_id
                ? `${process.env.REACT_APP_API_BASE_URL}/payment/vubpay/${orderData.public_id}`
                : ''
            }
            status={status}
          />
        </Wrapper>
        <InfoBanner />
      </>
    );
  }
}

const Wrapper = styled(FlexCol)`
  margin: 0 auto;
  width: 100%;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    orderData: finishedOrderDataSelector(state),
    isFetching: finishedOrderIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FinishedOrder' })(
    withRouter(FinishedOrderContainer),
  ),
);
