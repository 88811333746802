import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

// Interfaces

interface FormInputProps {
  value: string;
  onChange: (e) => void;
  onBlur?: (e) => void;
  label?: string;
  mandatory?: boolean;
  name?: string;
  placeholder?: string;
  className?: string;
  type?: string;
  id?: string;
  validationMessage?: string;
  tabIndex?: number;
  maxWidth?: number;
  required?: boolean;
  marginBottom?: number;
}

/*****************/

// Styled Components

const Wrapper = styled.div`
  /* max-width: ${rem(282)}; */
  width: 100%;
  
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};
`;

export const Label = styled.label`
  font-size: ${rem(12)};
  margin-bottom: ${rem(10)};
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`;

export const MandatoryAsterisk = styled.span`
  color: ${({ theme }) => theme.color.brand};
`;

const Input = styled.input<{ invalid: boolean }>`
  width: 100%;
  height: ${rem(40)};
  border: ${rem(1)} solid ${({ theme, invalid }) =>
  invalid ? theme.color.danger : theme.color.gray86}
  border-radius: ${rem(4)};
  padding-left: ${rem(15)};
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.color.text};
  box-shadow: ${({ invalid }) =>
    invalid ? `0 0 ${rem(8)} rgba(255, 0, 0, 0.5)` : 'none'};
    
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};
  
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px` : ''};

  &::placeholder {
    font-size: ${rem(14)}; 
    color: ${({ theme }) => theme.color.textSecondary};       
  }

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ValidationMessage = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.color.text};
  border-radius: ${rem(4)};
`;

/*****************/

class FormInput extends React.Component<FormInputProps> {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  public render() {
    const {
      label,
      mandatory,
      name,
      placeholder,
      className,
      type,
      id,
      validationMessage,
      value,
      tabIndex,
      maxWidth,
      required,
      marginBottom,
    } = this.props;
    const elementId = id ? id : '';
    return (
      <Wrapper className={className} maxWidth={maxWidth}>
        {label && (
          <Label htmlFor={id || ''}>
            {label} {mandatory && <MandatoryAsterisk>*</MandatoryAsterisk>}
          </Label>
        )}
        {validationMessage && (
          <ValidationMessage>{validationMessage}</ValidationMessage>
        )}
        <Input
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          name={name || ''}
          placeholder={placeholder}
          type={type || ''}
          id={elementId}
          invalid={false}
          tabIndex={tabIndex}
          maxWidth={maxWidth}
          required={required}
          marginBottom={marginBottom}
        />
      </Wrapper>
    );
  }

  private handleChange = e => {
    this.props.onChange(e);
  };

  private handleBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };
}

export default FormInput;
