import { __r } from 'react-i18n';
import { langSelector, currencySelector } from '../App/selectors';
import { saveCartCookie, removeCartCookie } from './helpers';
import { fetchCart } from './cartSlice';

export enum cartRoutes {
  INDEX = 'routes:kosik',
  SECOND_STEP = 'routes:kosik/osobne-udaje',
  THIRD_STEP = 'routes:cart/dodanie-a-platba',
}

export const cartIndexRoute = __r('routes:kosik', '/kosik');
export const cartSecondStepRoute = __r(
  'routes:kosik/osobne-udaje',
  '/kosik/osobne-udaje',
);
export const cartThirdStepRoute = __r(
  'routes:cart/dodanie-a-platba',
  '/kosik/dodanie-a-platba',
);

export const CART_ROUTES = {
  1: cartIndexRoute,
  2: cartSecondStepRoute,
  3: cartThirdStepRoute,
};

export const createNewCart = async (state, API, dispatch?: any) => {
  const { id } = await API.createCart(
    {},
    {
      xAcceptLanguage: langSelector(state),
      xCurrency: currencySelector(state),
    },
  );
  removeCartCookie();
  saveCartCookie(id);
  if (dispatch) {
    dispatch(fetchCart(true));
  }

  return id;
};
