import { DATALAYER_PRODUCTS_COOKIE } from '../containers/App/constants';

export const resolveDatalayerProducts = () => {
  let dataLayerProducts = null;
  try {
    dataLayerProducts = localStorage.getItem(DATALAYER_PRODUCTS_COOKIE)
      ? JSON.parse(localStorage.getItem(DATALAYER_PRODUCTS_COOKIE) as any)
      : '';
  } catch (error) {
    // silent
  }
  return dataLayerProducts;
};

export const setNewDatalayerProducts = newDatalayerProducts => {
  try {
    localStorage.setItem(
      DATALAYER_PRODUCTS_COOKIE,
      JSON.stringify(newDatalayerProducts),
    );
  } catch (error) {
    // silent
  }
};
