import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinTitle as Title,
  ContactPersonBlock,
  BlockWithTitle,
  ContactInfoBlock,
  InvoiceAddressBlock,
  CompanyInfo,
  ZemplinButton as Button,
  InfoText,
  ZemplinCheckbox,
  prop,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';

interface Props {
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  userId: number;
}

function reducer(state, { field, value }) {
  return { ...state, [field]: value };
}

function MyInfo({ customerInfo, userId }: Props) {
  const companyRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  const {
    meno,
    priezvisko,
    email,
    ico: currentIco,
    dic: currentDic,
    icdph: currentIcDph,
    firma,
    country: currentCountry,
    cislo,
    mesto,
    psc,
    telefon,
    ulica,
    newsletter_accept,
  } = customerInfo;
  const isNameSet = meno ? meno !== '' : false;
  const isSurnameSet = priezvisko ? priezvisko !== '' : false;
  const initialState = {
    name: meno || '',
    surname: priezvisko || '',
    ico: currentIco || '',
    dic: currentDic || '',
    icdph: currentIcDph || '',
    company: firma || '',
    country: currentCountry || 'sk',
    streetNumber: cislo || '',
    city: mesto || '',
    zip: psc || '',
    phone: telefon || '',
    street: ulica || '',
    isCompany: firma && currentIco && currentDic && currentIcDph,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [newsletterDataUpdated, setNewsletterDataUpdated] = React.useState(
    false,
  );
  const [newsletterError, setNewsletterError] = React.useState(null);
  const defaultNewsletterAccept = newsletter_accept;
  const [isNewsletterAccept, setIsNewsletterAccept] = React.useState(
    newsletter_accept,
  );

  React.useEffect(() => {
    setIsNewsletterAccept(defaultNewsletterAccept);
  }, [defaultNewsletterAccept]);

  const onChange = e => {
    const {
      target: { name, value },
    } = e;
    dispatch({ field: name, value: value });
    if (name === 'isCompany' && value) {
      if (companyRef && companyRef.current) {
        window.scrollTo({
          behavior: 'smooth',
          top: companyRef.current.offsetTop,
        });
      }
    }
  };

  const {
    ico,
    dic,
    icdph,
    company,
    country,
    streetNumber,
    street,
    city,
    zip,
    phone,
    isCompany,
    name,
    surname,
  } = state;

  const updateDetails = async () => {
    setIsSaving(true);
    try {
      await API.updateCustomerDetails(
        userId,
        {},
        {
          ...customerInfo,
          meno: name,
          priezvisko: surname,
          firma: company,
          ico,
          dic,
          icdph,
          ulica: street,
          cislo: streetNumber,
          telefon: phone,
          psc: zip,
          mesto: city,
          country,
          newsletter_accept: isNewsletterAccept,
        },
      );
    } catch (e) {
      setError(e);
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    setDataUpdated(true);
  };

  const toggleNewsletter = async () => {
    setIsSaving(true);
    try {
      setIsNewsletterAccept(a => !a);
      await API.updateCustomerDetails(
        userId,
        {},
        {
          ...customerInfo,
          newsletter_accept: !isNewsletterAccept,
        },
      );
    } catch (e) {
      setNewsletterError(e);
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    setNewsletterDataUpdated(true);
  };

  return (
    <Wrapper>
      <Title marginTop={0} marginBottom={32}>
        {__('Moje údaje')}
      </Title>
      <ZemplinCheckbox
        disabled={!isSaving}
        name={'newsletterAgree'}
        type={'checkbox'}
        selected={isNewsletterAccept}
        label={
          <>
            {__('Súhlasím so zasielaním newslettra')}{' '}
            <a
              target="__blank"
              rel="noopener noreferrer"
              href={
                'https://www.appgdpr.sk/doc/01HQR-HZY7T-JEEH-94V1-71NP-8HXP/DIP%20Vyhl%c3%a1senie%20o%20ochrane%20s%c3%bakromia%20-%20In%c3%a9%20[odberatelia%20newslettra].html'
              }
            >
              ({__('podmienky súhlasu')})
            </a>
          </>
        }
        wrapperStyle={{ marginBottom: rem(40) }}
        onClick={toggleNewsletter}
      />
      {!newsletterError && newsletterDataUpdated && (
        <InfoText
          type={InfoType.SUCCESS}
          info={__('Súhlas bol úspešne zmenený')}
        />
      )}
      <DimWrapper dimmed={isSaving}>
        <BlockWithTitle title={'Kontaktná osoba'}>
          <ContactPersonBlock
            onChange={onChange}
            isNameSet={isNameSet}
            isSurnameSet={isSurnameSet}
            name={name}
            surname={surname}
            isCompany={isCompany}
          />
        </BlockWithTitle>
        <BlockWithTitle title={'Kontaktné údaje'}>
          <ContactInfoBlock email={email} phone={phone} onChange={onChange} />
        </BlockWithTitle>
        <BlockWithTitle title={'Fakturačná adresa'}>
          <InvoiceAddressBlock
            onChange={onChange}
            street={street}
            streetNumber={streetNumber}
            city={city}
            zip={zip}
            country={country}
          />
        </BlockWithTitle>
        <CompanyWrapper visible={isCompany} ref={companyRef}>
          <BlockWithTitle title={'Údaje o firme'}>
            <CompanyInfo
              onChange={onChange}
              name={company}
              ico={ico}
              dic={dic}
              icdph={icdph}
            />
          </BlockWithTitle>
        </CompanyWrapper>
      </DimWrapper>
      {!error && dataUpdated && (
        <InfoText
          type={InfoType.SUCCESS}
          info={__('Údaje boli úspešne zmenené')}
        />
      )}
      {error && (
        <InfoText
          type={InfoType.ERROR}
          info={__('Pri ukladaní sa vyskytla chyba')}
        />
      )}
      <SaveButton onClick={updateDetails}>
        {isSaving ? __('Ukladám údaje ...') : __('Uložiť')}
      </SaveButton>
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const CompanyWrapper = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: ${({ visible }) => (visible ? 'auto' : 0)};
`;

const DimWrapper = styled(FlexCol)<{ dimmed: boolean }>`
  opacity: ${({ dimmed }) => (dimmed ? 0.4 : 1)};
`;

const SaveButton = styled(Button)`
  height: ${rem(48)};
  padding: ${rem(15)};
  width: 100%;
  max-width: ${rem(160)};
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${rem(14)};
  border-radius: ${rem(2)};
  margin: 0 0 ${rem(24)};

  &:disabled {
    background-color: #dcd6d6;
  }
`;

export default MyInfo;
