import React, { ReactNode, useRef } from 'react';
import {
  ga4CartEvent,
  ga4EventsItem,
} from '@bart.sk-ecommerce/react-online-marketing/lib/index';
import { useLocalStorage } from '../../utilities/useLocalStorage';
import {
  AFFILIATION_VALUE,
  DATALAYER_PRODUCTS_COOKIE,
} from '../../containers/App/constants';
import { prop } from 'eshop-defaults';
import { InView, useInView } from 'react-intersection-observer';

type Props = {
  cart: any;
  cartItem?: any;
  payment_type?: string;
  shipping_tier?: string;
  eventName: string;
};

const TrackCart: React.FC<Props> = ({
  cart,
  payment_type,
  shipping_tier,
  eventName,
  cartItem,
}: Props) => {
  const [analyticsSent, setAnalyticsSent] = React.useState(false);
  const [dataLayerProducts, setDataLayerProducts] = useLocalStorage(
    DATALAYER_PRODUCTS_COOKIE,
    {},
  );

  const { ref } = useInView({
    threshold: 0,
    // rootMargin: '-200px 0px -200px',
    onChange: inView => {
      if (cart && inView && !analyticsSent) {
        setAnalyticsSent(true);

        ga4CartEvent(
          AFFILIATION_VALUE,
          eventName,
          cart,
          cartItem,
          dataLayerProducts,
          {
            payment_type,
            shipping_tier,
          },
        );
      }
    },
  });

  return <div ref={ref} />;
};

export default TrackCart;
